import paymentPlanStore from 'stores/payment-plan-store'
import React, { Component } from 'react'
import Store from 'stores/store'
import uiStore from 'stores/ui-store'
import { GetText as $ } from 'stores/UITexts'
import { observable } from 'mobx'
import { observer } from 'mobx-react'
import { Portlet, PortletContent, PortletHeader, PortletLabel } from 'components'
import {
  Button,
  Chip,
  createStyles,
  Theme,
  Typography,
  withStyles,
  WithStyles,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from '@material-ui/core'
import { AccountCircle } from '@material-ui/icons'
import { Table } from 'reactstrap'

const Styles = (theme: Theme) =>
  createStyles({
    root: {
      marginTop: theme.spacing() * 4,
      paddingLeft: 10,
      paddingRight: 10,
      borderWidth: 1,
      borderStyle: 'solid',
      borderColor: '#001e50',
      maxWidth: '100%',
      borderRadius: 18,
      backgroundColor: '#fff',
    },
    siteItem: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      marginTop: theme.spacing() * 2,
      marginBottom: theme.spacing() * 2,
    },
    siteInfo: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'start',
      minWidth: 250,
    },
    siteRenewal: {
      display: 'flex',
      alignItems: 'start',
      minWidth: 250,
    },
    siteUrl: {
      fontSize: '13px',
      marginTop: 5,
    },
    siteProductName: {
      fontSize: '13px',
    },
    siteDates: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    siteDateContainer: {
      backgroundColor: theme.palette.primary.light,
      padding: 5,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      borderRadius: 5,
      width: 100,
    },
    siteTrialInfo: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      width: 100,
    },
    sitePriceContainer: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
    },
    sitePrice: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    siteControls: {},
    button: {
      backgroundColor: '#394172',
    },
  })

interface Props extends WithStyles<typeof Styles> {}

@observer
class Subscriptions extends Component<Props> {
  @observable dialogOpen = false
  @observable site: any

  getPaymentPlan(paymentPlanRefCode: string) {
    return paymentPlanStore.items.find(p => p.referenceCode === paymentPlanRefCode)
  }

  openDialog(site: any) {
    this.dialogOpen = true
    this.site = site
  }

  async handleClose(suspend: boolean) {
    this.dialogOpen = !this.dialogOpen

    if (suspend) {
      try {
        await Store.suspendSite(this.site.id)
        uiStore.showSnackbar($('site_suspended'))
      } catch {
        uiStore.showSnackbar($('site_cannot_be_suspended'))
      }
    }
  }

  render() {
    const { classes } = this.props
    let countSite = 0
    if (!Store.tenant) {
      return <></>
    }

    return (
      <>
        <Portlet className={classes.root}>
          <PortletHeader>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <AccountCircle />
              <PortletLabel title={$('subscriptions')} />
            </div>
          </PortletHeader>
          <PortletContent>
            <Table responsive>
              <thead>
                <tr>
                  <th>Site Id</th>
                  <th>Name</th>
                  <th>Trial-End Date</th>
                  <th>Start-Renewal Of Service</th>
                  <th>Pricing</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                {Store.tenant.sites.map(s => {
                  let plan = this.getPaymentPlan(s.scriptPaymentPlanRefCode)!
                  countSite++
                  if (!plan) {
                    plan = {
                      referenceCode: '',
                      name: 'Custom Plan',
                      price: 0,
                    }
                  }
                  const period = plan.referenceCode !== '' ? (plan.name.includes('01') ? $('month') : $('year')) : $('year')
                  const currency = plan.referenceCode !== '' ? plan.name.substr(0, 3) : 'TRY'
                  return (
                    <tr>
                      <th scope="row">{countSite}</th>
                      <td>
                        <div className={classes.siteInfo}>
                          <Typography variant="subtitle2">{s.name}</Typography>
                          <Typography className={classes.siteUrl}>({s.urls[0]})</Typography>
                        </div>
                      </td>
                      <td>
                        <div className={classes.siteTrialInfo}>
                          {s.trialingUntil ? (
                            <Chip
                              label={new Date(s.trialingUntil).toLocaleDateString(navigator.language)}
                              color="primary"
                              className={classes.button}
                            />
                          ) : (
                            <></>
                          )}
                        </div>
                      </td>
                      <td>
                        <div className={classes.siteRenewal}>
                          <div className={classes.siteDateContainer}>
                            <Typography variant="h6">{new Date(s.serviceStartDate).toLocaleDateString(navigator.language)}</Typography>
                          </div>
                          <Typography>--</Typography>
                          <div className={classes.siteDateContainer}>
                            <Typography variant="h6">{new Date(s.serviceEndDate).toLocaleDateString(navigator.language)}</Typography>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div className={classes.siteInfo}>
                          <div className={classes.sitePrice}>
                            {plan.price !== 0 ? (
                              <>
                                <Typography variant="h4">{`${currency} ${plan.price}`}</Typography>
                                <Typography variant="h6">/{period}</Typography>
                              </>
                            ) : (
                              <></>
                            )}
                          </div>
                        </div>
                      </td>
                      <td>
                        <div className={classes.siteControls}>
                          {s.status !== 'SUSPENDED' && plan.referenceCode !== '' && (
                            <Button variant="outlined" color="secondary" onClick={() => this.openDialog(s)}>
                              {$('suspend_site')}
                            </Button>
                          )}
                          {s.status === 'SUSPENDED' && plan.referenceCode !== '' && <Chip label={$('suspended')} color="secondary" />}
                          {plan.referenceCode === '' && <Chip label={$('not_available')} className={classes.button} color="secondary" />}
                        </div>
                      </td>
                    </tr>
                  )
                })}
              </tbody>
            </Table>
            <Dialog
              open={this.dialogOpen}
              onClose={() => this.handleClose(false)}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">{$('stop')}</DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  {$('suspend_cookieseal_services_for')} {this.site?.name}?
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={() => this.handleClose(false)} color="primary">
                  {$('cancel')}
                </Button>
                <Button onClick={() => this.handleClose(true)} color="secondary" autoFocus>
                  {$('suspend')}
                </Button>
              </DialogActions>
            </Dialog>
          </PortletContent>
        </Portlet>{' '}
        {/* <Description /> */}
      </>
    )
  }
}

export default withStyles(Styles)(Subscriptions)
