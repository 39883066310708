import _ from 'lodash'
import Cookies from './components/cookies'
import DashboardLayout from 'layouts/dashboard'
import Info from './components/info'
import Integration from './components/integration'
import React, { Component } from 'react'
import Store from 'stores/store'
import Texts from './components/texts'
import UIStore from 'stores/ui-store'
import {
  Button,
  createStyles,
  Tab,
  Tabs,
  Theme,
  withStyles,
  WithStyles
} from '@material-ui/core'
import { GetText as $ } from 'stores/UITexts'
import { observable } from 'mobx'
import { observer } from 'mobx-react'
import WebSiteCard from 'views/dashboard/components/WebSitesCard'
import Customization from './components/customization'

const Styles = (theme: Theme) =>
  createStyles({
    root: {
      height: '100%',
      display: 'flex',
      justifyContent: 'space-between',
      flexDirection: 'column',
      flex: 1,
    },
    container: {
      padding: theme.spacing() * 3,
      display: 'flex',
      flexDirection: 'column',
      flex: 1,
    },
    portlet: {
      marginTop: theme.spacing() * 4,
      paddingLeft: 10,
      paddingRight: 10,
      borderWidth: 1,
      borderStyle: 'solid',
      borderColor: '#dcdcdc',
      maxWidth: '100%',
      borderRadius: 5,
      backgroundColor: '#fff',
    },
    tabsContainer: {
      height: 48,
      display: 'flex',
      flexDirection: 'row',
      alignItems:'center',
      justifyContent:'center'
    },
    tabs: {
      flex: 1,
    },
    content: {
      flex: 1
    },
    button: {
      alignSelf: 'flex-end',
      backgroundColor:'#394172'
    },
  })

interface Props extends WithStyles<typeof Styles> {
  className: string
  match: any
}

@observer
class EditSite extends Component<Props> {
  @observable tabIndex = 0
  @observable site: any

  async componentDidMount() {
    const { match } = this.props
    const site = Store.tenant?.sites.find(s => s.id === match.params.id)

    if (!site) {
      await Store.loadTenant()
    }
    this.site = Store.tenant!.sites.find(s => s.id === match.params.id)
  }

  handleChange = (event: any, newValue: any) => {
    this.tabIndex = newValue
  }

  handleCallback = (childData:any) =>{
    this.tabIndex = childData
}

  async update() {
    const unknownGroup = this.site.cookieGroups.find((g: any) => g.id === 'Unknown')
    if (unknownGroup && unknownGroup.cookies && unknownGroup.cookies.length === 0) {
      this.site.status = 'ACTIVE'
      _.remove(this.site.cookieGroups, (g: any) => g.id === 'Unknown')
    }

    try {
      await Store.updateSite(this.site)
      UIStore.showSnackbar($('site_updated.'))
    } catch {
      UIStore.showSnackbar($('error_updating_site.'))
    }
  }

  render() {
    const { classes } = this.props

    return (
      <DashboardLayout title={$('edit_site')}>
        <div className={classes.root}>
          <div className={classes.container}>
            <WebSiteCard parentCallback = {this.handleCallback}/>
            <div className={classes.tabsContainer}>
              <Tabs className={classes.tabs} value={this.tabIndex} onChange={this.handleChange} indicatorColor="primary" textColor="primary">
                <Tab label={$('site_information')} />
                <Tab label={$('user_interface')} />
                <Tab label={$('cookies')} />
                <Tab label={$('integration')} />
                <Tab label={$('customization')} />
              </Tabs>
              {(this.tabIndex !== 3) &&   <Button className={classes.button} color="primary" variant="contained" onClick={() => this.update()}>
                {$('update_site')}
              </Button>}
            
            </div>
            {this.site && (
              <div className={classes.content}>
                {Store.tenant && this.tabIndex === 0 && <Info site={this.site} />}
                {Store.tenant && this.tabIndex === 1 && <Texts site={this.site} />}
                {Store.tenant && this.tabIndex === 2 && <Cookies site={this.site} />}
                {Store.tenant && this.tabIndex === 3 && <Integration site={this.site} />}
                {Store.tenant && this.tabIndex === 4 && <Customization site={this.site} />}
              </div>
            )}
          </div>
        </div>
      </DashboardLayout>
    )
  }
}

export default withStyles(Styles)(EditSite)
