import { createStyles } from '@material-ui/core'
import { BorderStyle } from '@material-ui/icons'

export default (theme: any) =>
  createStyles({
    root: {
      borderWidth:1,
      borderStyle: 'solid',
      borderColor: '#fef7f7',
      display: 'flex',
      alignItems: 'center',
      justifyContent:'center',
      maxWidth: '100%',
      borderRadius: 18,
      boxShadow: 'rgba(50, 50, 93, 0.25) 0px 30px 60px -12px, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px'
    },
    content: {
      display: 'flex',
      overflowX: 'auto',
      '&::-webkit-scrollbar': {
        display: 'visible',
        
      },
      backgroundColor: '#ffffff',
      borderRadius:18,
      borderStyle:'solid',
      borderColor:'#F7FAFC'
    },
    item: {
      backgroundColor: '#e74c3c',
      minWidth: 200,
      margin: 5,
      minHeight: 120,
    },
    
  })
