import { createStyles } from '@material-ui/core'

export default (theme: any) =>
  createStyles({
    root: {
      width: '100%',
      maxWidth: 600,
      backgroundColor: theme.palette.background.paper,
    },
    country: {
      display: 'flex',
      marginRight: '10px',
      minHeight: '40px',
      alignItems: 'center',
    },
    countryName: {
      marginLeft: '3px',
    },
    outlined: {
      borderRadius: '4px',
      borderColor: '#0000003b',
      borderStyle: 'solid',
      borderWidth: '1px',
      minHeight: '40px',
      display: 'flex',
      alignItems: 'center',
      paddingLeft: '5px',
      paddingRight: '5px',
      flexWrap: 'wrap',
    },
  })
