import React, { Component } from 'react'
import classNames from 'classnames'
import { Bar, Pie, Doughnut, Radar } from 'react-chartjs-2'
import { withStyles, WithStyles } from '@material-ui/core'
import { Portlet, PortletHeader, PortletLabel, PortletContent,Select } from 'components'
import { GetText as $ } from 'stores/UITexts'
import viewsChart from 'assets/viewsChart'
import { options } from './chart'
import styles from './styles'
import palette from 'theme/palette'

interface Props extends WithStyles<typeof styles> {
  className?: string
  labels: string[]
  mobilePerformance: number[]
  mobileMarketing: number[]
  mobileFunctional: number[]
  mobileRejected: number[]
  performance: number[]
  functional: number[]
  marketing: number[]
  rejected: number[]
}

interface IState {
  selectedLabel?: string;
}

class SalesChart extends Component<Props, IState> {

  constructor(props: Props) {
    super(props);

    this.state = {
      selectedLabel: 'Bar'
    };
  }

  createChart = (chartData:any,options:any,selectedLabel:any)=> {
    if(selectedLabel ==='Bar'){
      return(
        <Bar data={chartData} options={options} />
        )
    }else if(selectedLabel === 'Pie'){
      return(
      <Pie data={chartData} options={options} />
      )
    }else if(selectedLabel === 'Doughnut'){
      return(
      <Doughnut data={chartData} options={options} />
      )
    }else if(selectedLabel === 'Radar'){
      return(
      <Radar data={chartData} options={options} />
      )
    }else {
      return (
        <Bar data={chartData} options={options} />
      )
    }
  }

  render() {
    const { classes, className, labels, mobilePerformance, mobileMarketing, mobileFunctional, mobileRejected, performance, functional, marketing, rejected,...rest } = this.props
    const rootClassName = classNames(classes.root, className)

    const chartData = {
      labels: labels,
      datasets: [
        {
          label: 'Desktop Performance',
          backgroundColor: 'rgb(11, 102, 217)',
          data: performance,
          stack: 'Stack 0'
        },
        {
          label: 'Mobile Performance',
          backgroundColor: 'rgba(11, 102, 217, 0.7)',
          data: mobilePerformance,
          stack: 'Stack 0'
        },
        {
          label: 'Desktop Functional',
          backgroundColor: 'rgb(255, 23, 15)',
          data: functional,
          stack: 'Stack 1'
        },
        {
          label: 'Mobile Functional',
          backgroundColor: 'rgba(255, 23, 15, 0.7)',
          data: mobileFunctional,
          stack: 'Stack 1'
        },
        {
          label: 'Desktop Marketing',
          backgroundColor: 'rgb(255, 184, 34)',
          data: marketing,
          stack: 'Stack 2'
        },
        {
          label: 'Mobile Marketing',
          backgroundColor: 'rgba(255, 184, 34, 0.7)',
          data: mobileMarketing,
          stack: 'Stack 2'
        },
        {
          label: 'Rejected Consents',
          backgroundColor: 'rgb(63, 63, 63)',
          data: rejected,
          stack: 'Stack 3'
        },
        {
          label: 'Mobile Rejected Consents',
          backgroundColor: 'rgba(63, 63, 63, 0.7)',
          data: mobileRejected,
          stack: 'Stack 3'
        },
      ],
    }



    return (
      <Portlet {...rest} className={rootClassName}>
        <PortletHeader noDivider>
          <PortletLabel title={$('dashboard.consent_graph')} />
          <div style={{ display: 'flex', gap: 20 }}>
            <div style={{ display: 'flex', alignItems: 'center', gap:10 }}>
              <div style={{ borderRadius: 18, width: 50, height: 50, backgroundColor: '#0867db' }}></div>
              <PortletLabel title={$('dashboard.graph_performance')} />{' '}
            </div>
            <div style={{ display: 'flex', alignItems: 'center',gap:10 }}>
              <div style={{ borderRadius: 18, width: 50, height: 50, backgroundColor: '#FFB822' }}></div>
              <PortletLabel title={$('dashboard.graph_advertisement')} />{' '}
            </div>
            <div style={{ display: 'flex', alignItems: 'center',gap:10}}>
              <div style={{ borderRadius: 18, width: 50, height: 50, backgroundColor: '#FF170F' }}></div>
              <PortletLabel title={$('dashboard.graph_functional')} />{' '}
            </div>
            <div style={{ display: 'flex', alignItems: 'center',gap:10}}>
              <div style={{ borderRadius: 18, width: 50, height: 50, backgroundColor: '#3f3f3f' }}></div>
              <PortletLabel title={$('dashboard.graph_rejectedConsents')} />{' '}
            </div>
            <div style={{ display: 'flex', alignItems: 'center',gap:10, width:'80%'}}>
            <Select
                label={''}
                items={Object.keys(viewsChart)
                  .sort()
                  .map(c => ({ key: c, value: viewsChart[c] }))}
                value={this.state.selectedLabel}
                onChange={i => this.setState({ selectedLabel :i.value})}
              />
            </div>
          </div>
        </PortletHeader>
        <PortletContent>
          <div className={classes.chartWrapper}>
            {this.createChart(chartData, options, this.state.selectedLabel)}
          </div>
        </PortletContent>
      </Portlet>
    )
  }
}

export default withStyles(styles)(SalesChart)
