import React from 'react'
import DestinationCard from '../WebSitesCard/DestinationCard'
import './style.css'

interface WebsiteCardProps {
  parentCallback: any
}

const WebSiteCard: React.FC<WebsiteCardProps> = (props: WebsiteCardProps) => {
  
 const onTrigger = (value: any) => (event: any) => {
    props.parentCallback(value)
  }

  return (
    <div className="content-wrapper">
      <DestinationCard
        title={'Desteğe mi ihtiyacınız var?'}
        description={'Bize sorun bildirmek için sağ alttaki mesajlaşma bölümünü kullanabilirsiniz.'}
        image={require('./assets/support.jpg')}
        value={0}
        onTrigger={onTrigger} 
        parentCallback={undefined}      
        />
      <DestinationCard
        title={'Site Bilgisini Güncelle'}
        description={
          'Site Bilgisi sekmesinden Cookie Seal tercih ekranlarında görünmesini istediğiniz metinsel içerikleri değiştirebilirsiniz.'
        }
        image={'https://images.pexels.com/photos/631954/pexels-photo-631954.jpeg?auto=compress&cs=tinysrgb&h=750&w=1260'}
        value={1}
        onTrigger={onTrigger}
        parentCallback={undefined} 
      />
      <DestinationCard
        title={'Site içerisindeki çerezleri yönet'}
        description={'Çerezler sekmesinden web sitenizde bulunan çerezleri kategorileri ile görebilirsiniz.'}
        image={require('./assets/management.jpg')}
        onTrigger={onTrigger}
        parentCallback={undefined}      
        value={2}
      />
      <DestinationCard
        title={'Entegrasyon'}
        description={"Kolay bir şekilde CookieSeal'i websitenize entegre edebilirsiniz."}
        image={require('./assets/chat.png')}
        onTrigger={onTrigger}
        parentCallback={undefined}      

        value={3}
      />
      <DestinationCard
        title={'Özelleştir'}
        description={'Size özel hazırlanan kod bloğu ile site içerisindeki bannerınızı özelleştirebilirsiniz.'}
        image={require('./assets/customization.jpg')}
        onTrigger={onTrigger}
        parentCallback={undefined}      
        value={4}
      />
    </div>
  )
}

export default WebSiteCard
