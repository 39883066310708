import CloseIcon from '@material-ui/icons/Close'
import Drawer from '@material-ui/core/Drawer'
import paymentPlanStore from 'stores/payment-plan-store'
import React, { Component } from 'react'
import Store from 'stores/store'
import Styles from './styles'
import UIStore from 'stores/ui-store'
import { IconButton, Snackbar, WithStyles, withStyles } from '@material-ui/core'
import { observer } from 'mobx-react'
import { Sidebar, Topbar } from './components'

interface Props extends WithStyles<typeof Styles> {
  title: string
  children: any
}

@observer
class Dashboard extends Component<Props, {}> {
  constructor(props: any) {
    super(props)
    this.state = {
      drawerIsOpen: true,
    }
  }
  componentDidMount() {
    Store.loadTenant()
    paymentPlanStore.load()
  }

  render() {
    const classes = this.props.classes

    return (
      <div className={classes.root}>
        <Topbar className={classes.topbar} title={this.props.title} />
        <Drawer
          anchor="left"
          className={classes.drawer}
          classes={{ paper: classes.drawerPaper }}
          onClose={(ev, reason) => this.setState({ drawerIsOpen: false })}
          open={false}
          variant="permanent"
        >
          <Sidebar className={classes.sidebar} />
        </Drawer>
        <main className={classes.content}>{this.props.children}</main>
        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          open={UIStore.isSnackbarOpen}
          autoHideDuration={2500}
          onClose={() => (UIStore.isSnackbarOpen = false)}
          message={UIStore.snackbarMessage}
          action={
            <React.Fragment>
              <IconButton size="small" aria-label="close" color="inherit" onClick={() => (UIStore.isSnackbarOpen = false)}>
                <CloseIcon fontSize="small" />
              </IconButton>
            </React.Fragment>
          }
        />
      </div>
    )
  }
}

export default withStyles(Styles)(Dashboard)
