import AccountSettings from 'views/account'
import CookieList from 'views/cookie-list'
import Dashboard from 'views/dashboard'
import EditSite from 'views/site'
import Prices from 'views/prices'
import React, { Component } from 'react'
import RegisterSite from 'views/register-site'
import Signin from 'views/signin'
import SignUp from 'views/signup'
import TSoftSignUp from 'views/TSoftSignup'
import Sites from 'views/sites'
import Tenants from 'views/tenants'
import ContactUs from 'views/contact'
import { observer } from 'mobx-react'
import { Route, Switch } from 'react-router-dom'
import Entegration from 'views/Entegration'

@observer
@observer
export default class Routes extends Component {
  render() {
    return (
      <Switch>
        <Route component={Signin} path="/signin" />
        <Route component={SignUp} path="/signup" />
        <Route component={Dashboard} path="/dashboard" />
        <Route component={Sites} path="/sites" />
        <Route component={RegisterSite} path="/register" />
        <Route component={CookieList} path="/cookies" />
        <Route component={EditSite} path="/site/:id" />
        <Route component={AccountSettings} path="/account" />
        <Route component={Tenants} path="/tenants" />
        <Route component={Prices} path="/prices" />
        <Route component={ContactUs} path="/contact-us" />
        <Route component={Entegration} path="/entegration" />
        <Route component={TSoftSignUp} path="/tsoft-signup" />
        {/* <Route component={Auth} path="/contact-us" /> */}
      </Switch>
    )
  }
}
