import axiosInstance from './axios'
import { action, observable } from 'mobx'
import { Country, State } from 'stores/models'

class Address {
  @observable countries!: Country[]
  @observable states!: State[]
  @observable userCountry!: Country | undefined
  @observable userState!: State | undefined
  // manually set loading to false after load().then()
  @observable loadingCountries = true
  @observable loadingStates = true

  @action async loadCountries() {
    try {
      const response = await axiosInstance.get('api/countries')
      this.countries = response.data
    } catch (error) {
    } finally {
    }
  }

  @action async loadStates(countryId: string) {
    try {
      const response = await axiosInstance.get(`api/states/${countryId}`)
      this.states = response.data
    } catch (error) {
    } finally {
    }
  }

  @action changeUserCountry(key: string) {
    this.userCountry = this.countries.find(country => country._id === parseInt(key))
  }

  @action handleUserCountry(countryId: string) {
    this.changeUserCountry(countryId)
    this.loadStates(countryId)
  }

  @action changeUserState(key: string) {
    this.userState = this.states.find(state => state._id === parseInt(key))
  }
}

export default new Address()
