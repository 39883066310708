import _ from 'lodash'
import axiosInstance from './axios'
import { action, observable } from 'mobx'
import { TenantInfo, UserInfo } from './models'

class Store {
  @observable authenticated: boolean = false
  @observable user: UserInfo
  @observable tenant: TenantInfo

  @observable progress = false

  constructor() {
    axiosInstance
      .get<UserInfo>('auth/profile')
      .then(response => {
        this.authenticated = true
        this.user = response.data
      })
      .catch(error => {
        if (!window.location.href.includes('signin') && !window.location.href.includes('signup')) window.location.href = '/signin'
        this.authenticated = false
      })
  }

  @action async signIn({ email, password }: { email: string; password: string }) {
    const response = await axiosInstance.post<UserInfo>(`auth/login`,{
      email: email,
      password: password,
    })
    this.user = response.data
    this.authenticated = true
  }

  @action async signOut() {
    await axiosInstance.post(`auth/logout`, {})
    this.authenticated = false
  }

  @action async signUp(data: any): Promise<{ success: boolean; message: string }> {
    const response = await axiosInstance.post('/tenant', data)
    if (response.data.success) {
      return { success: true, message: '' }
    }
    return { success: false, message: response.data.msg }
  }

  @action async signUpWithOutPayment(data: any): Promise<{ success: boolean; message: string }> {
    const response = await axiosInstance.post('/tenant', data)
    if (response.data.success) {
      return { success: true, message: '' }
    }
    return { success: false, message: response.data.msg }
  }

  @action async crawlSite(url: string) {
    const response = await axiosInstance.get('tenant/sites/crawl', { params: { url: url } })
    return response.data
  }

  @action async checkSiteUrl(url: string) {
    const response = await axiosInstance.get('tenant/sites/check', { params: { url: url } })
    return response.data
  }

  @action async checkEmail(email: string) {
    const response = await axiosInstance.get('auth/checkemail', { params: { email } })
    return response.data
  }


  @action async loadTenant() {
    const response = await axiosInstance.get<TenantInfo>('tenant')
    this.tenant = response.data
  }

  @action async updateTenant(data: any) {
    const response = await axiosInstance.patch<TenantInfo>('tenant', data)
    this.tenant = response.data
  }

  @action async updatePassword(data: any) {
    await axiosInstance.patch('auth/password', data)
  }

  @action async registerSite(data: {
    siteName: string
    siteUrl: string
    cardHolderName: string
    cardNumber: string
    expireMonth: string
    expireYear: string
    pricingPlanReferenceCode: string
    ccv: string
  }): Promise<{ success: boolean; message: string }> {
    const response = await axiosInstance.post('tenant/sites', data)
    if (response.data.success) {
      return { success: true, message: '' }
    }
    return { success: false, message: response.data.msg }
  }

  @action async updateSite(siteInfo: any) {
    const updateRequest = {
      id: siteInfo.id,
      name: siteInfo.name,
      urls: siteInfo.urls,
      preferredLanguage: siteInfo.preferredLanguage,
      supportedLanguages: siteInfo.supportedLanguages,
      blacklistedCountries: siteInfo.blacklistedCountries,
      status: siteInfo.status,
      uiTexts: siteInfo.uiTexts,
      uiTheme: siteInfo.uiTheme,
      cookieGroups: siteInfo.cookieGroups,
      cookiePolicyUrl: siteInfo.cookiePolicyUrl,
      showCookiePolicyOnBanner: siteInfo.showCookiePolicyOnBanner
    }
    const response = await axiosInstance.patch('tenant/sites', updateRequest)
    const index = _.findIndex(this.tenant.sites, { id: response.data.id })
    this.tenant.sites[index] = response.data
  }

  @action async suspendSite(siteId: string) {
    const response = await axiosInstance.delete('tenant/sites', { data: { siteId: siteId } })
    this.tenant = response.data
  }
}

export default new Store()
