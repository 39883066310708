import { createStyles, Theme } from '@material-ui/core/styles'
import { relative } from 'path'

export default (theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: 'theme.palette.common.white',
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
      borderTopRightRadius:24,
      borderBottomRightRadius:24,
      paddingLeft: theme.spacing(),
    },
    logoWrapper: {
      display: 'flex',
      justifyContent: 'center',
      borderRadius:'100%',
      borderWidth:1,
      borderColor:'#394172',
      alignItems: 'center',
      height: 100,
      width: '100%',
      flexShrink: 0,
    },
    logoLink: {
      fontSize: 0,
    },
    logoImage: {
      cursor: 'pointer',
    },
    logoDivider: {
      marginBottom: theme.spacing(2),
    },
    profile: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent:'center',
      borderWidth:1,
      borderStyle:'solid',
      padding:10,
      margin:10,
      borderRadius:24,
      backgroundColor:'#ffffff',
      color:'#394172',
      borderColor:'#394172'
    },
    avatar: {
      width: '100px',
      height: '100px',
    },
    nameText: {
      marginTop: theme.spacing(2),
    },
    bioText: {color:'#394172',paddingBottom:10,fontSize:16},
    profileDivider: {
      marginBottom: theme.spacing(2),
      marginTop: theme.spacing(2),
    },
    listSubheader: {
      color: theme.palette.text.secondary,
    },
    listItem: {
      marginTop:10,
      cursor: 'pointer',
      '&:hover': {
        backgroundColor: theme.palette.primary.light,
        borderLeft: `4px solid #394172`,
        borderRadius: '10px',
        '& $listItemIcon': {
          color: '#394172',
          marginLeft: '-4px',
        },
      },
      '& + &': {
        marginTop: theme.spacing(),
      },
    },
    listItemTwo: {
      marginTop:10,
      cursor: 'pointer',
      '&:hover': {
        backgroundColor: theme.palette.primary.light,
        borderLeft: `4px solid #FFCCC0`,
        borderRadius: '10px',
        '& $listItemIcon': {
          color: '#FFCCC0',
          marginLeft: '-4px',
        },
      },
      '& + &': {
        marginTop: theme.spacing(),
      },
    },
    listItemThree: {
      marginTop:10,
      cursor: 'pointer',
      '&:hover': {
        backgroundColor: theme.palette.primary.light,
        borderLeft: `4px solid #B3B3FE`,
        borderRadius: '10px',
        '& $listItemIcon': {
          color: '#B3B3FE',
          marginLeft: '-4px',
        },
      },
      '& + &': {
        marginTop: theme.spacing(),
      },
    },
    activeListItem: {
      borderLeft: `4px solid #394172`,
      borderRadius: '10px',
      backgroundColor: theme.palette.primary.light,
      '& $listItemText': {
        color: '#394172',
      },
      '& $listItemIcon': {
        color: '#394172',
        marginLeft: '-4px',
      },
    },
    activeListItemTwo: {
      borderLeft: `4px solid '#FFCCC0'`,
      borderRadius: '10px',
      backgroundColor: theme.palette.primary.light,
      '& $listItemText': {
        color: '#FFCCC0',
      },
      '& $listItemIcon': {
        color: '#FFCCC0',
        marginLeft: '-4px',
      },
    },
    activeListItemThree: {
      borderLeft: `4px solid #B3B3FE`,
      borderRadius: '10px',
      backgroundColor: theme.palette.primary.light,
      '& $listItemText': {
        color: '#B3B3FE',
      },
      '& $listItemIcon': {
        color: '#B3B3FE',
        marginLeft: '-4px',
      },
    },
    listItemIcon: {
      marginRight: 0,
    },
    listItemText: {
      fontWeight: 500,
      color: theme.palette.text.secondary,
    },
    listDivider: {
      marginBottom: theme.spacing(2),
      marginTop: theme.spacing(2),
    },
    footer:{
      display: 'flex',
      bottom:0,
      left:0,
      
    }
  })
