import DashboardLayout from 'layouts/dashboard'
import React, { Component } from 'react'
import Store from 'stores/store'
import Styles from './styles'
import { GetText as $ } from 'stores/UITexts'
import { Typography, withStyles, WithStyles } from '@material-ui/core'
import { observer } from 'mobx-react'
import { Toolbar } from '../sites/components'
import { Button } from '@material-ui/core'
import { Table } from 'reactstrap'
import Integration from '../site/components/integration'

interface Props extends WithStyles<typeof Styles> { }

interface IState {
  selectedSite?: any
}

@observer
class Entegration extends Component<Props, IState> {
  constructor(props: Props) {
    super(props)

    this.state = {
      selectedSite: null,
    }
  }

  async handleCrawl  (url: string) {
    await Store.crawlSite(url)
  }


  async setSite  (selectedSite: any) {
    if (!selectedSite) {
      await Store.loadTenant()
    }
    this.setState({selectedSite:selectedSite})  }


  render() {
    const { classes } = this.props

    return (
      <DashboardLayout title={$('websites')}>
        <div className={classes.root}>
          <Toolbar />
          <div className={classes.content}>
            <div style={{ backgroundColor: '#394172', width: '100%', borderRadius: 10, padding: 15, textAlign: 'start', color: '#FFFFFF' }}>
              <Typography variant="h5" className={classes.titleHead} style={{ justifySelf: 'flex-start', color: '#FFFFFF', fontSize: 18 }}>
                {$('integration.description')}
              </Typography>
            </div>
            {this.state.selectedSite
            ? [
                <>
                            <div style={{ marginTop: 20 }}>

                  <Button className={classes.button} variant="contained" onClick={() => this.setState({selectedSite:null})}>
                    Site Değiştir
                  </Button>
                  <Integration site={this.state.selectedSite} />
                  </div>

                </>
              ]
            : 
            [
            <div style={{ marginTop: 20 }}>
              <Table >
                <thead>
                  <tr>
                    <th>No.</th>
                    <th>{$('site_name')}</th>
                    <th>{$('language')}</th>
                    <th>{$('status')}</th>
                    <th></th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {Store.tenant?.sites.map((item, index) => {
                    return (
                      <tr>
                        <td>{index + 1}</td>
                        <td>{item.name}</td>
                        <td>{item.preferredLanguage}</td>
                        <td style={{ display: 'flex', textAlign: 'center' }}><span style={{ padding: 10, backgroundColor: `${item.status === 'COMPLETED' ? '#394172' : '#23c710'}`, color: '#FFFFFF', textAlign: 'center', borderRadius: 20 }}>{item.status === 'COMPLETED' ? 'READY FOR USE' : item.status}</span></td>
                        <td>
                            <Button className={classes.button} variant="contained" onClick={() => this.handleCrawl(item.urls[0])}  >
                               {$('crawl')}
                            </Button>
                        </td>
                        <td>
                            <Button className={classes.button} variant="contained" onClick={() => this.setState({selectedSite:item})} >
                              <span role="img" aria-label="rocket">🚀</span> {$('select')}
                            </Button>
                        </td>
                      </tr>
                    )
                  })}
                </tbody>
              </Table>
            </div>
            ]}
          </div>
        </div>
      </DashboardLayout>
    )
  }
}

export default withStyles(Styles)(Entegration)
