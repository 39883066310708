import React, { StatelessComponent } from 'react'
import classNames from 'classnames'
import { createStyles, withStyles, WithStyles } from '@material-ui/core'

const styles = (theme: any) =>
  createStyles({
    root: {
      display: 'inline-block',
      borderRadius: '50%',
      flexGrow: 0,
      flexShrink: 0,
    },
    sm: {
      height: theme.spacing(),
      width: theme.spacing(),
    },
    md: {
      height: theme.spacing() * 2,
      width: theme.spacing() * 2,
    },
    lg: {
      height: theme.spacing() * 3,
      width: theme.spacing() * 3,
    },
    neutral: {
      backgroundColor: theme.palette.common.neutral,
    },
    primary: {
      backgroundColor: theme.palette.primary.main,
    },
    info: {
      backgroundColor: theme.palette.info.main,
    },
    warning: {
      backgroundColor: theme.palette.warning.main,
    },
    danger: {
      backgroundColor: theme.palette.danger.main,
    },
    success: {
      backgroundColor: theme.palette.success.main,
    },
  })

interface Props extends WithStyles<typeof styles> {
  className: string
  color: 'neutral' | 'primary' | 'info' | 'success' | 'warning' | 'danger'
  size: 'sm' | 'md' | 'lg'
}

const Status: StatelessComponent<Props> = (props: Props) => {
  const { classes, className, size, color, ...rest } = props

  const rootClassName = classNames(
    {
      [classes.root]: true,
      [classes[size]]: size,
      [classes[color]]: color,
    },
    className
  )

  return <span {...rest} className={rootClassName} />
}

Status.defaultProps = {
  size: 'md',
  color: 'neutral', // Couldn't make "default" work
}

export default withStyles(styles)(Status)
