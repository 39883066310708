import React from 'react'
import classNames from 'classnames'
import { createStyles, makeStyles, Theme } from '@material-ui/core'
import { Paper } from '@material-ui/core'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      borderRadius: '4px',
    },
    squared: {
      borderRadius: 0,
    },
    outlined: {
      border: `1px solid ${(theme.palette as any).border}`,
    },
  })
)

const CustomPaper = (props: any) => {
  const classes = useStyles()
  const { className, outlined, squared, children, ...rest } = props

  const rootClassName = classNames(
    {
      [classes.root]: true,
      [classes.squared]: squared,
      [classes.outlined]: outlined,
    },
    className
  )

  return (
    <Paper {...rest} className={rootClassName}>
      {children}
    </Paper>
  )
}

export default CustomPaper
