import React, { Component } from 'react'
import { createStyles, Typography } from '@material-ui/core'
import { GetText as $ } from 'stores/UITexts'
import { observer } from 'mobx-react'
import { withStyles, WithStyles } from '@material-ui/core'


// material-ui

// ==============================|| SKELETON - TOTAL INCOME DARK/LIGHT CARD ||============================== //

const styles = (theme: any) =>
  createStyles({
    root: {
      borderWidth: 1,
      borderStyle: 'solid',
      borderColor: '#dcdcdc',
      backgroundColor: '#fff',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      maxWidth: '100%',
      borderRadius: 5,
      padding: 20,
    },
  })

interface Props extends WithStyles<typeof styles> {
  className?: string
}

const WhiteTextTypography = withStyles({
  root: {
    color: "#ffffff"
  }
})(Typography);


@observer
class NoConsents extends Component<Props> {
  render() {
    const { classes, className, ...rest } = this.props

    return (

      // <Card sx={{ p: 2 }}>
      //   <List sx={{ py: 0 }}>
      //     <ListItem alignItems="center" disableGutters sx={{ py: 0 }}>
      //       <ListItemAvatar>
      //         <Skeleton variant="rectangular" width={44} height={44} />
      //       </ListItemAvatar>
      //       <ListItemText sx={{ py: 0 }} primary={<Skeleton variant="rectangular" height={20} />} secondary={<Skeleton variant="text" />} />
      //     </ListItem>
      //   </List>
      // </Card>
      <div {...rest} style={{backgroundColor:'#394172', width:'100%', borderRadius:18, padding:20, textAlign:'center', color:'#FFFFFF'}}>
        <WhiteTextTypography variant="h4">{$('no_consent_records_for_the_selected_context.')}</WhiteTextTypography>
      </div>
      
    )
  }
}

export default withStyles(styles)(NoConsents)
