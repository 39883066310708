import React, { useState, useEffect } from 'react'
import { BannerToolContext } from './BannerCustomization'
import styles from './Steps/banner.module.css'

const FrameComponent = () => {
  const value = React.useContext(BannerToolContext)

  const [place, setPlace] = useState()
  const [bgDiv, setBgDiv] = useState()
  const [bgButton, setBgButton] = useState()
  const [fontSizeS, setFontSizeS] = useState()

  useEffect(() => {
    switch (value.form1.value) {
      case 1:
        return setPlace(styles.fixedDivBottom)
      case 2:
        return setPlace(styles.fixedDivTop)
      case 3:
        return setPlace(styles.fixedDivRight)
      case 4:
        return setPlace(styles.fixedDivLeft)
      default:
        return setPlace(styles.fixedDivBottom)
    }
  }, [value.form1.value])

  useEffect(() => {
    setBgDiv(value.form2.value)
  }, [value.form2.value])

  useEffect(() => {
    setBgButton(value.form3.value)
  }, [value.form3.value])

  useEffect(() => {
    setFontSizeS(value.form4.value)
  }, [value.form4.value])

  const buttonNames = ['Ayarları Düzenle', 'Kabul Et', 'Hepsini Reddet']

  /*
  <button className={styles.btnStyles} style={{ backgroundColor: bgButton }}>
            Ayarları Düzenle
          </button>
          <button className={styles.btnStyles} style={{ backgroundColor: bgButton }}>
            Kabul Et
          </button>
          <button className={styles.btnStyles} style={{ backgroundColor: bgButton }}>
            Hepsi Reddet
          </button>
  */

  return (
    <div className={styles.container}>
      <svg width="100%" height="100%" viewBox="0 0 372 100" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M371.36 0H0.640015V494.344H371.36V0Z" fill="#EFEFEF" />
        <path d="M174.24 94.8H35.944V458.856H174.24V94.8Z" fill="white" />
        <path d="M155.624 111.92H54.568V117.808H155.624V111.92Z" fill="#2C3E50" />
        <path d="M155.624 131.784H54.568V137.672H155.624V131.784Z" fill="#E5E5E5" />
        <path d="M155.624 146.992H54.568V152.88H155.624V146.992Z" fill="#E5E5E5" />
        <path d="M155.624 162.192H54.568V168.08H155.624V162.192Z" fill="#E5E5E5" />
        <path d="M155.624 177.36H54.568V183.248H155.624V177.36Z" fill="#E5E5E5" />
        <path d="M155.624 192.56H54.568V198.448H155.624V192.56Z" fill="#E5E5E5" />
        <path d="M155.624 192.56H54.568V198.448H155.624V192.56Z" fill="#E5E5E5" />
        <path d="M155.624 207.8H54.568V213.688H155.624V207.8Z" fill="#E5E5E5" />
        <path d="M155.624 223.008H54.568V228.896H155.624V223.008Z" fill="#E5E5E5" />
        <path d="M155.624 238.208H54.568V244.096H155.624V238.208Z" fill="#E5E5E5" />
        <path d="M155.624 253.408H54.568V259.296H155.624V253.408Z" fill="#E5E5E5" />
        <path d="M155.624 253.408H54.568V259.296H155.624V253.408Z" fill="#E5E5E5" />
        <path d="M155.624 268.616H54.568V274.504H155.624V268.616Z" fill="#E5E5E5" />
        <path d="M155.624 283.816H54.568V289.704H155.624V283.816Z" fill="#E5E5E5" />
        <path d="M155.624 299.04H54.568V304.928H155.624V299.04Z" fill="#E5E5E5" />
        <path d="M155.624 314.24H54.568V320.128H155.624V314.24Z" fill="#E5E5E5" />
        <path d="M155.624 314.24H54.568V320.128H155.624V314.24Z" fill="#E5E5E5" />
        <path d="M155.624 329.44H54.568V335.328H155.624V329.44Z" fill="#E5E5E5" />
        <path d="M155.624 344.64H54.568V350.528H155.624V344.64Z" fill="#E5E5E5" />
        <path d="M155.624 359.832H54.568V365.72H155.624V359.832Z" fill="#E5E5E5" />
        <path d="M155.624 375.032H54.568V380.92H155.624V375.032Z" fill="#E5E5E5" />
        <path d="M155.624 375.032H54.568V380.92H155.624V375.032Z" fill="#E5E5E5" />
        <path d="M155.624 390.24H54.568V396.128H155.624V390.24Z" fill="#E5E5E5" />
        <path d="M155.624 405.44H54.568V411.328H155.624V405.44Z" fill="#E5E5E5" />
        <path d="M155.624 420.648H54.568V426.536H155.624V420.648Z" fill="#E5E5E5" />
        <path d="M155.624 435.84H54.568V441.728H155.624V435.84Z" fill="#E5E5E5" />
        <path d="M336.08 94.8H197.784V177.392H336.08V94.8Z" fill="#999999" />
        <path d="M336.08 188.624H197.784V271.216H336.08V188.624Z" fill="#999999" />
        <path d="M336.08 282.4H197.784V364.992H336.08V282.4Z" fill="#999999" />
        <path d="M336.08 376.24H197.784V458.832H336.08V376.24Z" fill="#999999" />
        <path d="M371.36 494.344H0.640015V512H371.36V494.344Z" fill="#999999" />
        <path d="M371.36 0H0.640015V59.488H371.36V0Z" fill="#999999" />
        <path d="M145.928 21.616H35.856V27.504H145.928V21.616Z" fill="white" />
        <path d="M120.152 31.992H35.856V37.88H120.152V31.992Z" fill="white" />
        <path d="M220.032 31.992H198.432V37.88H220.032V31.992Z" fill="#2C3E50" />
        <path d="M249.056 31.992H227.456V37.88H249.056V31.992Z" fill="white" />
        <path d="M278.016 31.992H256.416V37.88H278.016V31.992Z" fill="#2C3E50" />
        <path d="M306.976 31.992H285.376V37.88H306.976V31.992Z" fill="white" />
        <path d="M336.016 31.992H314.416V37.88H336.016V31.992Z" fill="#2C3E50" />
      </svg>
      <div className={place} style={{ backgroundColor: bgDiv }}>
        <div className={styles.text} style={{ fontSize: fontSizeS }}>
          Çerezler, içeriği ve reklamları kişiselleştirmek, sosyal medya özellikleri sağlamak ve trafiğimizi analiz etmek için kullanılmaktadır.
          “Kabul Et” seçeneği ile tüm çerezleri kabul edebilirsiniz veya “Çerez Ayarları” seçeneği ile ayarları düzenleyebilirsiniz.
        </div>
        <div className={styles.buttons}>
          {buttonNames.map(item => {
            return (
              <button className={styles.btnStyles} style={{ backgroundColor: bgButton }} key={item}>
                {item}
              </button>
            )
          })}
        </div>
      </div>
    </div>
  )
}

export default FrameComponent
