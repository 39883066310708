import { createStyles, Theme } from '@material-ui/core/styles'

export default (theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      height: '100%',
    },
    topbar: {
      position: 'fixed',
      width: '100%',
      top: 0,
      left: 0,
      right: 'auto',
      transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    drawer: {
      width: '200px',
    },
    drawerPaper: {
      zIndex: 1200,
      width: '200px',
    },
    sidebar: {
      width: '200px',
    },
    content: {
      flexGrow: 1,
      marginTop: '64px',
      backgroundColor: theme.palette.background.default,
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      overflowX: 'hidden'
    },
  })
