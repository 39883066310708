import { createStyles, Theme } from '@material-ui/core/styles'

export default (theme: Theme) =>
  createStyles({
    root: {},
    row: {
      height: '42px',
      display: 'flex',
      alignItems: 'center',
      marginTop: theme.spacing(),
    },
    spacer: {
      flexGrow: 1,
    },
    searchInput: {
      marginRight: theme.spacing(),
    },
    arrowIcon:{
      height:40,
      width:40
    }
  })
