import _ from 'lodash'
import countries from 'assets/countries'
import Address from 'stores/address'
import React, { Component } from 'react'
import Store from 'stores/store'
import uiStore from 'stores/ui-store'
import validate from 'validate.js'
import { Button, createStyles, styled, TextField, Theme, withStyles, WithStyles } from '@material-ui/core'
import { GetText as $ } from 'stores/UITexts'
import { observable } from 'mobx'
import { observer } from 'mobx-react'
import { Portlet, PortletContent, PortletFooter, PortletHeader, PortletLabel, Select } from 'components'

import './buttontwo.css'
import { Add, SettingsApplications } from '@material-ui/icons'

const Styles = (theme: Theme) =>
  createStyles({
    root: {
      marginTop: theme.spacing() * 4,
      paddingLeft: 10,
      paddingRight: 10,
      borderRadius: 18,
      borderWidth: 1,
      maxWidth: '100%',
      borderStyle: 'solid',
      backgroundColor: '#fff',
      borderColor: '#001e50',
    },
    formContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'stretch',
    },
    column: {
      width: '49%',
    },
    field: {
      marginBottom: theme.spacing() * 3,
      width: '100%',
    },
    numberField: {
      marginBottom: theme.spacing() * 3,
      '& input::-webkit-clear-button, & input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
        '-webkit-appearance': 'none',
      },
    },
    button: {
      backgroundColor: '#394172',
    },
    select: {
      '& .MuiSelect-root': {
        height: '0.5em', // Adjust the height value as per your requirement
      },
    },
  })
 

const validationSchema = {
  name: { presence: { allowEmpty: false } },
  taxOffice: { presence: { allowEmpty: false } },
  taxNumber: { presence: { allowEmpty: false } },
  country: { presence: { allowEmpty: false } },
  city: { presence: { allowEmpty: false } },
  address: { presence: { allowEmpty: false } },
  zipCode: { presence: { allowEmpty: false } },
  firstName: { presence: { allowEmpty: false } },
  lastName: { presence: { allowEmpty: false } },
  email: { presence: { allowEmpty: false }, email: true },
  phone: { presence: { allowEmpty: false } },
}

interface Props extends WithStyles<typeof Styles> {}

@observer
class GeneralSettings extends Component<Props> {
  @observable values: { [key: string]: string } = {
    name: Store.tenant.name,
    taxOffice: Store.tenant.taxOffice,
    taxNumber: Store.tenant.taxNumber,
    country: Store.tenant.country,
    city: Store.tenant.city,
    address: Store.tenant.address,
    zipCode: Store.tenant.zipCode,
    firstName: Store.tenant.contactPerson.firstName,
    lastName: Store.tenant.contactPerson.lastName,
    email: Store.tenant.contactPerson.email,
    phone: Store.tenant.contactPerson.phone,
  }

  @observable touched: { [key: string]: boolean } = {
    name: false,
    taxOffice: false,
    taxNumber: false,
    country: false,
    city: false,
    address: false,
    zipCode: false,
    firstName: false,
    lastName: false,
    email: false,
    phone: false,
  }
  @observable errors: { [key: string]: string[] } = {
    name: [],
    taxOffice: [],
    taxNumber: [],
    country: [],
    city: [],
    address: [],
    zipCode: [],
    firstName: [],
    lastName: [],
    email: [],
    phone: [],
  }

  @observable isValid = false
  @observable error = ''

  validateForm = _.debounce(() => {
    const errors = validate(this.values, validationSchema)
    this.errors = errors || {}
    this.isValid = errors ? false : true
  }, 300)

  handleFieldChange = (field: string, value: string) => {
    this.error = ''
    this.touched[field] = true
    this.values[field] = value
    this.validateForm()
  }

  checkError(field: string) {
    return this.touched[field] && this.errors[field] && this.errors[field].length > 0
  }

  getError(field: string, label: string) {
    return this.checkError(field) ? this.errors[field][0] : label
  }

  async componentDidMount() {
    await Address.loadCountries().then(() => {
      Address.userCountry = Address.countries.find(c => c._id.toString() === Store.tenant.country)
      Address.loadingCountries = false
      if (Address.userCountry) {
        Address.loadStates(Address.userCountry?._id.toString()).then(() => {
          Address.userState = Address.states.find(c => c._id.toString() === Store.tenant.city)
          Address.loadingStates = false
        })
      }
    })
  }

  async update() {
    try {
      await Store.updateTenant(this.values)
      uiStore.showSnackbar($('tenant_information_updated'))
    } catch {
      uiStore.showSnackbar($('error_updating_tenant'))
    }
  }

  render() {
    const { classes } = this.props

    return (
      <>
        <Portlet className={classes.root}>
          <PortletHeader>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <SettingsApplications />
              <PortletLabel title={$('general_settings')} />
            </div>
          </PortletHeader>
          <PortletContent>
            <div className={classes.formContainer}>
              <div className={classes.column}>
                <TextField
                  label={$('company_name')}
                  variant="standard"
                  fullWidth
                  className={classes.field}
                  name="name"
                  value={this.values.name}
                  error={this.checkError('name')}
                  onChange={e => this.handleFieldChange('name', e.target.value)}
                />
                <TextField
                  label={$('tax_office')}
                  variant="standard"
                  fullWidth
                  className={classes.field}
                  name="taxOffice"
                  value={this.values.taxOffice}
                  error={this.checkError('taxOffice')}
                  onChange={e => this.handleFieldChange('taxOffice', e.target.value)}
                />
                <TextField
                  label={$('tax_number')}
                  variant="standard"
                  fullWidth
                  className={classes.numberField}
                  name="taxNumber"
                  type="number"
                  value={this.values.taxNumber}
                  error={this.checkError('taxNumber')}
                  onChange={e => this.handleFieldChange('taxNumber', e.target.value)}
                />
                <TextField
                  label={$('contact_first_name')}
                  variant="standard"
                  fullWidth
                  className={classes.field}
                  name="firstName"
                  value={this.values.firstName}
                  error={this.checkError('firstName')}
                  onChange={e => this.handleFieldChange('firstName', e.target.value)}
                />
                <TextField
                  label={$('contact_last_name')}
                  variant="standard"
                  fullWidth
                  className={classes.field}
                  name="lastName"
                  value={this.values.lastName}
                  error={this.checkError('lastName')}
                  onChange={e => this.handleFieldChange('lastName', e.target.value)}
                />
              </div>
              <div className={classes.column} style={{marginTop:'0.2rem'}}>
                {!Address.loadingCountries && (
                  <Select
                    className={classes.select}
                    label={this.getError('country', $('country'))}
                    value={Address?.userCountry?._id.toString()}
                    items={Address.countries?.map(c => ({ key: c._id.toString(), value: c.native })) || [{ key: '', value: '' }]}
                    onChange={({ key }) => {
                      Address.handleUserCountry(key)
                      this.values.country = key
                    }}
                  />
                )}
                {!Address.loadingStates && (
                  <Select
                    className={classes.select}
                    label={this.getError('city', $('city'))}
                    value={Address.userState?._id.toString()}
                    items={Address.states?.map(c => ({ key: c._id.toString(), value: c.name })) || [{ key: '', value: '' }]}
                    onChange={({ key }) => {
                      Address.changeUserState(key)
                      this.values.city = key
                    }}
                  />
                )}
                <TextField
                  label={$('street_address')}
                  variant="standard"
                  fullWidth
                  className={classes.field}
                  name="address"
                  value={this.values.address}
                  error={this.checkError('address')}
                  onChange={e => this.handleFieldChange('address', e.target.value)}
                />
                <TextField
                  label={$('zip_code')}
                  variant="standard"
                  fullWidth
                  className={classes.numberField}
                  name="zipCode"
                  type="number"
                  value={this.values.zipCode}
                  error={this.checkError('zipCode')}
                  onChange={e => this.handleFieldChange('zipCode', e.target.value)}
                />
                <TextField
                  label={$('contact_email')}
                  variant="standard"
                  fullWidth
                  className={classes.field}
                  name="email"
                  value={this.values.email}
                  error={this.checkError('email')}
                  onChange={e => this.handleFieldChange('email', e.target.value)}
                />
                <TextField
                  label={$('contact_phone')}
                  variant="standard"
                  fullWidth
                  className={classes.field}
                  name="phone"
                  value={this.values.phone}
                  error={this.checkError('phone')}
                  onChange={e => this.handleFieldChange('phone', e.target.value)}
                />
              </div>
            </div>
          </PortletContent>
          <PortletFooter>
            <Button color="primary" className={classes.button} variant="contained" onClick={() => this.update()}>
              {$('update_company_information')}
            </Button>
          </PortletFooter>
        </Portlet>
        {/* <Description/> */}
      </>
    )
  }
}

export default withStyles(Styles)(GeneralSettings)
