import classNames from 'classnames'
import React, { Component } from 'react'
import Styles from './styles'
import { Button } from '@material-ui/core'
import { GetText as $ } from 'stores/UITexts'
import { Link, LinkProps } from 'react-router-dom'
import { withStyles, WithStyles } from '@material-ui/core'

interface Props extends WithStyles<typeof Styles> {
  className?: string
}

class Toolbar extends Component<Props> {
  render() {
    const RegisterSiteLink = React.forwardRef<HTMLAnchorElement, Omit<LinkProps, 'innerRef' | 'to'>>((props, ref) => (
      <Link innerRef={ref as any} to="/register" {...props} />
    ))

    const { classes, className } = this.props as any
    const rootClassName = classNames(classes.root, className)

    return (
      <div className={rootClassName}>
        <div className={classes.row}>

          <span className={classes.spacer} />
          <img
                alt="icon"
                src="/images/right_arrow.png"
                className={classes.arrowIcon}
              />
          <Button color="primary" size="small" variant="outlined" component={RegisterSiteLink}>
            {$('register_new_site')}
          </Button>
        </div>
      </div>
    )
  }
}

export default withStyles(Styles)(Toolbar)
