import axiosInstance from './axios'
import { action, observable, runInAction } from 'mobx'

class UIStore {
  @observable isInProgress = false
  @observable isSnackbarOpen = false
  @observable snackbarMessage = ''

  constructor() {
    axiosInstance.interceptors.request.use(
      request => {
        runInAction(() => (this.isInProgress = true))
        return request
      },
      (error: any) => {
        runInAction(() => (this.isInProgress = true))
        Promise.reject(error)
      }
    )

    axiosInstance.interceptors.response.use(
      response => {
        runInAction(() => (this.isInProgress = false))
        return response
      },
      (error: any) => {
        runInAction(() => (this.isInProgress = true))
        Promise.reject(error)
      }
    )
  }

  @action showSnackbar(message: string) {
    this.snackbarMessage = message
    this.isSnackbarOpen = true
  }
}

export default new UIStore()
