import React from 'react'
import './style.css'
import { GetText as $ } from 'stores/UITexts'

interface AccountCardProps {
  parentCallback: any
}

const AccountCard: React.FC<AccountCardProps> = (props: AccountCardProps) => {
  let onTrigger = (value: any) => (event: any) => {
    props.parentCallback(value)
  }
  return (
    <div className="wrapperDiv">
      <div className="box" onClick={onTrigger(0)} style={{borderRadius:20}}>
        <div className="content">
          <div className="text">
            <h3 style={{paddingLeft:6, paddingTop:7}}>{$('general')}</h3>
            <p style={{paddingLeft:6}}>{$('general_description')}</p>
          </div>
        </div>
      </div>
      <div className="box" onClick={onTrigger(1)} style={{borderRadius:20}}>
        <div className="content">
          <div className="text">
            <h3 style={{paddingLeft:6, paddingTop:7}}>{$('subscriptions')}</h3>
            <p style={{paddingLeft:6}}>{$('subscriptions_description')}</p>
          </div>
        </div>
      </div>
      <div className="box" onClick={onTrigger(2)} style={{borderRadius:20}}>
        <div className="content">
          <div className="text">
            <h3 style={{paddingLeft:6, paddingTop:7}}>{$('security')}</h3>
            <p style={{paddingLeft:6}}>{$('security_description')}</p>
          </div>
        </div>
      </div>
      <div className="box" onClick={onTrigger(3)} style={{borderRadius:20}}>
        <div className="content">
          <div className="text">
            <h3 style={{paddingLeft:6, paddingTop:7}}>{$('buyCookieseal')}</h3>
            <p style={{paddingLeft:6}}>{$('buyCookieseal_description')}</p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AccountCard
