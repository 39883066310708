import React, { Component } from 'react';
import Styles from './styles';
import { withStyles, WithStyles } from '@material-ui/core';
import { Card, CardBody, CardTitle, Row, Col } from "reactstrap";

interface Props extends WithStyles<typeof Styles> {
  desktopValue:number
  mobileValue:number
  title: string
  icon: 'performance' | 'advertising' | 'functional'
  className?: string
  color: string
}

class ConsentSummary extends Component<Props> {
  render() {
    const {
      title,
      desktopValue,
      mobileValue,
      color,
    } = this.props

    return (
      <Card className="mb-xl-0" style={{ backgroundColor: color, borderRadius: 20, marginRight: 20 }}>
        <CardBody>
          <Row>
            <div className="col">
              <CardTitle
                tag="h6"
                className="text-muted mb-0 "
              >
                <span className='text-white'>{title}</span>
              </CardTitle>
              <span className="h6 font-weight-bold mb-0 text-white p-1">
                <table>
                  <tbody>
                    <tr>
                      <td>Mobile</td>
                      <td className='ps-2'>{mobileValue}</td>
                    </tr>
                    <tr>
                      <td>Desktop</td>
                      <td className='ps-2'>{desktopValue}</td>
                    </tr>
                    <tr>
                      <td>Total</td>
                      <td className='ps-2'>{mobileValue + desktopValue}</td>
                    </tr>
                    <tr>
                    </tr>
                  </tbody>
                </table>
              </span>
            </div>
            <Col className="col-auto">
              <div style={{ width: '60', height: '60', fontSize: 24 }} className="icon icon-shape text-white rounded-circle shadow">
                <i className="fas fa-cookie-bite" />
              </div>
            </Col>
          </Row>
        </CardBody>
      </Card>
    )
  }
}

export default withStyles(Styles)(ConsentSummary)
