import classNames from 'classnames'
import React, { Component } from 'react'
import ReportStore from 'stores/report-store'
import SiteCard from './site-card'
import Store from 'stores/store'
import styles from './styles'
import { observer } from 'mobx-react'
import { withStyles, WithStyles } from '@material-ui/core'

interface Props extends WithStyles<typeof styles> {
  className?: string
}
interface state {
  isTotalSelected: boolean,
}
@observer
class ContextSelector extends Component<Props, state> {
  constructor(props: Props) {
    super(props)
    this.state = {
      isTotalSelected: false
    }
  }
  onSiteSelected(ctx: string) {
    ReportStore.setContext(ctx)
  }

  render() {
    const { classes, className, ...rest } = this.props

    const rootClassName = classNames(classes.root, className)

    return (
      <>
        {' '}
        <div {...rest} className={rootClassName}>
          <section className={classes.content}>
          <div style={{background: this.state.isTotalSelected === true ? '#7f818b':'#FFFFFF'}}>
            <SiteCard onSelected={ctx => {this.onSiteSelected(''); this.setState({isTotalSelected:true})}} />
          </div>
          
            {Store.tenant?.sites.map(s => (
              <div style={{background: ReportStore.context === s.id ? '#7f818b':'#FFFFFF'}}>
              <SiteCard key={s.id} site={s} onSelected={ctx => {this.onSiteSelected(s.id); this.setState({isTotalSelected:false})}} />
              </div>
            ))}
          </section>
        </div>
      </>
    )
  }
}

export default withStyles(styles)(ContextSelector)
