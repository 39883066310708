import _ from 'lodash'
import countries from 'assets/countries'
import React, { Component, Fragment } from 'react'
import styles from './styles'
import { GetText as $ } from 'stores/UITexts'
import { observable } from 'mobx'
import { observer } from 'mobx-react'
import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListItemSecondaryAction,
  Switch,
  Typography,
  withStyles,
  WithStyles,
} from '@material-ui/core'

interface Props extends WithStyles<typeof styles> {
  list: string[]
  isOpen: boolean
  onClose: () => void
}

@observer
class CountrySelector extends Component<Props> {
  @observable isSelectorOpen = false

  close() {
    this.isSelectorOpen = false
    this.props.onClose()
  }

  toggle(code: string) {
    const { list } = this.props
    if (~list.indexOf(code)) {
      _.pull(list, code)
      return
    }

    list.push(code)
  }

  render() {
    const { classes, list } = this.props

    return (
      <Fragment>
        <div
          className={classes.outlined}
          onClick={() => (this.isSelectorOpen = true)}
        >
          {list.length === 0 && (
            <Typography>{$('country_selector.title')}</Typography>
          )}
          {list.length > 0 &&
            list.map(c => (
              <div key={c} className={classes.country}>
                <span className={`flag-icon flag-icon-${c.toLowerCase()}`} />
                <Typography className={classes.countryName}>
                  {countries[c]}
                </Typography>
              </div>
            ))}
        </div>
        <Dialog
          disableBackdropClick
          disableEscapeKeyDown
          open={this.isSelectorOpen}
          onClose={() => {}}
          maxWidth="sm"
          fullWidth={true}
        >
          <DialogTitle>{$('country_selector.choose_countries')}</DialogTitle>
          <DialogContent>
            <List className={classes.root}>
              {Object.keys(countries)
                .sort()
                .map(k => (
                  <ListItem key={k}>
                    <ListItemIcon>
                      <span
                        className={`flag-icon flag-icon-${k.toLowerCase()}`}
                      />
                    </ListItemIcon>
                    <ListItemText
                      id="switch-list-label-wifi"
                      primary={countries[k]}
                    />
                    <ListItemSecondaryAction>
                      <Switch
                        edge="end"
                        checked={_.indexOf(list, k) > -1}
                        value={k}
                        onChange={() => this.toggle(k)}
                        inputProps={{
                          'aria-labelledby': 'switch-list-label-wifi',
                        }}
                      />
                    </ListItemSecondaryAction>
                  </ListItem>
                ))}
            </List>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => this.close()} color="primary">
            {$('country_selector.ok')}
            </Button>
          </DialogActions>
        </Dialog>
      </Fragment>
    )
  }
}

export default withStyles(styles)(CountrySelector)
