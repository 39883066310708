import classNames from 'classnames'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import React from 'react'
import { makeStyles, MenuProps, Select } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  root: {
    marginBottom: theme.spacing() * 3,
  },
}))

interface SelectItem {
  key: string
  value: string
}

interface Props {
  label: string
  items: SelectItem[]
  className?: string
  error?: boolean
  value?: string
  multiple?: boolean
  input?: any
  MenuProps?: Partial<MenuProps>
  renderValue?: (value: any) => React.ReactNode
  onChange: (item: SelectItem) => void
}

const OutlinedSelect: React.FC<Props> = props => {
  const { className, label, items, onChange, error, value, multiple, input, renderValue } = props

  const item = items.find(i => i.key === value)

  const [labelWidth, setLabelWidth] = React.useState(0)
  const [selectedItem, setSelectedItem] = React.useState<SelectItem>(item || items[0])

  const inputLabel = React.useRef<HTMLLabelElement>(null)
  React.useEffect(() => {
    setLabelWidth(inputLabel.current!.offsetWidth)
  }, [])

  const handleChange = (event: any) => {
    const item = items.find(i => i.key === event.target.value)!
    setSelectedItem(item)
    onChange(item)
  }

  const classes = useStyles()
  const rootClassName = classNames(classes.root, className)

  return (
    <FormControl error={error} variant="outlined" fullWidth className={rootClassName}>
      <InputLabel ref={inputLabel} id="select-outlined-label" htmlFor="items">
        {label}
      </InputLabel>
      <Select
        multiple={multiple}
        value={selectedItem.key}
        onChange={handleChange}
        labelWidth={labelWidth}
        input={input}
        renderValue={renderValue}
        inputProps={{
          name: 'items',
          id: 'items',
        }}
      >
        {items.map(i => (
          <MenuItem key={i.key} value={i.key}>
            {i.value}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

export default OutlinedSelect
