import { createStyles, Theme } from '@material-ui/core/styles'

import SignupBackground from './signup-bg.png'
import Seal from './seal.png'

export default (theme: Theme) =>
  createStyles({
    signupBackground: {
      height: '100%',
      backgroundImage: `url(${SignupBackground})`,
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      overflow: 'auto',
    },
    startUsing: {
      marginTop: '50%',
      marginLeft: '3em',
    },
    seal: {
      backgroundImage: `url(${Seal})`,
      backgroundRepeat: 'no-repeat',
      backgroundSize: '10em',
      backgroundPosition: 'left bottom',
    },
    appbar: {
      // make background gradient
      background: 'linear-gradient(45deg, #C0EEF2 30%, #537FE7 90%, #537FE7 10%)',
      borderBottom: '1px solid #537FE7',
    },
    headerContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    content: {
      paddingTop: theme.spacing() * 4,
      paddingBottom: theme.spacing() * 5,
      display: 'flex',
      justifyContent: 'space-between',
    },
    divider: {
      marginTop: theme.spacing() * 3,
      marginBottom: theme.spacing() * 3,
    },
    leftColumn: {
      width: '60%',
    },
    rightColumn: {
      width: '40%',
      paddingTop: 50,
      marginLeft: 100,
      marginRight: 75,
      display: 'flex',
      alignItem: 'center',
      flexDirection: 'column',
    },
    adText: {
      marginTop: 20,
    },
    adBulletText: {
      marginTop: 10,
    },
    title: {
      marginBottom: theme.spacing() * 2,
      color: '#537FE7',
    },
    formContainer: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    column: {
      width: '49%',
    },
    spaceBetweenColumn: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    expireDateSelector: {
      width: '49%',
    },
    field: {
      marginBottom: theme.spacing() * 3,
    },
  })
