import { createStyles, Theme } from '@material-ui/core/styles'

export default (theme: Theme) =>
  createStyles({
    root: {
      height: '100%',
    },
    portletContent: {
      overflow: 'scroll',
    },
    portletFooter: {
      paddingLeft: theme.spacing() * 3,
      paddingRight: theme.spacing() * 3,
      paddingTop: theme.spacing() * 2,
      paddingBottom: theme.spacing() * 2,
    },
    unknownCookie: {
      fontWeight: 'bold',
      fontSize: '12px',
    },
  })
