import DashboardLayout from 'layouts/dashboard'
import React, { Component } from 'react'
import ReportStore from 'stores/report-store'
import TimeRangeSelector from './components/timerange-selector'
import {
  ConsentGraph,
  ConsentSummary,
  ContextSelector,
  NoConsents,
  } from './components'
import { createStyles, Grid, Theme, withStyles, WithStyles } from '@material-ui/core'
import { GetText as $ } from 'stores/UITexts'
import { observer } from 'mobx-react'
const Styles = (theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing() * 4,
    },
    item: {
      height: '100%',
    },
  })

interface Props extends WithStyles<typeof Styles> {}

@observer
class Dashboard extends Component<Props> {
  componentDidMount() {
    ReportStore.refreshStats()
  }

  render() {
    const { classes } = this.props
    const performanceTotal = ReportStore.rawData.map(d => d.performance).reduce((a, b) => a + b, 0)
    const functionalTotal = ReportStore.rawData.map(d => d.functional).reduce((a, b) => a + b, 0)
    const marketingTotal = ReportStore.rawData.map(d => d.marketing).reduce((a, b) => a + b, 0)
    const mobilePerformanceTotal = ReportStore.rawData.map(d => d.mobilePerformance).reduce((a, b) => a + b, 0)
    const mobileMarketingTotalTotal = ReportStore.rawData.map(d => d.mobileMarketing).reduce((a, b) => a + b, 0)
    const mobileFunctionalTotalTotal = ReportStore.rawData.map(d => d.mobileFunctional).reduce((a, b) => a + b, 0)
    const mobileRejectedConsentsTotal = ReportStore.rawData.map(d => d.mobileRejected).reduce((a, b) => a + b, 0)
    const rejectedConsentsTotal = ReportStore.rawData.map(d => d.rejectedConsents).reduce((a, b) => a + b, 0)
   
    const desktopTotal = ReportStore.rawData.map(d => d.desktopTotal).reduce((a, b) => a + b, 0)
    const mobileTotal = ReportStore.rawData.map(d => d.mobileTotal).reduce((a, b) => a + b, 0)
    let randomData = Math.floor(Math.random() * 25) + 1; // 1 ve 10 arasında sayı üretir.

    return (
      <DashboardLayout title="Dashboard">
        <div className={classes.root}>
          <Grid container spacing={4}>
            <Grid item lg={12} md={12} xl={12} xs={12}>
              <TimeRangeSelector />
            </Grid>
            <Grid item lg={12} md={12} xl={12} xs={12}>
              <ContextSelector />
            </Grid>
            {ReportStore.rawData.length > 0 && (
              <React.Fragment>
                <Grid item lg={4} sm={6} xl={2} xs={12}>
                  <ConsentSummary color={'#0b66d9'} title={$('dashboard.performance_consents')} icon="performance" desktopValue={performanceTotal} mobileValue={mobilePerformanceTotal}   />
                </Grid>
                <Grid item lg={4} sm={6} xl={2} xs={12}>
                  <ConsentSummary color={'#FFB822'} title={$('dashboard.marketing_consents')} icon="advertising" desktopValue={marketingTotal} mobileValue={mobileMarketingTotalTotal}   />
                </Grid>
                <Grid item lg={4} sm={6} xl={2} xs={12}>
                  <ConsentSummary color={'#FF170F'} title={$('dashboard.functional_consents')} icon="functional" desktopValue={functionalTotal} mobileValue={mobileFunctionalTotalTotal}  />
                </Grid>
                <Grid item lg={4} sm={6} xl={2} xs={12}>
                  <ConsentSummary color={'#B3B3FF'} title={$('dashboard.total_consents')} icon="functional" desktopValue={desktopTotal} mobileValue={mobileTotal}    />
                </Grid>
                <Grid item lg={4} sm={6} xl={4} xs={12}>
                  <ConsentSummary color={'#3f3f3f'} title={$('dashboard.rejected_consents')} icon="functional"  desktopValue={rejectedConsentsTotal} mobileValue={mobileRejectedConsentsTotal} />
                </Grid>
                {/* <Grid item lg={3} sm={6} xl={3} xs={12}>
                  <TotalConsents className={classes.item} value={ReportStore.rawData.map(d => d.total).reduce((a, b) => a + b, 0)} />
                </Grid> */}
                <Grid item lg={12} md={12} xl={12} xs={12}>
                  <ConsentGraph
                    className={classes.item}
                    labels={ReportStore.rawData.map(d => `${d.month}.${d.day}`)}
                    performance={ReportStore.rawData.map(d => d.performance)}
                    functional={ReportStore.rawData.map(d => d.functional)}
                    marketing={ReportStore.rawData.map(d => d.marketing)}
                    rejected={ReportStore.rawData.map(d=>d.rejectedConsents)}
                    mobilePerformance={ReportStore.rawData.map(d => d.mobilePerformance)}
                    mobileMarketing={ReportStore.rawData.map(d => d.mobileMarketing)}
                    mobileFunctional={ReportStore.rawData.map(d => d.mobileFunctional)}
                    mobileRejected={ReportStore.rawData.map(d => d.mobileRejected)}
                  />
                </Grid>

                   

              </React.Fragment>
            )}
            {ReportStore.rawData.length === 0 && (
              <Grid item lg={12} md={12} xl={12} xs={12}>
                <NoConsents />
              </Grid>
            )}
          </Grid>
          {/* <Description/> */}
        </div>     
      </DashboardLayout>
    )
  }
}


export default withStyles(Styles)(Dashboard)
