import _ from 'lodash'
import languages from 'assets/languages'
import React, { Component } from 'react'
import validate from 'validate.js'
import { SettingsApplications } from '@material-ui/icons'
import { Chip, createStyles, Input, MenuItem, Select as Dropdown, TextField, Theme, withStyles, WithStyles } from '@material-ui/core'
import { CountrySelector, OutlinedFormControl, Portlet, PortletContent, PortletHeader, PortletLabel, Select } from 'components'
import { GetText as $ } from 'stores/UITexts'
import { observable } from 'mobx'
import { observer } from 'mobx-react'

const Styles = (theme: Theme) =>
  createStyles({
    root: {
      marginTop: theme.spacing() * 4,
      paddingLeft: 10,
      paddingRight: 10,
      borderWidth: 1,
      borderStyle: 'solid',
      borderColor: '#001e50',
      maxWidth: '100%',
      borderRadius: 18,
      backgroundColor: '#fff',
    },
    formContainer: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    column: {
      width: '49%',
    },
    field: {
      marginBottom: theme.spacing() * 3,
      width: '100%',
    },
    chips: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    chip: {
      margin: 2,
    },
  })

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
}

const validationSchema = {
  siteName: { presence: { allowEmpty: false } },
  siteUrl: { presence: { allowEmpty: false }, url: true },
}

interface Props extends WithStyles<typeof Styles> {
  site: any
}

@observer
class Info extends Component<Props> {
  @observable isCountrySelectorOpen = false

  @observable values: { [key: string]: any } = {
    siteName: '',
    siteUrl: '',
    cookiePolicyUrl: {},
  }

  @observable touched: { [key: string]: boolean } = {
    siteName: false,
    siteUrl: false,
    cookiePolicyUrl: false,
  }

  @observable errors: { [key: string]: string[] } = {
    siteName: [],
    siteUrl: [],
    cookiePolicyUrl: [],
  }

  @observable isValid = false
  @observable error = ''

  @observable cookiePolicyLanguage = 'en'

  validateForm = _.debounce(() => {
    const { site } = this.props

    const errors = validate(this.values, validationSchema)
    this.errors = errors || {}
    this.isValid = errors ? false : true

    if (this.isValid) {
      site.name = this.values.siteName
      site.url = this.values.siteUrl
      site.cookiePolicyUrl = this.values.cookiePolicyUrl
    }
  }, 300)

  handleFieldChange = (field: string, value: string) => {
    this.error = ''
    this.touched[field] = true
    this.values[field] = value
    this.validateForm()
  }

  checkError(field: string) {
    return this.touched[field] && this.errors[field] && this.errors[field].length > 0
  }

  handleSupportedLanguageChange(event: React.ChangeEvent<{ value: unknown }>) {
    const { site } = this.props

    const value = event.target.value as string[]
    if (value.length === 0) {
      site.supportedLanguages = ['en']
      site.preferredLanguage = 'en'
    } else {
      site.supportedLanguages = value
    }
    if (!site.supportedLanguages!.includes(site.preferredLanguage!)) {
      site.preferredLanguage = site.supportedLanguages![0]
    }
  }

  handleCookiePolicyURLFieldChange = (value: string) => {
    const { site } = this.props
    site.cookiePolicyUrl[this.cookiePolicyLanguage] = value
  }

  componentDidMount() {
    const { site } = this.props
    this.values.siteName = site.name
    this.values.siteUrl = site.urls[0]
    this.values.showCookiePolicyOnBanner = site.showCookiePolicyOnBanner
    this.values.cookiePolicyUrl = site.cookiePolicyUrl
  }

  render() {
    const { classes, site } = this.props

    if (!site) {
      return <></>
    }

    return (
      <Portlet className={classes.root}>
        <PortletHeader>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <SettingsApplications />
            <PortletLabel title={$('general_settings')} />
          </div>
        </PortletHeader>
        <PortletContent>
          <div className={classes.formContainer}>
            <div className={classes.column}>
              <TextField
                name="siteName"
                value={this.values.siteName}
                error={this.checkError('siteName')}
                label={$('site_name')}
                variant="standard"
                className={classes.field}
                fullWidth
                onChange={e => this.handleFieldChange('siteName', e.target.value)}
              />
              <TextField
                disabled
                name="siteUrl"
                value={this.values.siteUrl}
                error={this.checkError('siteUrl')}
                label={$('url')}
                variant="standard"
                fullWidth
                className={classes.field}
                onChange={e => this.handleFieldChange('siteUrl', e.target.value)}
              />
            </div>
            <div className={classes.column}>
              <OutlinedFormControl label={$('supported_languages')}>
                <Dropdown
                  style={{ marginTop: 5 }}
                  fullWidth
                  multiple
                  value={site.supportedLanguages}
                  onChange={e => this.handleSupportedLanguageChange(e)}
                  input={<Input id="select-multiple-chip" />}
                  renderValue={selected => (
                    <div className={classes.chips}>
                      {(selected as string[]).map(value => (
                        <Chip key={value} label={languages[value]} className={classes.chip} />
                      ))}
                    </div>
                  )}
                  MenuProps={MenuProps}
                >
                  {Object.keys(languages).map(l => (
                    <MenuItem key={l} value={l}>
                      {languages[l]}
                    </MenuItem>
                  ))}
                </Dropdown>
              </OutlinedFormControl>

              <Select
                label={$('preferred_language')}
                items={site.supportedLanguages?.map((l: string) => ({ key: l, value: languages[l] }))}
                value={site.preferredLanguage}
                onChange={e => (site.preferredLanguage = e.key)}
              />

              <CountrySelector
                list={site.blacklistedCountries}
                isOpen={this.isCountrySelectorOpen}
                onClose={() => (this.isCountrySelectorOpen = false)}
              />
            </div>
          </div>
        </PortletContent>
        <PortletContent>
          <div className={classes.formContainer}>
            <div className={classes.column}>
              <Select
                label={$('show_cookie_policy')}
                items={[
                  { key: '1', value: $('yes') },
                  { key: '0', value: $('no') },
                ]}
                value={site.showCookiePolicyOnBanner ? '1' : '0'}
                onChange={e => (site.showCookiePolicyOnBanner = e.key === '1' ? true : false)}
              />
            </div>

            <div className={classes.column}>
              <Select
                label={$('cookie_policy_language')}
                items={site.supportedLanguages?.map((l: string) => ({ key: l, value: languages[l] }))}
                value={this.cookiePolicyLanguage}
                onChange={e => (this.cookiePolicyLanguage = e.key)}
              />
              <TextField
                name="cookiePolicyUrl"
                value={this.values.cookiePolicyUrl[this.cookiePolicyLanguage] || ""}
                error={this.checkError('cookiePolicyUrl')}
                label={$('cookie_policy_url')}
                variant="standard"
                fullWidth
                required={true}
                InputLabelProps={{
                  shrink: this.values.cookiePolicyUrl[this.cookiePolicyLanguage] ? true : false,
                }}
                className={classes.field}
                onChange={e => this.handleCookiePolicyURLFieldChange(e.target.value)}
              />
            </div>
          </div>
        </PortletContent>
      </Portlet>
    )
  }
}

export default withStyles(Styles)(Info)
