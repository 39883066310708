import classNames from 'classnames'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import React from 'react'
import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  root: {
    marginBottom: theme.spacing() * 3,
  },
}))

interface Props {
  label: string
  className?: string
  error?: boolean
  children: React.ReactNode
}

const OutlinedFormControl: React.FC<Props> = props => {
  const { className, label, error, children } = props

  // eslint-disable-next-line
  const [labelWidth, setLabelWidth] = React.useState(0)

  const inputLabel = React.useRef<HTMLLabelElement>(null)
  React.useEffect(() => {
    setLabelWidth(inputLabel.current!.offsetWidth)
  }, [])

  const classes = useStyles()
  const rootClassName = classNames(classes.root, className)

  return (
    <FormControl error={error} variant="outlined" fullWidth className={rootClassName}>
      <InputLabel ref={inputLabel} id="demo-simple-select-outlined-label" htmlFor="outlined-Name">
        {label}
      </InputLabel>
      {children}
    </FormControl>
  )
}

export default OutlinedFormControl
