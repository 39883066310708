import _ from 'lodash'
import ErrorIcon from '@material-ui/icons/Error'
import React, { Component } from 'react'
import { action } from 'mobx'
import { createStyles, MenuItem, Select, Theme } from '@material-ui/core'
import { GetText as $ } from 'stores/UITexts'
import { observer } from 'mobx-react'
import { observable } from 'mobx'
import { Portlet, PortletContent, PortletHeader, PortletLabel } from 'components'
import { withStyles, WithStyles } from '@material-ui/core'
import languages from 'assets/languages'
import { DataGrid, GridColDef, GridEditRowsModel, GridCellParams, GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid'
import './DataGrid.css'

const styles = (theme: Theme) =>
  createStyles({
    root: {
      marginTop: theme.spacing() * 4,
      borderWidth: 1,
      borderStyle: 'solid',
      borderColor: '#dcdcdc',
      maxWidth: '100%',
      borderRadius: 18,
      backgroundColor: '#fff',
    },
    portletFooter: {
      paddingLeft: theme.spacing() * 3,
      paddingRight: theme.spacing() * 3,
      paddingTop: theme.spacing() * 2,
      paddingBottom: theme.spacing() * 2,
    },
    unknownCookie: {
      fontWeight: 'bold',
      fontSize: '12px',
    },
    type: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
    },
    icon: {
      display: 'flex',
      width: 40,
      alignItems: 'center',
      justifyContent: 'center',
    },
  })

interface Props extends WithStyles<typeof styles> {
  className?: string
  site: any
}

interface state {
  editRowsModel: any
}
@observer
class Cookies extends Component<Props, state> {
  @observable selectedCookieDefExpLanguage!: string

  constructor(props: Props) {
    super(props)
    const { site } = this.props
    this.selectedCookieDefExpLanguage = site.preferredLanguage
    this.state = {
      editRowsModel: {},
    }
  }

  renderSelect(cookie: any) {
    const { classes } = this.props

    return (
      <div className={classes.type}>
        <Select
          className="cookieType"
          variant="outlined"
          error={cookie.type === 'Unknown' ? true : false}
          value={cookie.type}
          disabled={cookie.type === 'Unknown' ? false : true}
          onChange={e => this.updateCookieGroup(cookie, e.target.value as string)}
        >
          {cookie.type === 'Unknown' && <MenuItem value={'Unknown'}>{$('cookie_list.unknown')}</MenuItem>}
          <MenuItem value={'NecessaryCookies'}>{$('cookie_list.necessary_cookies')}</MenuItem>
          <MenuItem value={'PerformanceCookies'}>{$('cookie_list.performance_cookies')}</MenuItem>
          <MenuItem value={'MarketingCookies'}>{$('cookie_list.marketing_cookies')}</MenuItem>
          <MenuItem value={'FunctionalCookies'}>{$('cookie_list.functional_cookies')}</MenuItem>
        </Select>
        <div className={classes.icon}>{cookie.type === 'Unknown' ? <ErrorIcon color="error" /> : null}</div>
      </div>
    )
  }

  @action updateCookieGroup(cookie: any, categoryId: string) {
    const { site } = this.props

    if (cookie.type === 'Unknown') {
      const unknownGroup = site.cookieGroups.find((g: any) => g.id === 'Unknown')
      let targetGroup = site.cookieGroups!.find((g: any) => g.id === categoryId)
      if (!_.isArray(targetGroup.cookies)) {
        targetGroup.cookies = []
      }
      _.remove(unknownGroup!.cookies, (c: any) => c.id === cookie.id)
      cookie.type = categoryId
      targetGroup!.cookies.push(cookie)
    } else {
      cookie.type = categoryId
    }
  }

  @action async updateCookie(cookie: any) {
    const { site } = this.props
    let selectedCookie = site.cookieGroups.find((g: any) => g.id === cookie.row.type).cookies.find((c: any) => c.id === cookie.id)

    selectedCookie[cookie.field] = {
      ...selectedCookie[cookie.field],
      [this.selectedCookieDefExpLanguage]: this.state.editRowsModel[selectedCookie.id][cookie.field].value,
    }
  }

  render() {
    const { classes, site } = this.props
    const cookies = []
    let csvLang = this.selectedCookieDefExpLanguage

    cookies.push(
      ..._(site.cookieGroups)
        .flatMap(r => _.map(r.cookies, c => c))
        .sortBy('name')
        .value()
    )

    const columns: GridColDef[] = [
      {
        field: 'name',
        headerName: $('cookie_list.cookie_name'),
        width: 200,
      },
      {
        field: 'domainPath',
        headerName: $('cookie_list.domain_path'),
        width: 200,
      },
      {
        field: 'definition',
        headerName: $('cookie_list.definition_head'),
        width: 800,
        editable: true,
        valueGetter: params => {
          return `${params.row.definition?.[this.selectedCookieDefExpLanguage] ?? $('cookie_list.definition_info')}`
        },
      },
      {
        field: 'expire',
        headerName: $('cookie_list.expire_head'),
        width: 125,
        editable: true,
        valueGetter: params => {
          return `${params.row.expire?.[this.selectedCookieDefExpLanguage] ?? $('cookie_list.expire_info')}`
        },
      },
      {
        field: 'type',
        headerName: $('cookie_list.category'),
        sortable: true,
        width: 220,
        renderCell: cellValues => {
          return this.renderSelect(cellValues.row)
        },
      },
    ]

    const handleEditRowsModelChange = (model: GridEditRowsModel) => {
      this.setState({ editRowsModel: model })
    }

    const handleEditRowDone = (params: GridCellParams, event: any) => {
      this.updateCookie(params)
    }

    function CustomToolbar() {
      return (
        <GridToolbarContainer>
         <GridToolbarExport placeholder={"Export"}  csvOptions={{ utf8WithBom: true, fileName: `${site.name}_${csvLang}` }} />
        </GridToolbarContainer>
      )
    }

    return (
      <Portlet className={classes.root}>
        <PortletHeader>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <svg
              className="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium MuiBox-root css-uqopch"
              focusable="false"
              aria-hidden="true"
              viewBox="0 0 24 24"
              data-testid="CookieIcon"
            >
              <path d="M21.95 10.99c-1.79-.03-3.7-1.95-2.68-4.22-2.98 1-5.77-1.59-5.19-4.56C6.95.71 2 6.58 2 12c0 5.52 4.48 10 10 10 5.89 0 10.54-5.08 9.95-11.01zM8.5 15c-.83 0-1.5-.67-1.5-1.5S7.67 12 8.5 12s1.5.67 1.5 1.5S9.33 15 8.5 15zm2-5C9.67 10 9 9.33 9 8.5S9.67 7 10.5 7s1.5.67 1.5 1.5-.67 1.5-1.5 1.5zm4.5 6c-.55 0-1-.45-1-1s.45-1 1-1 1 .45 1 1-.45 1-1 1z"></path>
            </svg>
            <PortletLabel title={$('cookie_lists')} />
          </div>
          <div className={classes.type}>
            <Select
              style={{ width: 100 }}
              value={this.selectedCookieDefExpLanguage}
              onChange={e => (this.selectedCookieDefExpLanguage = e.target.value as string)}
              inputProps={{
                name: 'preferredLanguage',
              }}
            >
              {site.supportedLanguages!.map((l: string) => (
                <MenuItem key={l} value={l}>
                  {languages[l]}
                </MenuItem>
              ))}
            </Select>
          </div>
        </PortletHeader>
        <div className="PortletContent-content">
          <PortletContent>
            <div style={{ width: '100%' }}>
              <DataGrid
                autoHeight={true}
                rows={cookies}
                columns={columns}
                disableSelectionOnClick
                editRowsModel={this.state.editRowsModel}
                onEditRowsModelChange={handleEditRowsModelChange}
                onCellEditStop={handleEditRowDone}
                components={{
                  Toolbar: CustomToolbar,
                }}
              />
            </div>
          </PortletContent>
        </div>
      </Portlet>
    )
  }
}

export default withStyles(styles)(Cookies)
