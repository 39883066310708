import classNames from 'classnames'
import React, { Component } from 'react'
import Styles from './styles'
import ReportStore from 'stores/report-store'

import { Typography, withStyles, WithStyles } from '@material-ui/core'

interface Props extends WithStyles<typeof Styles> {
  className?: string
  site?: any
  onSelected: (ctx: string) => void
}

class SiteCard extends Component<Props> {
  render() {
    const { classes, className, site, onSelected } = this.props

    const rootClassName = classNames(classes.root, className)

    return (
      <div className={rootClassName} onClick={() => onSelected(site ? site.id : '')}>
        <div className={classes.imageWrapper}>
          <img alt="Site" className={classes.image} src={site ? '/images/domain.png' : '/images/sum.png'} />
        </div>
        <div className={classes.details}>
          <Typography className={classes.title} variant="h6">
            {site ? site.name : 'TOTALS'}
          </Typography>
        </div>
      </div>
    )
  }
}

export default withStyles(Styles)(SiteCard)
