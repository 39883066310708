import _ from 'lodash'
import Address from 'stores/address'
import PaymentPlanStore, { PaymentPeriod } from 'stores/payment-plan-store'
import React, { Component } from 'react'
import Schema, { Rules } from 'async-validator'
import Store from 'stores/store'
import Styles from './styles'
import { AgreementViewer } from 'components/agreement-viewer'
import { GetText as $ } from 'stores/UITexts'
import { observable } from 'mobx'
import { observer } from 'mobx-react'
import { Country, PaymentPlan } from 'stores/models'
import { Select } from 'components'
import 'utils/validators'
import {
  AppBar,
  Button,
  Container,
  Divider,
  TextField,
  Typography,
  useScrollTrigger,
  WithStyles,
  withStyles,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  FormControlLabel,
  Checkbox,
  Link,
} from '@material-ui/core'
import TagManager from 'react-gtm-module'

const tagManagerArgs = {
  gtmId: 'GTM-NLMW54W',
}

const locale = navigator.language.startsWith('tr') ? 'tr' : 'en'

interface Props extends WithStyles<typeof Styles> {
  children: React.ReactElement
  history: any
}

function ElevationScroll(props: Props) {
  const { children } = props
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
  })

  return React.cloneElement(children, {
    elevation: trigger ? 4 : 0,
  })
}

@observer
class SignUp extends Component<Props> {
  // Get parameters from URL
  params = new URLSearchParams(this.props.history.location.search)
  reference = this.params.get('ref')

  @observable paymentPeriod = PaymentPeriod.Monthly
  @observable packageLocale: keyof typeof PaymentPlanStore.defaultPrices = 'tr'
  @observable price = 0

  @observable countries: { [key: string]: string } = {}
  @observable states: { [key: string]: string } = {}

  @observable values: { [key: string]: string | number | boolean } = {
    siteName: '',
    siteUrl: '',
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    password: '',
    passwordCheck: '',
    companyName: '',
    taxOffice: '',
    taxNumber: '',
    country: '225',
    city: '2170',
    address: '',
    zipCode: '',
    isToSAccepted: false,
    isETKAccepted: false,
    isCRMAccepted: false,
  }

  @observable touched: { [key: string]: boolean } = {
    siteName: false,
    siteUrl: false,
    firstName: false,
    lastName: false,
    email: false,
    phone: false,
    password: false,
    passwordCheck: false,
    companyName: false,
    taxOffice: false,
    taxNumber: false,
    country: true,
    city: true,
    address: false,
    zipCode: false,
    isToSAccepted: false,
    isETKAccepted: false,
    isCRMAccepted: false,
  }

  @observable errors: { [key: string]: string[] } = {
    siteName: [],
    siteUrl: [],
    firstName: [],
    lastName: [],
    email: [],
    phone: [],
    password: [],
    passwordCheck: [],
    companyName: [],
    taxOffice: [],
    taxNumber: [],
    country: [],
    city: [],
    address: [],
    zipCode: [],
    isToSAccepted: [],
    isETKAccepted: [],
    isCRMAccepted: [],
  }

  @observable isValid = false
  @observable error = ''
  @observable transactionInProgress = false
  @observable purchaseSuccessful = false
  @observable dialog = {
    open: false,
    title: '',
    content: '',
  }

  @observable agreementViewer = {
    title: 'Kullanım Sözleşmesi',
    agreementName: '01_en.html',
    open: false,
    data: {},
  }

  validationDescriptor: Rules = {
    siteName: { type: 'string', required: true },
    siteUrl: [
      { type: 'url', required: true },
      {
        asyncValidator: (rule, value) => {
          return new Promise(async (resolve, reject) => {
            const exists = await Store.checkSiteUrl(value)
            if (exists) {
              return reject($('site_address_exists'))
            }
            resolve()
          })
        },
      },
    ],
    firstName: { type: 'string', required: true },
    lastName: { type: 'string', required: true },
    email: [
      { type: 'email', required: true },
      {
        asyncValidator: (rule, value) => {
          return new Promise(async (resolve, reject) => {
            const exists = await Store.checkEmail(value)
            if (exists) {
              return reject($('email_address_in_use'))
            }
            resolve()
          })
        },
      },
    ],
    phone: [
      { type: 'string', required: true },
      {
        validator: (rule, value) => /^\+[1-9]{1}[0-9]{3,14}$/.test(value),
        message: $('invalid_phone_number'),
      },
    ],
    password: [
      { type: 'string', required: true },
      {
        validator: (rule, value) => {
          return /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/.test(value)
        },
      },
    ],
    passwordCheck: [
      { type: 'string', required: true },
      {
        validator: (rule, value) =>
          !_.isEmpty(this.values.password) && !_.isEmpty(this.values.passwordCheck) && this.values.password === this.values.passwordCheck,
        message: $('passwords_do_not_match'),
      },
    ],
    companyName: { type: 'string', required: true },
    taxOffice: { type: 'string', required: true },
    taxNumber: { type: 'string', required: true },
    country: { type: 'string', required: true },
    city: { type: 'string', required: true },
    address: { type: 'string', required: true },
    zipCode: { type: 'string', required: true, len: 5 },
    // cardHolderName: { type: 'string', required: false },
    // cardNumber: { type: 'string', required: false },
    // expireMonth: { type: 'number', required: false },
    // expireYear: { type: 'number', required: false },
    // cvc: { type: 'number', required: false },
    isToSAccepted: { type: 'boolean', required: true },
    isETKAccepted: { type: 'boolean', required: false },
    isCRMAccepted: { type: 'boolean', required: false },
  }

  validator = new Schema(this.validationDescriptor)

  validateForm = _.debounce(() => {
    this.validator.validate(this.values, {}, (errors, fields) => {
      this.errors = {}
      if (fields) {
        Object.keys(fields).forEach(f => {
          this.errors[f] = fields[f].map(e => e.message)
        })
      }
      this.isValid = errors === null
    })
  }, 300)

  handleFieldChange = (field: string, value: any) => {
    this.error = ''
    this.touched[field] = true
    this.values[field] = value
    this.validateForm()
  }
  signUpWithOutPaymet = async () => {
    const params = Object.assign(
      {
        locale: locale,
      },
      {
        reference: this.reference ? this.reference : 'no-ref',
      },
      this.values
    )
    delete params.passwordCheck

    const result = await Store.signUpWithOutPayment(params)
    if (!result.success) {
      this.dialog = {
        open: true,
        title: $('error_processing_transaction'),
        content: result.message,
      }
      this.purchaseSuccessful = false
    } else {
      this.dialog = {
        open: true,
        title: $('success'),
        content: $('purchase_successful._click_ok_to_continue_to_sign_in_page.'),
      }
      this.purchaseSuccessful = false
    }
    this.transactionInProgress = false
  }

  checkError(field: string) {
    return this.touched[field] && this.errors[field] && this.errors[field].length > 0
  }

  getError(field: string, label: string) {
    return this.checkError(field) ? this.errors[field][0] : label
  }

  async componentDidMount() {
    PaymentPlanStore.load().then(() => {
      const selectedPeriod = _.padStart((this.paymentPeriod === PaymentPeriod.Monthly ? 1 : 12) + '', 2, '0')
      //const currencyCode = locale === 'tr' ? 'TRY' : 'EUR'
      const currencyCode = 'TRY'
      const paymentPlan: PaymentPlan = PaymentPlanStore.items.find(i => i.name === `${currencyCode}${selectedPeriod}_2024`)!
      this.price = paymentPlan.price
    })
    await Address.loadCountries().then(() => {
      Address.userCountry = Address.countries.find(c => c._id.toString() === '225')
      Address.loadingCountries = false
      if (Address.userCountry) {
        Address.loadStates(Address.userCountry?._id.toString()).then(() => {
          Address.userState = Address.states.find(c => c._id.toString() === '2170')
          Address.loadingStates = false
        })
      }
    })

    TagManager.initialize(tagManagerArgs)
  }

  /* updateStates(countryId: string) {
    console.log('update states', countryId)
    Address.loadStates(countryId).then(() => {
      // clear states
      this.states = {}
      Address.states.forEach(s => {
        this.states[s._id] = s.name
      })
      console.log('states', this.states)
      Address.loadingStates = false
    })
  }

  handleCountryChange(value: string, keyID: string) {
    console.log('country changed', value)
    this.values.country = keyID
    const key = Object.keys(this.countries).find(k => this.countries[k] === value)
    console.log('key', key)
    this.updateStates(key!)
  } */

  paymentOptionChanged = (period: PaymentPeriod) => {
    this.paymentPeriod = period
  }

  async register() {
    this.transactionInProgress = true

    const period = _.padStart((this.paymentPeriod === PaymentPeriod.Monthly ? 1 : 12) + '', 2, '0')
    // const currencyCode = locale === 'tr' ? 'TRY' : 'EUR'
    const currencyCode = 'TRY'
    const paymentPlan: PaymentPlan = PaymentPlanStore.items.find(i => i.name === `${currencyCode}${period}_2024`)!

    const params = Object.assign(
      {
        scriptPaymentPlanRefCode: paymentPlan.referenceCode,
        locale: locale,
      },
      this.values
    )
    delete params.passwordCheck

    const result = await Store.signUp(params)
    if (!result.success) {
      this.dialog = {
        open: true,
        title: $('error_processing_transaction'),
        content: result.message,
      }
      this.purchaseSuccessful = false
    } else {
      this.dialog = {
        open: true,
        title: $('success'),
        content: $('purchase_successful._click_ok_to_continue_to_sign_in_page.'),
      }
      this.purchaseSuccessful = true
    }
    this.transactionInProgress = false
  }

  closeDialog() {
    const { history } = this.props
    if (!this.purchaseSuccessful) {
      history.push('/signin')
    }
    this.dialog = {
      open: false,
      title: '',
      content: '',
    }
  }

  renderRightCol() {
    const { classes } = this.props

    return locale === 'en' ? (
      <React.Fragment>
        <Typography variant="h4" className={classes.title}>
          Get Started Free!
        </Typography>
        <Typography variant="h5" className={classes.adText}>
          Use all the features of Cookie Seal for 14 days free of charge.
        </Typography>
        <Typography variant="h5" className={classes.adText}>
          Make your website compatible with Data Protection Laws with easy setup.
        </Typography>
        <Typography variant="h5" className={classes.adText}>
          With Cookie Seal:
        </Typography>
        <Typography variant="h5" className={classes.adBulletText}>
          º Classify the cookies on your website by scanning them
        </Typography>
        <Typography variant="h5" className={classes.adBulletText}>
          º Customize the content of the Cookie Preference Screen as you like
        </Typography>
        <Typography variant="h5" className={classes.adBulletText}>
          º Access detailed consent reports via the dashboard
        </Typography>
        <div style={{ marginTop: '10%' }}>
          <div className="row justify-content-center">
            <div className="col-lg-10">
              <div className="home-img login-img text-center d-none d-lg-inline-block">
                <div className="animation-2"></div>
                <div className="animation-3"></div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    ) : (
      <React.Fragment>
        <div className={classes.startUsing}>
          <Typography variant="h4" className={classes.title}>
            Cookie Seal’ı Şimdi Kullanmaya Başlayın!
          </Typography>
          <Typography variant="h5" className={classes.adText}>
            Kolay kurulum ile web sitenizi Veri Koruma Kanunlarına Uyumlu hale getirin. Cookie Seal’ın tüm özelliklerini 14 gün boyunca ücretsiz
            kullanın.
          </Typography>
          <Typography variant="h5" className={classes.adText}>
            Sol taraftaki ilgili alanları doldurarak Cookie Seal’a erişim sağlayabilirsiniz. Bu bilgiler faturalama sürecinde kullanılacağı için doğru
            girilmelidir.
          </Typography>
          <Typography variant="h5" className={classes.adText}>
            Kayıt işlemini tamamladıktan sonra Cookie Seal Yönetim Panel’ine giriş yapıp, kurulum işlemlerine başlayabilirsiniz
          </Typography>
          <Typography variant="h5" className={classes.adText}>
            Cookie Seal ile:
          </Typography>
          <Typography variant="h5" className={classes.adBulletText}>
            º Web sitenizdeki çerezleri tarayarak, sınıflandırabilirsiniz.
          </Typography>
          <Typography variant="h5" className={classes.adBulletText}>
            º Çerez tercih ekranını özelleştirebilirsiniz.
          </Typography>
          <Typography variant="h5" className={classes.adBulletText}>
            º Yönetim paneli üzerinden çerezlerle ilgili detaylı raporlara erişebilirsiniz
          </Typography>
          <div style={{ marginTop: '10%' }}>
            <div className="row justify-content-center">
              <div className="col-lg-10">
                <div className="home-img login-img text-center d-none d-lg-inline-block">
                  <div className="animation-2"></div>
                  <div className="animation-3"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    )
  }

  showAgreement(title: string, name: string) {
    const data = {
      customerName: this.values.firstName + ' ' + this.values.lastName,
      address: `${this.values.address} ${this.values.city} / ${this.values.country}`,
      email: this.values.email,
      dateSigned: new Date().toLocaleDateString(),
      productName: 'Cookie Seal Test Plan',
      period: '14 Gün',
      price: 0,
      priceWithoutTax: 0,
      taxRate: 20,
      discount: this.price,
      totalPrice: 0,
      phone: this.values.phone,
    }
    this.agreementViewer = { title: title, agreementName: name, open: true, data: data }
  }

  render() {
    const { classes } = this.props

    const label = (
      /* locale === 'tr' ? */ <span>
        {$('signup.accept')}: &nbsp;
        <Link
          component="button"
          style={{ fontSize: 14, paddingBottom: 3 }}
          onClick={() => this.showAgreement('ÖN BİLGİLENDİRME FORMU', 'on_bilgilendirme')}
        >
          Ön Bilgilendirme Formu
        </Link>
        &nbsp;&&nbsp;
        <Link
          component="button"
          style={{ fontSize: 14, paddingBottom: 3 }}
          onClick={() => this.showAgreement('Mesafeli Kullanım ve Üyelik Sözleşmesi', 'mesafeli_satis')}
        >
          Mesafeli Kullanım ve Üyelik Sözleşmesi
        </Link>
      </span>
    ) /* : (
        <span>
          I have read and agree to the&nbsp;
          <Link component="button" style={{ fontSize: 14, paddingBottom: 3 }} onClick={() => this.showAgreement('ÖN BİLGİLENDİRME FORMU', '01_en')}>
            Terms and Conditions
          </Link>
          &nbsp;and&nbsp;
          <Link
            component="button"
            style={{ fontSize: 14, paddingBottom: 3 }}
            onClick={() => this.showAgreement('Mesafeli Kullanım ve Üyelik Sözleşmesi', '02_en')}
          >
            Sales Agreement
          </Link>
        </span>
      ) */

    const ETKLabel =
      locale === 'tr' ? (
        <span>Tarafıma ticari elektronik ileti gönderilmesine izin veriyorum. &nbsp;</span>
      ) : (
        <span>I agree to receive commercial electronic messages. &nbsp;</span>
      )

    const CRMLabel =
      locale === 'tr' ? (
        <span>KVKK kapsamında kişi verilerimin işlenmesine izin veriyorum. &nbsp;</span>
      ) : (
        <span>I agree to the processing of my personal data within the scope of the KVKK. &nbsp;</span>
      )

    return (
      <React.Fragment>
        <div className={classes.signupBackground}>
          <div className={classes.seal}>
            <ElevationScroll {...this.props}>
              <AppBar className={classes.appbar}>
                <Container maxWidth="lg" className={classes.headerContainer}>
                  <img alt="logo" src="/images/logo.png" />
                  <div>
                    <Button variant="outlined" color="primary" href="https://www.cookieseal.com">
                      {$('return_to_site')}
                    </Button>
                  </div>
                </Container>
              </AppBar>
            </ElevationScroll>
            {/* <Toolbar />
        <Section />
        <Brands />
        <StepperComponent />
        <Team /> */}
            {/* <Footer /> */}
            <Container className={classes.content}>
              <div className={classes.leftColumn}>
                {/* <Typography variant="h3" className={classes.title}>
              {$('choose_your_package')}
            </Typography>
            {!PaymentPlanStore.loading && (
              <PackageSelector
                prices={PaymentPlanStore.defaultPrices[this.packageLocale]}
                activeIndex={this.paymentPeriod === 'MONTHLY' ? 0 : 1}
                onChanged={period => this.paymentOptionChanged(period)}
              />
            )} */}

                <Divider className={classes.divider} />
                <Typography variant="h3" className={classes.title}>
                  {$('site_information')}
                </Typography>

                {/* <div className={classes.column}>
              <TextField
                name="siteFrom"
                error={this.checkError('siteOwner')}
                // label={this.getError('siteOwner', $('site_owner'))}
                variant="outlined"
                disabled
                value={'TSoft'}
                fullWidth
                className={classes.field}
                onChange={e => this.handleFieldChange('siteOwner', e.target.value)}
              />
            </div> */}
                <div className={classes.formContainer}>
                  <div className={classes.column}>
                    <TextField
                      name="siteName"
                      error={this.checkError('siteName')}
                      label={this.getError('siteName', $('site_name'))}
                      variant="outlined"
                      fullWidth
                      className={classes.field}
                      onChange={e => this.handleFieldChange('siteName', e.target.value)}
                    />
                  </div>
                  <div className={classes.column}>
                    <TextField
                      name="siteUrl"
                      error={this.checkError('siteUrl')}
                      label={this.getError('siteUrl', $('site_url'))}
                      variant="outlined"
                      fullWidth
                      className={classes.field}
                      onChange={e => this.handleFieldChange('siteUrl', e.target.value)}
                    />
                  </div>
                </div>

                <Divider className={classes.divider} />
                <Typography variant="h3" className={classes.title}>
                  {$('user_information')}
                </Typography>
                <div className={classes.formContainer}>
                  <div className={classes.column}>
                    <TextField
                      name="firstName"
                      error={this.checkError('firstName')}
                      label={this.getError('firstName', $('first_name'))}
                      variant="outlined"
                      fullWidth
                      className={classes.field}
                      onChange={e => this.handleFieldChange('firstName', e.target.value)}
                    />
                  </div>
                  <div className={classes.column}>
                    <TextField
                      name="lastName"
                      error={this.checkError('lastName')}
                      label={this.getError('lastName', $('last_name'))}
                      variant="outlined"
                      fullWidth
                      className={classes.field}
                      onChange={e => this.handleFieldChange('lastName', e.target.value)}
                    />
                  </div>
                </div>

                <Divider className={classes.divider} />
                <Typography variant="h3" className={classes.title}>
                  {$('account_information')}
                </Typography>
                <div className={classes.formContainer}>
                  <div className={classes.column}>
                    <TextField
                      name="email"
                      error={this.checkError('email')}
                      label={this.getError('email', $('email'))}
                      variant="outlined"
                      fullWidth
                      className={classes.field}
                      onChange={e => this.handleFieldChange('email', e.target.value)}
                    />
                    <TextField
                      name="phone"
                      error={this.checkError('phone')}
                      label={this.getError('phone', $('phone'))}
                      variant="outlined"
                      fullWidth
                      className={classes.field}
                      onChange={e => this.handleFieldChange('phone', e.target.value)}
                    />
                  </div>
                  <div className={classes.column}>
                    <TextField
                      name="password"
                      error={this.checkError('password')}
                      label={this.getError('password', $('password'))}
                      type="password"
                      variant="outlined"
                      fullWidth
                      className={classes.field}
                      onChange={e => this.handleFieldChange('password', e.target.value)}
                    />
                    <TextField
                      name="passwordCheck"
                      error={this.checkError('passwordCheck')}
                      label={this.getError('passwordCheck', $('re-type_password'))}
                      type="password"
                      variant="outlined"
                      fullWidth
                      className={classes.field}
                      onChange={e => this.handleFieldChange('passwordCheck', e.target.value)}
                    />
                  </div>
                </div>

                <Divider className={classes.divider} />
                <Typography variant="h3" className={classes.title}>
                  {$('company_information')}
                </Typography>
                <div className={classes.formContainer}>
                  <div className={classes.column}>
                    <TextField
                      name="companyName"
                      error={this.checkError('companyName')}
                      label={this.getError('companyName', $('company_name'))}
                      variant="outlined"
                      fullWidth
                      className={classes.field}
                      onChange={e => this.handleFieldChange('companyName', e.target.value)}
                    />
                    <TextField
                      name="taxOffice"
                      error={this.checkError('taxOffice')}
                      label={this.getError('taxOffice', $('tax_office'))}
                      variant="outlined"
                      fullWidth
                      className={classes.field}
                      onChange={e => this.handleFieldChange('taxOffice', e.target.value)}
                    />
                    <TextField
                      name="taxNumber"
                      error={this.checkError('taxNumber')}
                      label={this.getError('taxNumber', $('tax_number'))}
                      variant="outlined"
                      fullWidth
                      className={classes.field}
                      onChange={e => this.handleFieldChange('taxNumber', e.target.value)}
                    />
                  </div>
                  <div className={classes.column}>
                    {!Address.loadingCountries && (
                      <Select
                        label={this.getError('country', $('country'))}
                        value={Address?.userCountry?._id.toString()}
                        items={Address.countries?.map(c => ({ key: c._id.toString(), value: c.native })) || [{ key: '', value: '' }]}
                        onChange={({ value, key }) => {
                          Address.handleUserCountry(key)
                          this.values.country = key
                        }}
                      />
                    )}
                    {!Address.loadingStates && (
                      <Select
                        label={this.getError('city', $('city'))}
                        value={Address.userState?._id.toString()}
                        items={Address.states?.map(c => ({ key: c._id.toString(), value: c.name })) || [{ key: '', value: '' }]}
                        onChange={({ value, key }) => {
                          Address.changeUserState(key)
                          this.values.city = key
                        }}
                      />
                    )}
                    <TextField
                      name="address"
                      error={this.checkError('address')}
                      label={this.getError('address', $('street_address'))}
                      variant="outlined"
                      fullWidth
                      className={classes.field}
                      onChange={e => this.handleFieldChange('address', e.target.value)}
                    />
                    <TextField
                      name="zipCode"
                      error={this.checkError('zipCode')}
                      label={this.getError('zipCode', $('zip_code'))}
                      variant="outlined"
                      fullWidth
                      className={classes.field}
                      onChange={e => this.handleFieldChange('zipCode', e.target.value)}
                    />
                  </div>
                </div>
                {/* <Typography variant="h3" className={classes.title}>
              {$('credit_card_information')}
            </Typography>
            <div className={classes.formContainer}>
              <div className={classes.column}>
                <TextField
                  name="cardHolderName"
                  error={this.checkError('cardHolderName')}
                  label={this.getError('cardHolderName', $('signup.name_on_card'))}
                  variant="outlined"
                  fullWidth
                  className={classes.field}
                  onChange={e => this.handleFieldChange('cardHolderName', e.target.value)}
                />
                <TextField
                  name="cardNumber"
                  error={this.checkError('cardNumber')}
                  label={this.getError('cardNumber', $('card_number'))}
                  variant="outlined"
                  fullWidth
                  className={classes.field}
                  onChange={e => this.handleFieldChange('cardNumber', e.target.value)}
                />
              </div>
              <div className={classes.column}>
                <div className={classes.spaceBetweenColumn}>
                  <Select
                    label={this.getError('expireMonth', $('expire_month'))}
                    error={this.checkError('expireMonth')}
                    items={months}
                    className={classes.expireDateSelector}
                    onChange={({ value }) => (this.values.expireMonth = Number(value))}
                  />
                  <Select
                    label={this.getError('expireYear', $('expire_year'))}
                    error={this.checkError('expireYear')}
                    items={years}
                    className={classes.expireDateSelector}
                    onChange={({ value }) => (this.values.expireYear = Number(value))}
                  />
                </div>
                <div className={classes.expireDateSelector}>
                  <TextField
                    name="cvc"
                    error={this.checkError('cvc')}
                    label={this.getError('cvc', 'CVC')}
                    variant="outlined"
                    className={classes.field}
                    onChange={e => this.handleFieldChange('cvc', Number(e.target.value))}
                  />
                </div>
              </div>
            </div> */}
                <div className={classes.formContainer}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={this.values.isToSAccepted as boolean}
                        onChange={() => {
                          this.values.isToSAccepted = !this.values.isToSAccepted
                        }}
                      />
                    }
                    label={label}
                  />
                </div>
                <div className={classes.formContainer}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={this.values.isETKAccepted as boolean}
                        onChange={() => {
                          this.values.isETKAccepted = !this.values.isETKAccepted
                        }}
                      />
                    }
                    label={ETKLabel}
                  />
                </div>
                <div className={classes.formContainer}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={this.values.isCRMAccepted as boolean}
                        onChange={() => {
                          this.values.isCRMAccepted = !this.values.isCRMAccepted
                        }}
                      />
                    }
                    label={CRMLabel}
                  />
                </div>
                <Button
                  disabled={!this.isValid || this.transactionInProgress || !this.values.isToSAccepted}
                  variant="contained"
                  color="primary"
                  size="large"
                  fullWidth
                  onClick={() => this.signUpWithOutPaymet()}
                >
                  {$('register')}
                </Button>
              </div>
              <div className={classes.rightColumn}>{this.renderRightCol()}</div>
            </Container>
            <AgreementViewer
              title={this.agreementViewer.title}
              agreementName={this.agreementViewer.agreementName}
              isOpen={this.agreementViewer.open}
              data={this.agreementViewer.data}
              onClose={() => (this.agreementViewer.open = false)}
            />
            <Dialog open={this.dialog.open} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
              <DialogTitle id="alert-dialog-title">{this.dialog.title}</DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">{this.dialog.content}</DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={() => this.closeDialog()} color="primary">
                  {$('ok')}
                </Button>
              </DialogActions>
            </Dialog>
          </div>
        </div>
      </React.Fragment>
    )
  }
}

export default withStyles(Styles)(SignUp)
