import React, { Component } from 'react'
import { ThemeProvider } from '@material-ui/styles'
import Routes from './Routes'
import theme from './theme'
import { isMobile } from 'react-device-detect'
import { GetText as $ } from 'stores/UITexts'

export default class App extends Component {
  render() {
    if (isMobile) {
      return (

        <div>
          <h1>{$('no_mobile')}</h1>
          
        </div>
      )
    } else {

      return (
        <ThemeProvider theme={theme}>
          {/* <Router> */}
          <Routes />
          {/* </Router> */}
        </ThemeProvider>
      )
    }
  }
}
