import _ from 'underscore'
import PaymentPlanStore from 'stores/payment-plan-store'
import React, { Component } from 'react'
import schema from './schema'
import Store from 'stores/store'
import styles from './styles'
import validate from 'validate.js'
import { GetText as $ } from 'stores/UITexts'
import { observable } from 'mobx'
import { observer } from 'mobx-react'
import { withStyles, WithStyles } from '@material-ui/core'
import { Col, Container, Form, FormGroup, Row, Button, Input, Label } from 'reactstrap'
import { Link } from 'react-router-dom'
// import logodark from '/images/logo.png'


import logodark from '../../assets/images/logo.png'

import './sigin.css'
import { isMobile } from 'react-device-detect'

interface Props extends WithStyles<typeof styles> {
  className: string
  history: any
}

@observer
class SignIn extends Component<Props> {
  @observable values: { [key: string]: string } = { email: '', password: '' }
  @observable touched: { [key: string]: any } = {
    email: null,
    password: null,
  }
  @observable errors: { [key: string]: any } = {
    email: null,
    password: null,
  }

  @observable isValid = false
  @observable error = ''

  validateForm = _.debounce(() => {
    const errors = validate(this.values, schema)
    this.errors = errors || {}
    this.isValid = errors ? false : true
  }, 300)

  triggerText = 'Şifremi Unuttum'
  onSubmit = (event: any) => {
    event.preventDefault(event)
    //TODO: servise baglan şifremi unuttum

    console.log(event.target.name.value)
    console.log(event.target.email.value)
  }

  handleFieldChange = (field: string, value: string) => {
    this.error = ''
    this.touched[field] = true
    this.values[field] = value
    this.validateForm()
  }

  handleSignIn = async (e: any) => {
    const { history } = this.props
    try {
      await Store.signIn({
        email: this.values.email,
        password: this.values.password,
      })
      PaymentPlanStore.load()
      history.push('/dashboard')
    } catch {
      this.error = $('error_signing_in._please_try_again.')
    }
  }

  checkMobile() {
    const { history } = this.props
    if (isMobile) {
      history.push('/no-mobile')
    }
  }

  render() {
    if (isMobile) {
      return <div>
        <h1>{$('no_mobile')}</h1>
      </div>
    }

    const { classes } = this.props

    return (
      <React.Fragment>
        <div className="account-home-btn d-none d-sm-block">
          <Link to="/" className="text-primary">
            <i className="mdi mdi-home h1"></i>
          </Link>
        </div>
        <section className={classes.signinBackground}>
          <div className="display-table">
            <div className="display-table-cell">
              <Container>
                <Row>
                  <Col lg={12}>
                    <div className="login-box">
                      <Row className="align-items-center no-gutters">
                        <Col lg={6}>
                          <div className={classes.image}>
                            <div className="row justify-content-center">
                              <div className="col-lg-10">
                                <div className="home-img login-img text-center d-none d-lg-inline-block">
                                  <div className="animation-2"></div>
                                  <div className="animation-3"></div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </Col>
                        <Col lg={6}>
                          <Row className="justify-content-center align-items-center text-center">
                            <div className="text-center">
                              <Link to="#">
                                <img src={logodark} alt="" height="100" />
                              </Link>
                              {/* <p className="text-muted mt-3">Cookie Seal Yönetim Paneline Giriş Yap</p> */}
                            </div>
                          </Row>
                          <Row className="justify-content-center">
                            <Col lg={11}>
                              <div className="p-4">
                                <div className="p-3 custom-form">
                                  <Form>
                                    <FormGroup>
                                      <Label for="email">Email</Label>
                                      <Input
                                        type="text"
                                        className="form-control"
                                        id="email"
                                        placeholder="E mail"
                                        onChange={e => this.handleFieldChange('email', e.target.value)}
                                      />
                                    </FormGroup>
                                    <FormGroup>
                                      <Label for="userpassword">{$('signin.password')}</Label>
                                      <Input
                                        type="password"
                                        className="form-control"
                                        id="userpassword"
                                        placeholder="Enter password"
                                        onChange={event => this.handleFieldChange('password', event.target.value)}
                                      />
                                    </FormGroup>
                                    <div className="custom-control custom-checkbox">
                                      <Input type="checkbox" className="custom-control-input" id="customControlInline" />
                                      <Label className="custom-control-label" for="customControlInline">
                                        &nbsp;{$('signin.remember_me')}
                                      </Label>
                                    </div>
                                    <div className="mt-3">
                                      <span style={{ color: 'red', fontSize: 14 }}>{this.error}</span>
                                    </div>
                                    <div className="mt-3">
                                      <Button color="primary" className="btn btn-primary btn-block" block onClick={event => this.handleSignIn(event)}>
                                        {$('signin.button')}
                                      </Button>{' '}
                                    </div>
                                  </Form>
                                  {/* <div className="mt-4 pt-1 mb-0 text-center">
                                        <ModalContainer triggerText={this.triggerText} onSubmit={this.onSubmit} />
                                    </div> */}
                                </div>
                              </div>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </div>
                  </Col>
                </Row>
              </Container>
            </div>
          </div>
        </section>
      </React.Fragment>
    )
  }
}

export default withStyles(styles)(SignIn)
