import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import PrintIcon from '@material-ui/icons/Print'
import React, { useRef, useState, useEffect } from 'react'

interface Props {
  title: string
  agreementName: string
  isOpen: boolean
  data?: { [key: string]: string }
  onClose?: () => void
}

export const AgreementViewer: React.FC<Props> = ({ title, agreementName, isOpen, data, onClose }) => {
  const [agreement, setAgreement] = useState<string>('')

  const loadAgreement = async () => {
    const res = await fetch(`./agreements/${agreementName}.html`)

    let text = await res.text()
    Object.keys(data as any).forEach(key => {
      text = text.replace(new RegExp(`__${key}__`, 'g'), data![key])
    })

    setAgreement(text)
  }

  useEffect(() => {
    loadAgreement()
    return () => {}
  }, [agreementName, isOpen])

  const frame = useRef<HTMLIFrameElement>(null)

  const print = () => {
    const printableElements = document.getElementById('printableAgreement')!.innerHTML
    const html = '<html><head><title></title></head><body>' + printableElements + '</body></html>'
    if (!frame) return
    const contentWindow = frame.current!.contentWindow!
    contentWindow.document.write(html)
    contentWindow.print()
  }

  return (
    <div>
      <iframe ref={frame} style={{ display: 'none' }} title="frame"></iframe>
      <Dialog
        open={isOpen}
        onClose={onClose}
        fullWidth
        maxWidth={'md'}
        scroll="paper"
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle id="scroll-dialog-title">{title}</DialogTitle>
        <DialogContent dividers={true} style={{ maxHeight: '50%', fontFamily: 'Quicksand' }}>
          <div id="printableAgreement" dangerouslySetInnerHTML={{ __html: agreement }}>
            {/* <ReactMarkdown source={agreement} /> */}
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="primary">
            Done
          </Button>
          <Button onClick={print} color="secondary" startIcon={<PrintIcon />}>
            Print
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}
