import { createStyles } from '@material-ui/core'

export default (theme: any) =>
  createStyles({
    root: {borderRadius:24},
    dropdownButton: {
      marginRight: -theme.spacing() * 2,
    },
    chartWrapper: {
      height: '400px',
      position: 'relative',
    },
    portletFooter: {
      display: 'flex',
      justifyContent: 'flex-end',
      backgroundColor:'#001e50'
    },
  })
