import _ from 'lodash'

export interface UITextItem {
  name?: string
  key: string
  group?: string
  description?: string
  defaultValues: { [key: string]: string }
}

const DefaultUITexts: UITextItem[] = [
  {
    name: 'Banner Body Content',
    key: 'bannerBody',
    group: 'BannerText',
    description: 'Sitede çıkan Cookie Banner textini buradan guncelleyebilirsiniz',
    defaultValues: {
      tr:
        'Çerezler, içeriği ve reklamları kişiselleştirmek, sosyal medya özellikleri sağlamak ve trafiğimizi analiz etmek için kullanılmaktadır. “Kabul Et” seçeneği ile tüm çerezleri kabul edebilirsiniz veya “Çerez Ayarları” seçeneği ile ayarları düzenleyebilirsiniz.',
      en:
        'Cookies are used to personalize content and ads, provide social media features, and analyze our traffic. You can accept all cookies with the “Accept” option or you can edit the settings with the "Cookie Settings" option.',
    },
  },
  {
    name: 'Customize Settings Button Title',
    key: 'customizeSettings',
    group: 'BannerText',
    description: 'Sitede çıkan Cookie Banner textini buradan guncelleyebilirsiniz',
    defaultValues: { tr: 'Ayarları Düzenle', en: 'Customize Settings' },
  },
  {
    name: 'Accept All Cookies Button Title',
    key: 'acceptAllCookies',
    group: 'BannerText',
    description: 'Sitede çıkan Cookie Banner textini buradan guncelleyebilirsiniz',
    defaultValues: { tr: 'Kabul Et', en: 'Accept' },
  },
  {
    name: 'Reject All Cookies Button Title',
    key: 'rejectAllCookies',
    group: 'BannerText',
    description: 'Sitede çıkan Cookie Banner textini buradan guncelleyebilirsiniz',
    defaultValues: { tr: 'Hepsini Reddet', en: 'Reject All' },
  },
  {
    name: 'Cookie Settings Button Title',
    key: 'cookieSettings',
    group: 'BannerText',
    description: 'Sitede çıkan Cookie Banner textini buradan guncelleyebilirsiniz',
    defaultValues: { tr: 'Çerez Ayarları', en: 'Cookie Settings' },
  },
  {
    name: 'Cookie List Title',
    key: 'cookies',
    group: 'BannerText',
    description: 'Sitede çıkan Cookie Banner textini buradan guncelleyebilirsiniz',
    defaultValues: { tr: 'Çerezler', en: 'Cookies' },
  },
  {
    name: 'Allow All Button Title',
    key: 'allowAll',
    group: 'BannerText',
    description: 'Sitede çıkan Cookie Banner textini buradan guncelleyebilirsiniz',
    defaultValues: { tr: 'Hepsine İzin Ver', en: 'Accept All' },
  },
  {
    name: 'Save Setting Button Title',
    key: 'saveSettings',
    group: 'BannerText',
    description: 'Sitede çıkan Cookie Banner textini buradan guncelleyebilirsiniz',
    defaultValues: { tr: 'Ayarları Kaydet', en: 'Save Settings' },
  },
  {
    name: 'Constent ID Label',
    key: 'constentIdLabel',
    group: 'IDText',
    description: 'Consent Id',
    defaultValues: { tr: 'Cookie tercihinizi belirttiğinizde ID atanır', en: 'ID seems when u choose category' },
  },
  {
    name: 'Active State Label',
    key: 'active',
    group: 'BannerText',
    description: 'Sitede çıkan Cookie Banner textini buradan guncelleyebilirsiniz',
    defaultValues: { tr: 'Aktif', en: 'Active' },
  },
  {
    name: 'Inactive State Label',
    key: 'inactive',
    group: 'BannerText',
    description: 'Sitede çıkan Cookie Banner textini buradan guncelleyebilirsiniz',
    defaultValues: { tr: 'İnaktif', en: 'Inactive' },
  },
  {
    name: 'Always Active Label',
    key: 'alwaysActive',
    group: 'BannerText',
    description: 'Sitede çıkan Cookie Banner textini buradan guncelleyebilirsiniz',
    defaultValues: { tr: 'Her Zaman Aktif', en: 'Always Active' },
  },
  {
    name: 'Cookie Policy Label',
    key: 'cookiePolicy',
    group: 'BannerText',
    description: 'Sitede çıkan Cookie Banner textini buradan guncelleyebilirsiniz',
    defaultValues: { tr: 'Çerez Politikası', en: 'Cookie Policy' },
  },
  {
    name: 'Necessary Cookies Title',
    key: 'NecessaryCookies.name',
    group: 'CookieGroups',
    description: 'Sitede çıkan Cookie Banner textini buradan guncelleyebilirsiniz',
    defaultValues: { tr: 'Zorunlu Çerezler', en: 'Necessary Cookies' },
  },
  {
    name: 'Necessary Cookies Definition',
    key: 'NecessaryCookies.definition',
    group: 'CookieGroups',
    description: 'Sitede çıkan Cookie Banner textini buradan guncelleyebilirsiniz',
    defaultValues: {
      tr:
        'Bu çerezler, web sitesinin çalışması için gereklidir ve sistemlerimizde kapatılamaz. Web sitesinde dolaşmanıza ve gizlilik tercihlerinizi belirleme, giriş yapma veya formları doldurma gibi hizmet talebine karşılık gelen eylemlere gibi özelliklerini kullanmanıza olanak sağlamaktadır.',
      en:
        'These cookies are required for the operation of the website and cannot be turned off. They allow you to browse the website and use features such as actions that correspond to the service request, such as setting your privacy preferences, logging in, or filling out forms.',
    },
  },
  {
    name: 'Performance Cookies Title',
    key: 'PerformanceCookies.name',
    group: 'CookieGroups',
    description: 'Sitede çıkan Cookie Banner textini buradan guncelleyebilirsiniz',
    defaultValues: { tr: 'Performans Çerezleri', en: 'Performance Cookies' },
  },
  {
    name: 'Performance Cookies Definition',
    key: 'PerformanceCookies.definition',
    group: 'CookieGroups',
    description: 'Sitede çıkan Cookie Banner textini buradan guncelleyebilirsiniz',
    defaultValues: {
      tr:
        'Bu çerezler, web sitesinin çalışma şeklini geliştirmek için kullanılmaktadır. Ziyaretçiyi tanımlayan bilgileri toplamaz. Ziyaretçilerin web sitesini nasıl kullandıklarına  (Örneğin web sayfalarında hata mesajı alıp almadıkları) ilişkin bilgileri içermektedir.',
      en:
        'These cookies are used to improve the way the website works. They do not collect information that identifies the visitor. They contain information about how visitors use the website (for example, whether they receive an error message on their web pages).',
    },
  },
  {
    name: 'Marketing Cookies Title',
    key: 'MarketingCookies.name',
    group: 'CookieGroups',
    description: 'Sitede çıkan Cookie Banner textini buradan guncelleyebilirsiniz',
    defaultValues: { tr: 'Reklam veya Hedefleme Çerezleri', en: 'Marketing/Targeting Cookies' },
  },
  {
    name: 'Marketing Cookies Definition',
    key: 'MarketingCookies.definition',
    group: 'CookieGroups',
    description: 'Sitede çıkan Cookie Banner textini buradan guncelleyebilirsiniz',
    defaultValues: {
      tr:
        'Bu çerezler, sizin profilinizi oluşturmak ve ilgi alanlarınızla alakalı reklamlar sunmak için kullanılırlar. Aynı zamanda, reklam kampanyasının etkinliğini ölçmeye yardımcı olmasının yanı sıra, bir reklamı görme sayısını sınırlamak için de kullanılmaktadır.  Genellikle web sitesi sahibinin izniyle reklam ağlarına yerleştirilir. Web sitesini ziyaret ettiğiniz ve çerezde yer alan bilgiler reklam verenler diğer kuruluşlarla paylaşılmaktadır. Bu çerezlere izin vermezseniz, farklı web sitelerinde hedefli reklamlarımızı deneyimlemezsiniz.',
      en:
        "These cookies are used to create your profile and serve ads relevant to your interests. They are also used to limit the number of times you see an ad, as well as helping to measure the effectiveness of the ad campaign. The information stoed in these cookie are shared with advertisers and other organizations. If you don't allow these cookies, you won't experience targeted ads on different websites.",
    },
  },
  {
    name: 'Functional Cookies Title',
    key: 'FunctionalCookies.name',
    group: 'CookieGroups',
    description: 'Sitede çıkan Cookie Banner textini buradan guncelleyebilirsiniz',
    defaultValues: { tr: 'Fonksiyonel Çerezler', en: 'Functional Cookies' },
  },
  {
    name: 'Functional Cookies Definition',
    key: 'FunctionalCookies.definition',
    group: 'CookieGroups',
    description: 'Sitede çıkan Cookie Banner textini buradan guncelleyebilirsiniz',
    defaultValues: {
      tr:
        'Bu çerezler web sitesinin yaptığınız seçimleri (kullanıcı adınız, diliniz veya bulunduğunuz bölge gibi) hatırlamasını ve gelişmiş, daha fazla kişisel özellikler sağlamasını sağlar. Ayrıca metin boyutunda, yazı tiplerinde ve web sayfalarının diğer bölümlerinde özelleştirebileceğiniz değişiklikleri hatırlamak için de kullanılabilir. Bu çerezlerin topladığı bilgiler gizli tutulabilir ve diğer web sitelerindeki göz atma etkinliğinizi izleyemezler. Bu çerezlere izin vermezseniz, bu işlevlerin bazıları veya tümü düzgün çalışmayabilir.',
      en:
        'These cookies allow the website to remember your preferences (such as your user name, language, or region) and provide enhanced, more personal features. They can also be used to remember changes in text size, fonts, and other parts of web pages that you can customize. The information collected by these cookies may be kept confidential and will not be able to track your browsing activity on other websites. If you do not allow these cookies, some or all of these functions may not work properly.',
    },
  },
  { key: 'sidebar.dashboard', defaultValues: { tr: 'Analiz', en: 'Analysis' } },
  { key: 'sidebar.websites', defaultValues: { tr: 'Web Siteleri', en: 'Websites' } },
  { key: 'sidebar.account', defaultValues: { tr: 'Hesap', en: 'Account' } },
  { key: 'sidebar.entegrated', defaultValues: { tr: 'Entegrasyon', en: 'Integration' } },
  { key: 'sidebar.support', defaultValues: { tr: 'Destek', en: 'Support' } },
  { key: 'sidebar.customer_support', defaultValues: { tr: 'Müşteri Destek', en: 'Customer Support' } },
  { key: 'sidebar.log_out', defaultValues: { tr: 'Çıkış Yap', en: 'Log Out' } },
  { key: 'dashboard.consent_graph', defaultValues: { tr: 'Onay Grafiği', en: 'Constent Graph' } },
  { key: 'dashboard.graph_performance', defaultValues: { tr: 'Performans', en: 'Performance' } },
  { key: 'dashboard.graph_advertisement', defaultValues: { tr: 'Reklam', en: 'Advertisement' } },
  { key: 'dashboard.graph_functional', defaultValues: { tr: 'Fonksiyonel', en: 'Functional' } },
  { key: 'dashboard.graph_rejectedConsents', defaultValues: { tr: 'Reddedilen', en: 'Rejected' } },
  { key: 'dashboard.consent_pie', defaultValues: { tr: 'Rıza Verisi Diyagramı', en: 'Constent Pie' } },
  { key: 'dashboard.performance', defaultValues: { tr: 'Performans', en: 'Performance' } },
  { key: 'dashboard.functional', defaultValues: { tr: 'Fonksiyonel', en: 'Functional' } },
  { key: 'dashboard.marketing', defaultValues: { tr: 'Reklam veya Hedefleme', en: 'Marketing' } },
  { key: 'dashboard.total_consents', defaultValues: { tr: 'TOPLAM İZİNLER', en: 'TOTAL CONSENTS' } },
  { key: 'dashboard.rejected_consents', defaultValues: { tr: 'Reddedilen İzinler', en: 'Rejected Consents' } },
  { key: 'dashboard.performance_consents', defaultValues: { tr: 'Performans Çerezleri İzinleri', en: 'Performance Consents' } },
  { key: 'dashboard.marketing_consents', defaultValues: { tr: 'Reklam veya Hedefleme Çerezleri İzinleri', en: 'Marketing Consents' } },
  { key: 'dashboard.functional_consents', defaultValues: { tr: 'Fonksiyonel Çerez İzinleri', en: 'Functional Consents' } },
  { key: 'edit.side_id', defaultValues: { tr: 'Web Site ID', en: 'Site ID' } },
  { key: 'edit.site_name', defaultValues: { tr: 'Website Adı', en: 'Website Name' } },
  { key: 'edit.site_url', defaultValues: { tr: 'Site Adresi (https://...)', en: 'Site URL (https://...)' } },
  { key: 'edit.preferred_language', defaultValues: { tr: 'Tercih Edilen Dil', en: 'Preferred Language' } },
  { key: 'edit.supported_languages', defaultValues: { tr: 'Desteklenen Diller', en: 'Supported Languages' } },
  { key: 'language', defaultValues: { tr: 'Dil', en: 'Language' } },
  { key: 'edit.blacklisted_countries', defaultValues: { tr: 'Kara Liste Ülkeleri', en: 'Blacklisted Countries' } },
  { key: 'cookie_list.unknown', defaultValues: { tr: 'Bilinmeyen Çerezler', en: 'Unknown' } },
  { key: 'cookie_list.necessary_cookies', defaultValues: { tr: 'Zorunlu Çerezler', en: 'Necessary Cookies' } },
  { key: 'cookie_list.performance_cookies', defaultValues: { tr: 'Performans Çerezleri', en: 'Performance Cookies' } },
  { key: 'cookie_list.marketing_cookies', defaultValues: { tr: 'Reklam veya Hedefleme Çerezleri', en: 'Marketing Cookies' } },
  { key: 'cookie_list.functional_cookies', defaultValues: { tr: 'Fonksiyonel Çerezler', en: 'Functional Cookies' } },
  { key: 'cookie_list.cookie_name', defaultValues: { tr: 'Çerez Adı', en: 'Cookie Name' } },
  { key: 'cookie_list.domain_path', defaultValues: { tr: 'Site Adresi', en: 'Domain Path' } },
  { key: 'cookie_list.category', defaultValues: { tr: 'Kategoriler', en: 'Category' } },
  {
    key: 'analysis.description',
    defaultValues: {
      tr: 'Her bir web sitenizdeki istatistiklerinizi aşağıdaki alandan tarih bazlı olarak inceleyebilirsiniz.',
      en: 'You can review your statistics on each website on a date-based basis from the field below.',
    },
  },
  {
    key: 'integration.description',
    defaultValues: {
      tr: '“Cookie Seal’ın web sitelerinizdeki durumlarını görmek için aşağıdaki alandan filtreleme işlemi yapabilirsiniz.',
      en: 'You can filter the status of Cookie Seal your websites from the field below.',
    },
  },
  { key: 'crawl', defaultValues: { tr: 'Çerezleri tara', en: 'Crawl cookies' } },
  { key: 'status', defaultValues: { tr: 'Site Durumu', en: 'Website Status' } },
  { key: 'select', defaultValues: { tr: 'Seç', en: 'Select' } },
  { key: 'edit', defaultValues: { tr: 'Düzenle', en: 'Edit' } },
  {
    key: 'country_selector.title',
    defaultValues: { tr: 'Ülke eklemek veya kaldırmak için tıklayınız', en: 'Click to add or remove countries' },
  },
  { key: 'country_selector.choose_countries', defaultValues: { tr: 'Ülke Seç', en: 'Choose Countries' } },
  { key: 'country_selector.ok', defaultValues: { tr: 'Tamam', en: 'Ok' } },
  { key: 'language_editor.language', defaultValues: { tr: 'Dil', en: 'Language' } },
  { key: 'edit_site.title', defaultValues: { tr: 'Site Güncelle', en: 'Update Site' } },
  { key: 'edit_site.dialog_title', defaultValues: { tr: 'Site Kaydedildi', en: 'Site Saved' } },
  { key: 'edit_site.dialog_button', defaultValues: { tr: 'Bitti', en: 'Done' } },
  { key: 'edit_site.unknown_categories', defaultValues: { tr: 'Bilinmeyen Çerez Kategorisi', en: 'Unknown cookie categories' } },
  { key: 'signin.title', defaultValues: { tr: 'Oturum Aç', en: 'Sign In' } },
  { key: 'signin.email', defaultValues: { tr: 'E-mail', en: 'E-mail' } },
  { key: 'signin.password', defaultValues: { tr: 'Parola', en: 'Password' } },
  { key: 'signin.button', defaultValues: { tr: 'Oturum Aç', en: 'Sign In' } },
  { key: 'signin.remember_me', defaultValues: { tr: 'Beni Hatırla', en: 'Remember Me' } },
  {
    key: 'no_mobile',
    defaultValues: { tr: 'Üzgünüz, bu sayfayı mobil cihazlarda görüntüleyemezsiniz', en: 'Sorry, you can not view this page on mobile devices' },
  },
  { key: 'signup.card_information', defaultValues: { tr: 'Kredi Kart Bilgisi', en: 'Card Information' } },
  { key: 'signup.name_on_card', defaultValues: { tr: 'Kart Sahibinin İsmi', en: "Card Holder's Name" } },
  { key: 'signup.card_number', defaultValues: { tr: 'Kart Numarası', en: 'Card Number' } },
  { key: 'signup.expire_date', defaultValues: { tr: 'Son Kullanım Tarihi', en: 'Expire Date' } },
  { key: 'signup.ccv2', defaultValues: { tr: 'CCV2 Numarası', en: 'CCV2' } },
  { key: 'signup.city', defaultValues: { tr: 'Şehir', en: 'City' } },
  { key: 'signup.zip_code', defaultValues: { tr: 'Posta Kodu', en: 'Zip Code' } },
  { key: 'signup.address', defaultValues: { tr: 'Adres', en: 'Address' } },
  { key: 'signup.product_name', defaultValues: { tr: 'Çerez Rıza Kodları', en: 'Cookie Consent Script' } },
  { key: 'signup.web_site_name', defaultValues: { tr: 'Web Sitesi', en: 'Web Site' } },
  { key: 'signup.subscription_period', defaultValues: { tr: 'Yıllık Abonelik', en: 'Yearly Subscription' } },
  { key: 'signup.support', defaultValues: { tr: 'Destek', en: 'Support' } },
  { key: 'signup.total', defaultValues: { tr: 'Toplam', en: 'Total' } },
  { key: 'signup.site_information', defaultValues: { tr: 'Site Bilgisi', en: 'Site Information' } },
  { key: 'signup.site_name', defaultValues: { tr: 'Site Adı', en: 'Site Name' } },
  { key: 'signup.site_url', defaultValues: { tr: 'Site Adresi (https://...)', en: 'Website URL (https://...)' } },
  { key: 'signup.subscription_period_title', defaultValues: { tr: 'Abonelik Periyodu', en: 'Subscription Period' } },
  { key: 'signup.subscription_period_monthly', defaultValues: { tr: 'Aylık Abonelik', en: 'Monthly Subscription' } },
  { key: 'signup.subscription_period_yearly', defaultValues: { tr: 'Yıllık Abonelik', en: 'Yearly Subscription' } },
  { key: 'signup.tenant_information', defaultValues: { tr: 'Kullanıcı Bilgileri', en: 'Tenant Information' } },
  { key: 'signup.site_information', defaultValues: { tr: 'Site Bilgisi', en: 'Site Information' } },
  { key: 'signup.payment', defaultValues: { tr: 'Ödeme', en: 'Payment' } },
  { key: 'signup.success', defaultValues: { tr: 'Başarılı', en: 'Success' } },
  { key: 'signup.contact_information', defaultValues: { tr: 'İletişim Bilgileri', en: 'Contact Information' } },
  { key: 'signup.first_name', defaultValues: { tr: 'İsim', en: 'First Name' } },
  { key: 'signup.last_name', defaultValues: { tr: 'Soy İsim', en: 'Last Name' } },
  { key: 'signup.company_name', defaultValues: { tr: 'Şirket Adı', en: 'Company name' } },
  { key: 'signup.email', defaultValues: { tr: 'E-mail Adresi', en: 'Email Address' } },
  { key: 'signup.password', defaultValues: { tr: 'Parola', en: 'Password' } },
  { key: 'signup.accept', defaultValues: { tr: 'Okudum ve onaylıyorum', en: 'I have read and accept' } },
  { key: 'validation.is_required', defaultValues: { tr: 'zorunludur', en: 'is required' } },
  { key: 'change_password', defaultValues: { tr: 'Parola güncelleme', en: 'Change Password' } },
  { key: 'old_password', defaultValues: { tr: 'Eski Parola', en: 'Old Password' } },
  { key: 'new_password', defaultValues: { tr: 'Yeni Parola', en: 'New Password' } },
  { key: 'retype_new_password', defaultValues: { tr: 'Yeni Parola (Tekrar)', en: 'Re-type New Password' } },
  { key: 'password_changed_message', defaultValues: { tr: 'Parola güncellendi', en: 'Password changed' } },
  { key: 'password_change_error_message', defaultValues: { tr: 'Parola güncellendi', en: 'Password changed' } },
  { key: 'tenant_information_updated', defaultValues: { tr: 'Firma bilgisi güncellendi', en: 'Tenant information updated' } },
  { key: 'error_updating_tenant', defaultValues: { tr: 'Firma bilgisi güncellenemedi', en: 'Error updating tenant' } },
  { key: 'general_settings', defaultValues: { tr: 'Genel Ayarlar', en: 'General Settings' } },
  { key: 'company_name', defaultValues: { tr: 'Firma Unvanı', en: 'Company Name' } },
  { key: 'tax_office', defaultValues: { tr: 'Vergi Dairesi', en: 'Tax Office' } },
  { key: 'tax_number', defaultValues: { tr: 'Vergi Numarası/T.C Kimlik Numarası', en: 'Tax Number/T.C. ID Numer' } },
  { key: 'contact_first_name', defaultValues: { tr: 'İlgili Kişi Adı', en: 'Contact First Name' } },
  { key: 'contact_last_name', defaultValues: { tr: 'İlgili Kişi Soyadı', en: 'Contact Last Name' } },
  { key: 'contact_email', defaultValues: { tr: 'İlgili Kişi E-posta', en: 'Contact E-mail' } },
  { key: 'contact_phone', defaultValues: { tr: 'İlgili Kişi Telefon (+905554443322)', en: 'Contact Phone (+905554443322)' } },
  { key: 'country', defaultValues: { tr: 'Ülke', en: 'Country' } },
  { key: 'city', defaultValues: { tr: 'Şehir', en: 'City' } },
  { key: 'street_address', defaultValues: { tr: 'Adres', en: 'Street Address' } },
  { key: 'zip_code', defaultValues: { tr: 'Posta Kodu', en: 'Zip Code' } },
  { key: 'update_company_information', defaultValues: { tr: 'Firma Bilgilerimi Güncelle', en: 'Update My Company Information' } },
  { key: 'site_suspended', defaultValues: { tr: 'Site Durduruldu', en: 'Site suspended' } },
  { key: 'site_cannot_be_suspended', defaultValues: { tr: 'Site Durdurulamadı', en: 'Site cannot be suspended' } },
  { key: 'subscriptions', defaultValues: { tr: 'Üyelikler', en: 'Subscriptions' } },
  { key: 'suspend_site', defaultValues: { tr: 'Duraklat', en: 'Suspend' } },
  { key: 'suspended', defaultValues: { tr: 'DURDURULDU', en: 'SUSPENDED' } },
  { key: 'stop', defaultValues: { tr: 'Dur', en: 'Stop' } },
  {
    key: 'suspend_cookieseal_services_for',
    defaultValues: { tr: 'Şu site için CookieSeal hizmetleri durdurulsun mu: ', en: 'Suspend CookieSeal services for ' },
  },
  { key: 'cancel', defaultValues: { tr: 'İptal', en: 'Cancel' } },
  { key: 'suspend', defaultValues: { tr: 'Duraklat', en: 'Suspend' } },
  { key: 'account_settings', defaultValues: { tr: 'Hesap Ayarlarım', en: 'Account Settings' } },
  { key: 'general', defaultValues: { tr: 'Genel', en: 'General' } },
  {
    key: 'general_description',
    defaultValues: {
      tr: 'Buradan siteniz ile alakalı genel ayarları kontrol edebilirsiniz',
      en: 'You can control general settings of your site here',
    },
  },
  { key: 'subscriptions', defaultValues: { tr: 'Üyelikler', en: 'Subscriptions' } },
  {
    key: 'subscriptions_description',
    defaultValues: {
      tr: 'Sistemde üyeliğiniz ile alakalı websiteleri görüntüleyebilirsiniz',
      en: 'You can view websites related to your subscription here',
    },
  },
  { key: 'security', defaultValues: { tr: 'Güvenlik', en: 'Security' } },
  { key: 'security_description', defaultValues: { tr: 'Şifrenizi buradan güncelleyebilirsiniz', en: 'You can update your password here' } },
  { key: 'buyCookieseal', defaultValues: { tr: 'Satın Al', en: 'Buy Now' } },
  {
    key: 'buyCookieseal_description',
    defaultValues: { tr: 'CookieSeal hizmetini satın almak için buraya tıklayın', en: 'Click here to buy CookieSeal service' },
  },
  { key: 'unknown', defaultValues: { tr: 'Bilinmeyen', en: 'Unknown' } },
  { key: 'necessary_cookies', defaultValues: { tr: 'Zorunlu Çerezler', en: 'Necessary Cookies' } },
  { key: 'performance_cookies', defaultValues: { tr: 'Performans Çerezleri', en: 'Performance Cookies' } },
  { key: 'marketing_cookies', defaultValues: { tr: 'Reklam ve Hedefleme Çerezleri', en: 'Marketing Cookies' } },
  { key: 'functional_cookies', defaultValues: { tr: 'Fonksiyonel Çerezler', en: 'Functional Cookies' } },
  { key: 'cookie_list', defaultValues: { tr: 'Çerez Listesi', en: 'Cookie List' } },
  { key: 'cookie_categories', defaultValues: { tr: 'Çerez Kategorileri', en: 'Cookie Categories' } },
  { key: 'cookie_name', defaultValues: { tr: 'Çerez Adı', en: 'Cookie Name' } },
  { key: 'category', defaultValues: { tr: 'Kategori', en: 'Category' } },
  { key: 'save_cookie_categories', defaultValues: { tr: 'Kategorileri Kaydet', en: 'Save Cookie Categories' } },
  {
    key: 'you_must_specify_categories_for_the_following_cookies',
    defaultValues: { tr: 'Aşağıdaki çerezler için kategori belirlemelisiniz', en: 'You must specify categories for the following cookies' },
  },
  { key: 'done', defaultValues: { tr: 'Tamam', en: 'Done' } },
  { key: 'consent_breakdown', defaultValues: { tr: 'Rıza Detayları', en: 'Consent Breakdown' } },
  {
    key: 'no_consent_records_for_the_selected_context.',
    defaultValues: { tr: 'Seçilen site için rıza bilgisi bulunamadı', en: 'No Consent records for the selected context.' },
  },
  { key: 'date_range', defaultValues: { tr: 'Tarih Aralığı', en: 'Date Range' } },
  { key: 'start_date', defaultValues: { tr: 'Başlangıç Tarihi', en: 'Start Date' } },
  { key: 'end_date', defaultValues: { tr: 'Bitiş Tarihi', en: 'End Date' } },
  { key: 'error_processing_transaction', defaultValues: { tr: 'İşlem gerçekleştirilemedi', en: 'Error processing transaction' } },
  { key: 'success', defaultValues: { tr: 'Başarılı', en: 'Success' } },
  { key: 'purchase_successful.', defaultValues: { tr: 'Satış işlemi başarılı', en: 'Purchase successful.' } },
  { key: 'register_new_site', defaultValues: { tr: 'Yeni site tanımla', en: 'Register New Site' } },
  { key: 'site_information', defaultValues: { tr: 'Site Bilgisi', en: 'Site Information' } },
  { key: 'package_selection', defaultValues: { tr: 'Üyelik Paketi', en: 'Package Selection' } },
  { key: 'site_name', defaultValues: { tr: 'Site Adı', en: 'Site Name' } },
  { key: 'site_owner', defaultValues: { tr: 'Site Hizmeti', en: 'Site Service' } },
  { key: 'site_url', defaultValues: { tr: 'Site Adresi (https://...)', en: 'Site URL (https://...)' } },
  { key: 'credit_card_information', defaultValues: { tr: 'Kredi Kartı Bilgileri', en: 'Credit Card Information' } },
  { key: 'card_holder_name', defaultValues: { tr: 'Kart Sahibinin Adı', en: 'Card Holder Name' } },
  { key: 'card_number', defaultValues: { tr: 'Kart Numarası', en: 'Card Number' } },
  { key: 'expire_month', defaultValues: { tr: 'Son Kullanım Tarihi (Ay)', en: 'Expire Month' } },
  { key: 'expire_year', defaultValues: { tr: ' Son Kullanım Tarihi (Yıl)', en: 'Expire Year' } },
  { key: 'make_payment_and_register', defaultValues: { tr: 'Ödemeyi Yap ve Siteyi Kaydet', en: 'Make Payment & Register' } },
  { key: 'monthly_payment', defaultValues: { tr: 'Aylık Ödeme', en: 'Monthly Payment' } },
  { key: 'yearly_payment', defaultValues: { tr: 'Yıllık Ödeme', en: 'Yearly Payment' } },
  { key: 'ok', defaultValues: { tr: 'TAMAM', en: 'OK' } },
  {
    key: 'error_signing_in._please_try_again.',
    defaultValues: { tr: 'Kullanıcı adı veya şifre hatalı.', en: 'Username or password is wrong.' },
  },
  { key: 'site_address_exists', defaultValues: { tr: 'Site adresi tanımlı', en: 'Site address exists' } },
  { key: 'email_address_in_use', defaultValues: { tr: 'E-posta adresi kullanımda', en: 'Email address in use' } },
  { key: 'passwords_do_not_match', defaultValues: { tr: 'Parolalar eşleşmiyor', en: 'Passwords do not match' } },
  {
    key: 'invalid_phone_number',
    defaultValues: { tr: 'Telefon numarası geçerli değil (+905554443322)', en: 'Phone number is invalid (+905554443322)' },
  },
  { key: 'error_processing_transaction', defaultValues: { tr: 'İşlem gerçekleştirilemedi', en: 'Error processing transaction' } },
  { key: 'success', defaultValues: { tr: 'Başarılı', en: 'Success' } },
  {
    key: 'purchase_successful._click_ok_to_continue_to_sign_in_page.',
    defaultValues: { tr: 'Satın alma başarılı. Giriş yapmak için tıklayın', en: 'Purchase successful. Click OK to continue to Sign In page.' },
  },
  { key: 'return_to_site', defaultValues: { tr: 'Siteye geri dön', en: 'Return to Site' } },
  { key: 'choose_your_package', defaultValues: { tr: 'Üyelik paketini seçin', en: 'Choose your package' } },
  { key: 'site_information', defaultValues: { tr: 'Site Bilgileri', en: 'Site Information' } },
  { key: 'site_url', defaultValues: { tr: 'Site Adresi (https://...)', en: 'Site URL (https://...)' } },
  { key: 'user_information', defaultValues: { tr: 'Kullanıcı Bilgileri', en: 'User Information' } },
  { key: 'first_name', defaultValues: { tr: 'Ad', en: 'First Name' } },
  { key: 'last_name', defaultValues: { tr: 'Soyad', en: 'Last Name' } },
  { key: 'account_information', defaultValues: { tr: 'Hesap Bilgileri', en: 'Account Information' } },
  { key: 'email', defaultValues: { tr: 'E-posta', en: 'Email' } },
  { key: 'phone', defaultValues: { tr: 'Telefon', en: 'Phone' } },
  { key: 'password', defaultValues: { tr: 'Parola', en: 'Password' } },
  { key: 're-type_password', defaultValues: { tr: 'Parola (Tekrar)', en: 'Re-type Password' } },
  { key: 'company_information', defaultValues: { tr: 'Firma Bilgileri', en: 'Company Information' } },
  { key: 'company_name', defaultValues: { tr: 'Firma Unvanı', en: 'Company Name' } },
  { key: 'tax_office', defaultValues: { tr: 'Vergi Dairesi', en: 'Tax Office' } },
  { key: 'tax_number', defaultValues: { tr: 'Vergi Numarası', en: 'Tax Number' } },
  { key: 'country', defaultValues: { tr: 'Ülke', en: 'Country' } },
  { key: 'city', defaultValues: { tr: 'Şehir', en: 'City' } },
  { key: 'street_address', defaultValues: { tr: 'Adres', en: 'Street Address' } },
  { key: 'register', defaultValues: { tr: 'Kayıt Ol', en: 'Register' } },
  { key: 'ok', defaultValues: { tr: '', en: 'OK' } },
  { key: 'cookie_lists', defaultValues: { tr: 'Cookie Listesi', en: 'Cookies List' } },
  { key: 'url', defaultValues: { tr: 'Adres', en: 'Url' } },
  { key: 'supported_languages', defaultValues: { tr: 'Desteklenen Diller', en: 'Supported Languages' } },
  { key: 'preferred_language', defaultValues: { tr: 'Tercih Edilen Dil', en: 'Preferred Language' } },
  { key: 'cookie_policy_url', defaultValues: { tr: 'Çerez Politikası URL’i', en: 'Cookie Policy Url' } },
  { key: 'cookie_policy_language', defaultValues: { tr: 'Çerez Politikası dili', en: 'Cookie Policy Language' } },
  { key: 'show_cookie_policy', defaultValues: { tr: 'Çerez Politikasını Göster', en: 'Show Cookie Policy' } },
  { key: 'registering_cookiseal', defaultValues: { tr: 'CookieSeal Kayıt', en: 'Registering CookieSeal' } },
  {
    key: 'register_the_required_assets_in_the_head_tag_of_your_html_page',
    defaultValues: {
      tr:
        "Aşağıda size iletilen kod bloklarını projenizin ana html dosyası (public, index..) olarak adlandırılan alana ekleyiniz. Eklenip eklenmediğini websitenizde sayfa kaynağını görüntüle(F12)'den kontrol edebilirsiniz. Kopyalamak için metin alanına tıklayınız.",
      en:
        'Add the code blocks sent to you below to the area called the main html file of your project (public, index..). You can check if it has been added on your website by viewing the page source (F12). Click on the text area to copy.',
    },
  },
  {
    key: 'copied_to_clipboard',
    defaultValues: {
      tr: 'Panoya kopyalandı',
      en: 'Copied to clipboard',
    },
  },
  {
    key: 'copy_to_clipboard',
    defaultValues: {
      tr: 'Panoya kopyala',
      en: 'Copy to clipboard',
    },
  },
  {
    key: 'installation_cookieseal_title_description',
    defaultValues: {
      tr:
        "Cookie Seal'ın web sitesine kurulumu oldukça kolay olup, ürünün 'Hukuka Uygun' olarak çalışabilmesi için web sitenizdeki cookie'lerin yönetimini, Cookie Seal ile sağlayacak şekilde Kurulum Yönergesine uygun olarak yapılmalıdır ",
      en:
        "Cookie Seal's installation on the website is very easy, and in order for the product to work 'Lawfully', the management of cookies on your website must be done in accordance with the Installation Directive, with Cookie Seal.",
    },
  },
  {
    key: 'integration_steps',
    defaultValues: {
      tr: 'Kurulum Aşamaları',
      en: 'Installation Steps',
    },
  },
  {
    key: 'insallation_steps_cookieseal_one',
    defaultValues: {
      tr: '- Ürüne ait JS ve CSS dokümanlarının eklenmesi',
      en: 'Register the required assets in the head tag of your html page',
    },
  },
  {
    key: 'insallation_steps_cookieseal_two',
    defaultValues: {
      tr: '- Tenant ve Site ID’lerin eklenmesi',
      en: 'Register the required assets in the head tag of your html page',
    },
  },
  {
    key: 'insallation_steps_cookieseal_three',
    defaultValues: {
      tr: '- Sınıflandırılmış Cookie’lerin ilgili sınıflar altına eklenmesi (Opsiyonel)',
      en: 'Register the required assets in the head tag of your html page',
    },
  },
  {
    key: 'insallation_steps_cookieseal_four',
    defaultValues: {
      tr: '- Cookie Tercih Ekranı’nın yeniden açılması',
      en: 'Register the required assets in the head tag of your html page',
    },
  },

  { key: 'initializing_cookieseal', defaultValues: { tr: "Cookie Seal'ın Başlatılması", en: 'Initializing Cookie Seal' } },
  {
    key: 'copy_and_paste_the_following_template_just_after_the_opening_body_tag',
    defaultValues: {
      tr:
        'Aşağıda ki kod bloğunu html dosyanızda ki <body></body> tagleri arasına ekleyiniz. Body tagından sonra eklenmesi en altta eklenmesine oranla daha çok önerilmektedir. Kopyalamak için metin alanına tıklayınız.',
      en:
        'Add the code block below between the <body></body> tags in your html file. Adding it after the body tag is more recommended than adding it at the bottom. Click on the text area to copy.',
    },
  },
  { key: 'showing_settings_popup', defaultValues: { tr: 'Ayarlar Penceresinin Gösterilmesi', en: 'Showing Settings Popup' } },
  {
    key: 'you_can_show_the_cookie_customization_popup_by_calling_the_following_method',
    defaultValues: {
      tr: 'Özelleştirme penceresini aşağıdaki metodu çağırarak gerçekleştirebilirsiniz. Kopyalamak için metin alanına tıklayınız.',
      en: 'You can show the cookie customization popup by calling the following method. Click on the text area to copy.',
    },
  },
  { key: 'language_settings', defaultValues: { tr: 'Dil Seçenekleri', en: 'Language Settings' } },
  { key: 'websites', defaultValues: { tr: 'Web siteleri', en: 'Websites' } },
  { key: 'site_updated.', defaultValues: { tr: 'Site güncellendi.', en: 'Site updated.' } },
  { key: 'error_updating_site.', defaultValues: { tr: 'Site güncellenemedi', en: 'Error updating site.' } },
  { key: 'edit_site', defaultValues: { tr: 'Site Düzenleme', en: 'Edit Site' } },
  { key: 'site_information', defaultValues: { tr: 'Site Bilgileri', en: 'Site Information' } },
  { key: 'user_interface', defaultValues: { tr: 'Kullanıcı Arayüzleri', en: 'User Interface' } },
  { key: 'cookies', defaultValues: { tr: 'Çerezler', en: 'Cookies' } },
  { key: 'integration', defaultValues: { tr: 'Entegrasyon', en: 'Integration' } },
  { key: 'customization', defaultValues: { tr: 'Özelleştirme', en: 'Customization' } },
  { key: 'update_site', defaultValues: { tr: 'Siteyi Güncelle', en: 'Update Site' } },
  { key: 'youre_all_set', defaultValues: { tr: 'Hepsi bu kadar!', en: "You're all set!" } },
  { key: 'integration_directions', defaultValues: { tr: 'Entegrasyon Yönergeleri', en: 'Integration Directions' } },
  { key: 'customize_banner', defaultValues: { tr: 'Banner Özelleştirme', en: 'Banner Customizing' } },
  { key: 'month', defaultValues: { tr: 'ay', en: 'month' } },
  { key: 'year', defaultValues: { tr: 'yıl', en: 'year' } },
  { key: 'not_available', defaultValues: { tr: 'Değiştirilemez', en: 'Not Available' } },
  { key: 'change_password', defaultValues: { tr: 'Parolayı Güncelle', en: 'Change Password' } },
  { key: '__sidebar.system_admin__', defaultValues: { tr: 'Yönetim', en: 'Management' } },
  { key: '__sidebar.tenants__', defaultValues: { tr: 'Müşteriler', en: 'Tenants' } },
  { key: '__sidebar.prices__', defaultValues: { tr: 'Fiyatlar', en: 'Prices' } },
  { key: '__prices.title__', defaultValues: { tr: 'Fiyatlar', en: 'Prices' } },
  { key: '__prices.price__', defaultValues: { tr: 'Fiyat', en: 'Price' } },
  { key: '__prices_update_prices__', defaultValues: { tr: 'Güncelle', en: 'Update' } },
  { key: '__tenants.title__', defaultValues: { tr: 'Müşteriler', en: 'Tenants' } },
  { key: '__tenants.tenant_name__', defaultValues: { tr: 'Müşteri Adı', en: 'Tenant Name' } },
  { key: 'yes', defaultValues: { tr: 'Evet', en: 'Yes' } },
  { key: 'no', defaultValues: { tr: 'Hayır', en: 'No' } },
  { key: 'timepicker_analyze', defaultValues: { tr: 'Analiz Et', en: 'Analyze' } },
  { key: 'cookie_list.definition_head', defaultValues: { tr: 'Açıklama', en: 'Definition' } },
  { key: 'cookie_list.expire_head', defaultValues: { tr: 'Geçerlilik Süresi', en: 'Expire' } },
  { key: 'cookie_list.definition_info', defaultValues: { tr: 'Açıklama Yok', en: 'Unknown' } },
  { key: 'cookie_list.expire_info', defaultValues: { tr: 'Bilinmiyor', en: 'Unknown' } },
  { key: 'tenant_list.tenant_name', defaultValues: { tr: 'Tenant Adı', en: 'Tenant Name' } },
  { key: 'tenant_list.site_name', defaultValues: { tr: 'Site Adı', en: 'Site Name' } },
  { key: 'tenant_list.site_url', defaultValues: { tr: 'Site Adresi', en: 'Site URL' } },
  { key: 'tenant_list.start_date', defaultValues: { tr: 'Başlangıç Tarihi', en: 'Start Date' } },
  { key: 'tenant_list.end_date', defaultValues: { tr: 'Bitiş Tarihi', en: 'End Date' } },
  { key: 'tenant_list.status', defaultValues: { tr: 'Site Durumu', en: 'Site Status' } },
]
export default DefaultUITexts

export const GetDefaultUIText = (key: string, language: string) =>
  _.get(
    DefaultUITexts.find(i => i.key === key),
    `defaultValues.${language}`
  )

export const GetText = (key: string) => {
  const entry = DefaultUITexts.find(i => i.key === key)
  if (entry) {
    return _.get(entry, `defaultValues.${navigator.language.substr(0, 2)}`)
  }
  return key
}

export const GetItem = (key: string) => DefaultUITexts.find(i => i.key === key)
