import ChangePassword from './components/change-password'
import DashboardLayout from 'layouts/dashboard'
import GeneralSettings from './components/general'
import React, { Component } from 'react'
import Store from 'stores/store'
import Subscriptions from './components/subscriptions'
import {
  createStyles,
  Tab,
  Tabs,
  Theme,
  withStyles,
  WithStyles
} from '@material-ui/core'
import { GetText as $ } from 'stores/UITexts'
import { observable } from 'mobx'
import { observer } from 'mobx-react'
import AccountCard from 'views/dashboard/components/AccountCards/index'
import BuyCookieseal from './components/buy-cookieseal'

const Styles = (theme: Theme) =>
  createStyles({
    root: {
      height: '100%',
      display: 'flex',
      justifyContent: 'space-between',
      flexDirection: 'column',
    },
    container: {
      marginTop: 20,
      padding: theme.spacing() * 3,
    },
    portlet: {
      marginTop: theme.spacing() * 4,
      paddingLeft: 10,
      paddingRight: 10,
      borderWidth: 1,
      borderStyle: 'solid',
      borderColor: '#dcdcdc',
      maxWidth: '100%',
      borderRadius: 5,
      backgroundColor: '#fff',
    },
  })

interface Props extends WithStyles<typeof Styles> { }

@observer
class AccountSettings extends Component<Props> {
  @observable tabIndex = 0

  handleChange = (event: any, newValue: any) => {
    this.tabIndex = newValue
  }

  handleCallback = (childData: any) => {
    this.tabIndex = childData
  }

  render() {
    const { classes } = this.props

    return (
      <DashboardLayout title={$('account_settings')}>
        <div className={classes.root}>
          <div className={classes.container}>
            <AccountCard parentCallback={this.handleCallback} />
            <Tabs value={this.tabIndex} onChange={this.handleChange} indicatorColor="primary" textColor="primary">
              <Tab label={$('general')} />
              <Tab label={$('subscriptions')} />
              <Tab label={$('security')} />
              <Tab label={$('buyCookieseal')} />
            </Tabs>
            {Store.tenant && this.tabIndex === 0 && <GeneralSettings />}
            {Store.tenant && this.tabIndex === 1 && <Subscriptions />}
            {Store.tenant && this.tabIndex === 2 && <ChangePassword />}
            {Store.tenant && this.tabIndex === 3 && <BuyCookieseal history={''} />}

          </div>
        </div>
      </DashboardLayout>
    )
  }

}

export default withStyles(Styles)(AccountSettings)
