import _ from 'lodash'
import axios from 'axios'
import { action, observable } from 'mobx'
import { apiUrl } from 'common/config'
import { PaymentPlan } from 'stores/models'

export interface PricingInfo {
  monthly: { price: number }
  yearly: { price: number }
}

export enum PaymentPeriod {
  Monthly = 'MONTHLY',
  Yearly = 'YEARLY',
}

class PaymentPlanStore {
  @observable items!: PaymentPlan[]
  @observable loading = true

  @observable defaultPrices = {
    tr: {
      monthly: { price: 300, support: 0 },
      yearly: { price: 3000, support: 0 },
    },
    eur: {
      monthly: { price: 0, support: 8 },
      yearly: { price: 0, support: 0 },
    },
  }

  @action async load() {
    axios.defaults.withCredentials = false
    try {
      const response = await axios.get(`${apiUrl}/payment/plans`)
      this.items = response.data
      // this.defaultPrices.tr.monthly.price = this.items.find(i => i.name === 'TRY01_2022')!.price
      // this.defaultPrices.tr.yearly.price = this.items.find(i => i.name === 'TRY12_2022')!.price
      // this.defaultPrices.eur.monthly.price = this.items.find(i => i.name === 'EUR01_2022')!.price
      // this.defaultPrices.eur.yearly.price = this.items.find(i => i.name === 'EUR12_2022')!.price
    } catch (error) {
    } finally {
      axios.defaults.withCredentials = true
      this.loading = false
    }
  }

  getPaymentPlan(tier: string, currencyCode: string, period: number) {
    return this.items.find(i => i.name === `${tier}${currencyCode}${_.padStart(period + '', 2, '0')}`)!
  }

  getPrices(): PricingInfo {
    // const currencyCode = navigator.language.startsWith('tr') ? 'TRY' : 'EUR'
    const currencyCode = 'TRY'
    const productPlanMonthly = this.items.find(i => i.name === `${currencyCode}01_2024`)!
    const productPlanYearly = this.items.find(i => i.name === `${currencyCode}12_2024`)!

    return {
      monthly: { price: productPlanMonthly.price },
      yearly: { price: productPlanYearly.price },
    }
  }
}

export default new PaymentPlanStore()
