import _ from 'lodash'
import React, { Component } from 'react'
import Store from 'stores/store'
import uiStore from 'stores/ui-store'
import validate from 'validate.js'
import {
  Button,
  createStyles,
  TextField,
  Theme,
  withStyles,
  WithStyles
  } from '@material-ui/core'
import { GetText as $ } from 'stores/UITexts'
import { observable } from 'mobx'
import { observer } from 'mobx-react'
import { Portlet, PortletContent, PortletFooter, PortletHeader, PortletLabel } from 'components'
import { Security } from '@material-ui/icons'

const Styles = (theme: Theme) =>
  createStyles({
    root: {
      marginTop: theme.spacing() * 4,
      paddingLeft: 10,
      paddingRight: 10,
      borderWidth: 1,
      borderStyle: 'solid',
      borderColor: '#001e50',
      maxWidth: '100%',
      borderRadius: 18,
      backgroundColor: '#fff',
    },
    formContainer: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    column: {
      width: '49%',
    },
    field: {
      marginBottom: theme.spacing() * 3,
    },
    button:{
      backgroundColor:'#394172'
    }
  })

const validationSchema = {
  oldPassword: { presence: { allowEmpty: false } },
  password: {
    presence: { allowEmpty: false, message: $('validation.is_required') },
    length: {
      maximum: 128,
    },
  },
  passwordCheck: {
    presence: { allowEmpty: false, message: $('validation.is_required') },
    equality: {
      attribute: 'password',
      comparator: (v1: string, v2: string) => {
        return v1 === v2
      },
    },
    length: {
      maximum: 128,
    },
    button:{
      backgroundColor:'#394172'
    }
  },
}

interface Props extends WithStyles<typeof Styles> {}

@observer
class ChangePassword extends Component<Props> {
  @observable values: { [key: string]: string } = {
    oldPassword: '',
    password: '',
    passwordCheck: '',
  }
  @observable touched: { [key: string]: boolean } = {
    oldPassword: false,
    password: false,
    passwordCheck: false,
  }
  @observable errors: { [key: string]: string[] } = {
    oldPassword: [],
    password: [],
    passwordCheck: [],
  }

  @observable isValid = false
  @observable error = ''

  validateForm = _.debounce(() => {
    const errors = validate(this.values, validationSchema)
    this.errors = errors || {}
    this.isValid = errors ? false : true
  }, 300)

  handleFieldChange = (field: string, value: string) => {
    this.error = ''
    this.touched[field] = true
    this.values[field] = value
    this.validateForm()
  }

  checkError(field: string) {
    return this.touched[field] && this.errors[field] && this.errors[field].length > 0
  }

  async update() {
    try {
      await Store.updatePassword({
        oldPassword: this.values.oldPassword,
        newPassword: this.values.password,
      })
      uiStore.showSnackbar($('password_changed_message'))
    } catch {
      uiStore.showSnackbar($('password_change_error_message'))
    }
  }

  render() {
    const { classes } = this.props

    return (
      <>
      <Portlet className={classes.root}>
        <PortletHeader>
        <div style={{display:'flex', alignItems:'center',}}>
          <Security/>
          <PortletLabel title={$('change_password')} />
        </div>
        </PortletHeader>
        <PortletContent>
          <div className={classes.formContainer}>
            <div className={classes.column}>
              <TextField
                label={$('old_password')}
                variant='standard'
                type="password"
                fullWidth
                className={classes.field}
                name="oldPassword"
                value={this.values.oldPassword}
                error={this.checkError('oldPassword')}
                onChange={e => this.handleFieldChange('oldPassword', e.target.value)}
              />
            </div>
          </div>
          <div className={classes.formContainer}>
            <div className={classes.column}>
              <TextField
                label={$('new_password')}
                variant='standard'
                type="password"
                fullWidth
                className={classes.field}
                name="password"
                value={this.values.password}
                error={this.checkError('password')}
                onChange={e => this.handleFieldChange('password', e.target.value)}
              />
            </div>
            <div className={classes.column}>
              <TextField
                label={$('retype_new_password')}
                variant='standard'
                type="password"
                fullWidth
                className={classes.field}
                name="passwordCheck"
                value={this.values.passwordCheck}
                error={this.checkError('passwordCheck')}
                onChange={e => this.handleFieldChange('passwordCheck', e.target.value)}
              />
            </div>
          </div>
        </PortletContent>
        <PortletFooter>
          <Button color="primary" className={classes.button} variant="contained" onClick={() => this.update()}>
            {$('change_password')}
          </Button>
        </PortletFooter>
      </Portlet>
      {/* <Description/> */}
      </>
    )
  }
}

export default withStyles(Styles)(ChangePassword)
