import React from 'react'
import classNames from 'classnames'
import { createStyles, withStyles, WithStyles } from '@material-ui/core'

const styles = () =>
  createStyles({
    root: {
      justifyContent: 'flex-end',
      alignItems: 'center',
      display: 'flex',
    },
  })

interface Props extends WithStyles<typeof styles> {
  children?: React.ReactNode
  className?: string
}

const PortletToolbar = (props: Props) => {
  const { classes, className, children, ...rest } = props

  const rootClassName = classNames(classes.root, className)

  return (
    <div {...rest} className={rootClassName}>
      {children}
    </div>
  )
}

export default withStyles(styles)(PortletToolbar)
