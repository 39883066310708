import classNames from 'classnames';
import React, { Component } from 'react';
import Styles from './styles';
import { Paper } from 'components';
import { Typography, withStyles, WithStyles } from '@material-ui/core';
import { GetText as $} from 'stores/UITexts'

interface Props extends WithStyles<typeof Styles> {
  className?: string
  value: number
}

class TotalConsents extends Component<Props> {
  render() {
    const { classes, className, value, ...rest } = this.props

    const rootClassName = classNames(classes.root, className)

    return (
      <Paper {...rest} className={rootClassName}>
        <div className={classes.content}>
          <div className={classes.details}>
            <Typography className={classes.title} variant="body2">
              {$('dashboard.total_consents')}
            </Typography>
            <Typography className={classes.value} variant="h3">
              {value}
            </Typography>
          </div>
          <div className={classes.iconWrapper}>
            <img
              alt="total-consents"
              src="/images/cookies.png"
              className={classes.icon}
            />
          </div>
        </div>
      </Paper>
    )
  }
}

export default withStyles(Styles)(TotalConsents)
