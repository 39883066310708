import { createStyles } from '@material-ui/core'

export default (theme: any) =>
  createStyles({
    root: {
      paddingLeft: 10,
      paddingRight: 10,
      marginTop:'20px',
      borderWidth: 1,
      borderStyle: 'solid',
      borderColor: '#dcdcdc',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      maxWidth: '100%',
      background: 'radial-gradient(circle, rgba(175,193,255,1) 0%, rgba(57,65,114,1) 100%)',   
      borderRadius: 18,
      color: 'white'
    },
    title: {
      marginRight: 20,
      marginLeft:5,
      color:'white'
    },
    titleHead: {
      color:'#394172',
      marginRight: 20,
      marginLeft:5,
    }
  })
