import React, { Component } from 'react'
import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core'
import { GetText as $ } from 'stores/UITexts'
import { observer } from 'mobx-react'
import { Portlet, PortletContent, PortletHeader, PortletLabel } from 'components'
import { CodeOutlined } from '@material-ui/icons'
import BannerCustomization from './BannerCustomization'

const Styles = (theme: Theme) =>
  createStyles({
    root: {
      marginTop: theme.spacing() * 4,
      paddingLeft: 10,
      paddingRight: 10,
      borderWidth: 1,
      borderStyle: 'solid',
      borderColor: '#001e50',
      maxWidth: '100%',
      borderRadius: 18,
      backgroundColor: '#fff',
    },
    highlighter: {
      marginTop: 10,
      marginBottom: 10,
    },
  })

interface Props extends WithStyles<typeof Styles> {
  site: any
}

@observer
class Customization extends Component<Props> {

  render() {
    const { classes } = this.props
    return (
      <Portlet className={classes.root}>
        <PortletHeader>
        <div style={{display:'flex', alignItems:'center',}}>
          <CodeOutlined/>
          <PortletLabel title={$('customize_banner')} />
          </div>
        </PortletHeader>
        <PortletContent>
        <BannerCustomization siteInfo={this.props} />
        </PortletContent>
      </Portlet>
    )
  }
}

export default withStyles(Styles)(Customization)
