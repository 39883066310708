import classNames from 'classnames'
import DashboardLayout from 'layouts/dashboard'
import React, { Component } from 'react'
import styles from './styles'
import { GetText as $ } from 'stores/UITexts'
import { observable } from 'mobx'
import { observer } from 'mobx-react'
import { Portlet, PortletContent, PortletFooter, PortletHeader, PortletLabel } from 'components'
import { withStyles, WithStyles } from '@material-ui/core'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Select,
} from '@material-ui/core'

interface Props extends WithStyles<typeof styles> {
  className?: string
}

@observer
class CookieList extends Component<Props> {
  @observable dialogOpen = false

  @observable cookies = [
    { name: '_fbp', type: 'Unknown' },
    { name: 'fr', type: 'MarketingCookies' },
    { name: 'ASP.NET_SessionId', type: 'NecessaryCookies' },
    { name: '_gid', type: 'PerformanceCookies' },
    { name: '_gat', type: 'PerformanceCookies' },
    { name: '_ga', type: 'PerformanceCookies' },
  ]

  saveCookies = () => {
    if (this.cookies.filter(c => c.type === 'Unknown').length > 0) {
      this.dialogOpen = true
    }
  }

  renderSelect(row: any) {
    return (
      <Select
        value={row.type}
        onChange={e => (row.type = e.target.value)}
        inputProps={{
          name: 'type',
          id: 'type-simple',
        }}
      >
        <MenuItem value={'Unknown'}>{$('unknown')}</MenuItem>
        <MenuItem value={'NecessaryCookies'}>{$('necessary_cookies')}</MenuItem>
        <MenuItem value={'PerformanceCookies'}>{$('performance_cookies')}</MenuItem>
        <MenuItem value={'MarketingCookies'}>{$('marketing_cookies')}</MenuItem>
        <MenuItem value={'FunctionalCookies'}>{$('functional_cookies')}</MenuItem>
      </Select>
    )
  }

  render() {
    const { classes, className } = this.props
    const rootClassName = classNames(classes.root, className)

    return (
      <DashboardLayout title={$('cookie_list')}>
        <Portlet className={rootClassName}>
          <PortletHeader>
            <PortletLabel title={$('cookie_categories')} />
          </PortletHeader>
          <PortletContent noPadding className={classes.portletContent}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>{$('cookie_name')}</TableCell>
                  <TableCell align="right">{$('category')}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {this.cookies.map(row => (
                  <TableRow key={row.name}>
                    <TableCell component="th" scope="row">
                      {row.name}
                    </TableCell>
                    <TableCell align="right">{row.type === 'Unknown' ? this.renderSelect(row) : row.type}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </PortletContent>
          <PortletFooter className={classes.portletFooter}>
            <Button color="primary" variant="contained" onClick={this.saveCookies}>
              {$('save_cookie_categories')}
            </Button>
          </PortletFooter>
        </Portlet>

        <Dialog open={this.dialogOpen} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
          <DialogTitle id="alert-dialog-title">{'Unknown cookie categories'}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <p>{$('you_must_specify_categories_for_the_following_cookies')}:</p>
              {this.cookies
                .filter(c => c.type === 'Unknown')
                .map(c => (
                  <p className={classes.unknownCookie} key={c.name}>
                    {c.name}
                  </p>
                ))}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => (this.dialogOpen = false)} color="primary" autoFocus>
              {$('done')}
            </Button>
          </DialogActions>
        </Dialog>
      </DashboardLayout>
    )
  }
}

export default withStyles(styles)(CookieList)
