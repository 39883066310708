import classNames from 'classnames'
import React, { Component } from 'react'
import RefreshIcon from '@material-ui/icons/Refresh'
import ReportStore from 'stores/report-store'
import { Chip, createStyles, IconButton, withStyles, WithStyles } from '@material-ui/core'
import { GetText as $ } from 'stores/UITexts'
import { observable } from 'mobx'
import { observer } from 'mobx-react'
import { Portlet, PortletContent, PortletHeader, PortletLabel } from 'components'

const styles = (theme: any) =>
  createStyles({
    root: {},
    container: {
      flex: 1,
      display: 'flex',
      position: 'relative',
      alignItems: 'center',
      justifyContent: 'center',
    },
    chart: {
      width: 500,
      height: 450,
    },
    performance: {
      position: 'absolute',
      width: 300,
      height: 300,
      opacity: 0.3,
      borderRadius: '50%',
      backgroundColor: 'blue',
    },
    marketing: {
      position: 'relative',
      width: 300,
      height: 300,
      opacity: 0.3,
      borderRadius: '50%',
      backgroundColor: 'yellow',
      left: 150,
    },
    functional: {
      position: 'relative',
      width: 300,
      height: 300,
      opacity: 0.3,
      borderRadius: '50%',
      backgroundColor: 'red',
      top: -150,
      left: 75,
    },
    s01: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      position: 'absolute',
      width: 150,
      height: 300,
      top: 0,
      left: 0,
    },
    s02: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      position: 'absolute',
      width: 150,
      height: 300,
      top: 0,
      left: 150,
    },
    s02Top: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: 150,
      height: 110,
      paddingTop: 40,
    },
    s02Bottom: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: 150,
      height: 150,
    },
    s03: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      position: 'absolute',
      width: 150,
      height: 300,
      top: 0,
      left: 300,
    },
    sMiddle: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      position: 'absolute',
      width: 450,
      height: 125,
      top: 175,
      left: 0,
    },
    sM01: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: 185,
      height: 125,
      paddingLeft: 40,
    },
    sM02: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: 225,
      height: 125,
    },
    sBottom: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      position: 'absolute',
      width: 450,
      height: 150,
      top: 300,
      left: 0,
    },
  })

interface Props extends WithStyles<typeof styles> {
  className?: string
}

@observer
class ConsentBreakdown extends Component<Props> {
  @observable breakdown = {
    all: 0,
    none: 0,
    performanceOnly: 0,
    functionalOnly: 0,
    marketingOnly: 0,
    performanceAndFunctional: 0,
    performanceAndMarketing: 0,
    functionalAndMarketing: 0,
  }

  async refresh() {
    this.breakdown = await ReportStore.getConsentBreakdown()
  }

  render() {
    const { classes, className } = this.props
    const rootClassName = classNames(classes.root, className)

    return (
      <Portlet className={rootClassName}>
        <PortletHeader noDivider>
          <PortletLabel title={$('consent_breakdown')} />
          <IconButton aria-label="refresh" onClick={() => this.refresh()}>
            <RefreshIcon />
          </IconButton>
        </PortletHeader>
        <PortletContent>
          <div className={classes.container}>
            <div className={classes.chart}>
              <div className={classes.performance}></div>
              <div className={classes.marketing}></div>
              <div className={classes.functional}></div>
              <div className={classes.s01}>
                <Chip label={this.breakdown.performanceOnly} />
              </div>
              <div className={classes.s02}>
                <div className={classes.s02Top}>
                  <Chip label={this.breakdown.performanceAndMarketing} />
                </div>
                <div className={classes.s02Bottom}>
                  <Chip label={this.breakdown.all} />
                </div>
              </div>
              <div className={classes.s03}>
                <Chip label={this.breakdown.marketingOnly} />
              </div>
              <div className={classes.sMiddle}>
                <div className={classes.sM01}>
                  <Chip label={this.breakdown.performanceAndFunctional} />
                </div>
                <div className={classes.sM02}>
                  <Chip label={this.breakdown.functionalAndMarketing} />
                </div>
              </div>
              <div className={classes.sBottom}>
                <Chip label={this.breakdown.functionalOnly} />
              </div>
            </div>
          </div>
        </PortletContent>
      </Portlet>
    )
  }
}

export default withStyles(styles)(ConsentBreakdown)
