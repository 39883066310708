import classNames from 'classnames'
import React from 'react'
import { createStyles, Theme, withStyles } from '@material-ui/core'

const styles = (theme: Theme) =>
  createStyles({
    root: {
      paddingLeft: theme.spacing() * 3,
      paddingRight: theme.spacing() * 3,
      paddingTop: theme.spacing() * 2,
      paddingBottom: theme.spacing() * 2,
    },
    noPadding: {
      padding: 0,
    },
  })

const PortletContent = (props: any) => {
  const { classes, className, children, noPadding, ...rest } = props

  const rootClassName = classNames(
    {
      [classes.root]: true,
      [classes.noPadding]: noPadding,
    },
    className
  )

  return (
    <div {...rest} className={rootClassName}>
      {children}
    </div>
  )
}

export default withStyles(styles)(PortletContent)
