import React from 'react'
import classNames from 'classnames'
import { withStyles } from '@material-ui/core'

const styles = (theme: any) => ({
  root: {
    paddingBottom: theme.spacing(),
    paddingTop: theme.spacing(),
    paddingLeft: theme.spacing() * 2,
    paddingRight: theme.spacing() * 2,
    borderTop: `1px solid ${theme.palette.border}`,
    borderBottomLeftRadius: '2px',
    borderBottomRightRadius: '2px',
  },
  noDivider: {
    borderTop: 'none',
  },
})

const PortletFooter = (props: any) => {
  const { classes, className, noDivider, children, ...rest } = props

  const rootClassName = classNames(
    {
      [classes.root]: true,
      [classes.noDivider]: noDivider,
    },
    className
  )

  return (
    <div {...rest} className={rootClassName}>
      {children}
    </div>
  )
}

export default withStyles(styles)(PortletFooter)
