import axiosInstance from './axios'
import Store from 'stores/store'
import { action, computed, observable, runInAction } from 'mobx'

class ReportStore {
  @observable startDate = new Date()
  @observable endDate = new Date()
  @observable context = ''
  @observable rawData: Array<{
    performance: number
    functional: number
    marketing: number
    desktopTotal: number
    mobileTotal: number
    day: number
    month: number
    year: number
    rejectedConsents: number
    mobileMarketing:number
    mobileFunctional:number
    mobilePerformance:number
    mobileRejected:number
  }> = []

  constructor() {
    runInAction(() => {
      this.startDate.setHours(0,0,0,0)
      this.endDate.setHours(23,59,59,999)
    })
  }

  @action async refreshStats() {
    if (!Store.authenticated) return
    const response = await axiosInstance.get('reporting/consents', {
      params: {
        siteId: this.context,
        startDate: this.startDate.toISOString(),
        endDate: this.endDate.toISOString(),
      },
    })

    if (response.data) {
      this.rawData = response.data.map((d: any) => ({
        performance: d.PerformanceCookies,
        functional: d.FunctionalCookies,
        marketing: d.MarketingCookies,
        mobilePerformance: d.PerformanceCookiesMobile,
        mobileFunctional: d.FunctionalCookiesMobile,
        mobileRejected: d.RejectedConsentsMobile,
        mobileMarketing: d.MarketingCookiesMobile,
        desktopTotal: d.PerformanceCookies + d.FunctionalCookies + d.MarketingCookies,
        mobileTotal:  d.PerformanceCookiesMobile + d.FunctionalCookiesMobile + d.MarketingCookiesMobile,
        day: d.dateParts.day,
        month: d.dateParts.month,
        year: d.dateParts.year,
        rejectedConsents: d.RejectedConsents,
      }))
    } else {
      this.rawData = []
    }
  }

  @action setStartDate(date: Date) {
    this.startDate = date
  }

  @action setEndDate(date: Date) {
    this.endDate = date
  }

  @action setContext(ctx: string) {
    this.context = ctx
    this.refreshStats()
  }

  @computed get performanceConsentCount() {
    return 0
  }

  async getConsentBreakdown() {
    const response = await axiosInstance.get('reporting/consents/breakdown', {
      params: {
        siteId: this.context,
        startDate: this.startDate.toISOString(),
        endDate: this.endDate.toISOString(),
      },
    })

    return response.data
  }
}

const store = new ReportStore()
export default store
