import React from 'react'
import ReactDOM from 'react-dom'
import './assets/scss/index.scss'
import App from './App'
import * as serviceWorker from './common/serviceWorker'
import '@fortawesome/fontawesome-free/css/all.min.css'
import 'bootstrap/dist/css/bootstrap.css'
import './assets/bootstrap-overrides.css'
// import { Provider } from 'react-redux'
// import { store } from './views/customization/redux/store'
import { BrowserRouter as Router } from 'react-router-dom'

ReactDOM.render(
  <Router>
    <App />
  </Router>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
