import classNames from 'classnames';
import palette from 'theme/palette';
import React, { Component } from 'react';
import styles from './styles';
import { Doughnut } from 'react-chartjs-2';
import { GetText as $ } from 'stores/UITexts';
import { IconButton, Typography } from '@material-ui/core';
import { options } from './chart';
import { Refresh as RefreshIcon } from '@material-ui/icons';
import { withStyles, WithStyles } from '@material-ui/core';
import {
  Portlet,
  PortletHeader,
  PortletLabel,
  PortletToolbar,
  PortletContent,
} from 'components'

interface Props extends WithStyles<typeof styles> {
  className?: string
  performance: number
  functional: number
  marketing: number
}

class ConsentPie extends Component<Props> {
  render() {
    const { classes, className, performance, functional, marketing, ...rest } = this.props
    const rootClassName = classNames(classes.root, className)

    const total = performance + functional + marketing

    const data = {
      datasets: [
        {
          data: [performance / total * 100, functional / total * 100, marketing / total * 100],
          backgroundColor: [
            palette.primary.main,
            palette.danger.main,
            palette.warning.main,
          ],
          borderWidth: 8,
          borderColor: palette.common.white,
          hoverBorderColor: palette.common.white,
        },
      ],
      labels: ['Performance', 'Functional', 'Total'],
    }    

    return (
      <Portlet {...rest} className={rootClassName}>
        <PortletHeader noDivider>
          <PortletLabel title={$('dashboard.consent_pie')} />
          <PortletToolbar>
            <IconButton className={classes.refreshButton} onClick={() => {}}>
              <RefreshIcon />
            </IconButton>
          </PortletToolbar>
        </PortletHeader>
        <PortletContent>
          <div className={classes.chartWrapper}>
            <Doughnut data={data} options={options} />
          </div>
          <div className={classes.stats}>
            <div className={classes.device}>
              <img
                alt="icon"
                src="/images/performance.png"
                className={classes.deviceIcon}
              />
              <Typography variant="body1">{$('dashboard.performance')}</Typography>
              <Typography style={{ color: palette.primary.main }} variant="h2">
                {!isNaN(total) ? Math.round(performance / total * 100) : 0}%
              </Typography>
            </div>
            <div className={classes.device}>
              <img
                alt="icon"
                src="/images/functional.png"
                className={classes.deviceIcon}
              />
              <Typography variant="body1">{$('dashboard.functional')}</Typography>
              <Typography style={{ color: palette.danger.main }} variant="h2">
                {!isNaN(total) ? Math.round(functional / total * 100) : 0}%
              </Typography>
            </div>
            <div className={classes.device}>
              <img
                alt="icon"
                src="/images/advertising.png"
                className={classes.deviceIcon}
              />
              <Typography variant="body1">{$('dashboard.marketing')}</Typography>
              <Typography style={{ color: palette.warning.main }} variant="h2">
                {!isNaN(total) ? Math.round(marketing / total * 100): 0}%
              </Typography>
            </div>
          </div>
        </PortletContent>
      </Portlet>
    )
  }
}

export default withStyles(styles)(ConsentPie)
