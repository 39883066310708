import axios from 'stores/axios'
import DashboardLayout from 'layouts/dashboard'
import React, { Component } from 'react'
import {
  Button,
  createStyles,
  TextField,
  Theme,
  Typography,
  withStyles,
  WithStyles
  } from '@material-ui/core'
import { GetText as $ } from 'stores/UITexts'
import { observable } from 'mobx'
import { observer } from 'mobx-react'
import { Portlet, PortletContent, PortletFooter } from 'components'

const Styles = (theme: Theme) =>
  createStyles({
    root: {
      height: '100%',
      display: 'flex',
      justifyContent: 'space-between',
      flexDirection: 'column',
    },
    container: {
      padding: theme.spacing() * 3,
    },
    portlet: {
      marginTop: theme.spacing() * 4,
      paddingLeft: 10,
      paddingRight: 10,
      borderWidth: 1,
      borderStyle: 'solid',
      borderColor: '#dcdcdc',
      maxWidth: '100%',
      borderRadius: 5,
      backgroundColor: '#fff',
    },
    prices: {
      display: 'flex',
    },
    column: {
      flex: 1,
      marginRight: 20,
    },
    item: {
      flex: 1,
      marginTop: 20,
      marginBottom: 20,
      display: 'flex',
      alignItems: 'center',
    },
    name: {
      flex: 1,
      width: 120,
    },
    price: {
      flex: 1,
    },
  })

interface Props extends WithStyles<typeof Styles> {
  history: any
}

interface Product {
  referenceCode: string
  name: string
  description: string
}

interface PaymentPlan {
  referenceCode: string
  name: string
  price: number
  productReferenceCode: string
}

const planSorter = (type: 'SCRIPT' | 'SUPPORT', a: PaymentPlan, b: PaymentPlan) => {
  const index = type === 'SCRIPT' ? 1 : 2
  const tier1 = Number(a.name.substr(index, 1))
  const tier2 = Number(b.name.substr(index, 1))

  const currency1 = a.name.substr(2, 3)
  const currency2 = b.name.substr(2, 3)

  if (currency1 < currency2) return -1
  if (currency2 < currency1) return 1

  if (tier1 < tier2) return -1
  if (tier2 < tier1) return 1

  if (tier1 === tier2) {
    const period1 = Number(a.name.substr(a.name.length - 2))
    const period2 = Number(b.name.substr(b.name.length - 2))
    return period1 - period2
  }

  return 0
}

@observer
class Prices extends Component<Props> {
  @observable products: Product[]
  @observable scriptPaymentPlans: PaymentPlan[]
  @observable supportPaymentPlans: PaymentPlan[]

  async componentDidMount() {
    const productsResponse = await axios.get<Product[]>('payment/products')
    this.products = productsResponse.data

    const paymentPlansResponse = await axios.get<PaymentPlan[]>('payment/plans')
    this.scriptPaymentPlans = paymentPlansResponse.data.filter(p => p.name.startsWith('T')).sort((a, b) => planSorter('SCRIPT', a, b))
    this.supportPaymentPlans = paymentPlansResponse.data.filter(p => p.name.startsWith('ST')).sort((a, b) => planSorter('SUPPORT', a, b))
  }

  async update() {}

  getPlan(type: 'SCRIPT' | 'SUPPORT', tier: number, currency: string, period: string) {
    const source = type === 'SCRIPT' ? this.scriptPaymentPlans : this.supportPaymentPlans
    const prefix = type === 'SCRIPT' ? 'T' : 'ST'
    return source.find(p => p.name === `${prefix}${tier}${currency}${period}`)!
  }

  updateScriptPrices(plan: PaymentPlan, newPrice: number) {
    plan.price = newPrice
    const period = plan.name.substr(plan.name.length - 2)
    const currency = plan.name.substr(2, 3)

    const t2 = this.getPlan('SCRIPT', 2, currency, period)
    const t3 = this.getPlan('SCRIPT', 3, currency, period)
    const t4 = this.getPlan('SCRIPT', 4, currency, period)
    t2.price = newPrice * 0.8
    t3.price = newPrice * 0.7
    t4.price = newPrice * 0.6
  }

  updateSupportPrices(plan: PaymentPlan, newPrice: number) {
    plan.price = newPrice
    const period = plan.name.substr(plan.name.length - 2)
    const currency = plan.name.substr(3, 3)

    const t2 = this.getPlan('SUPPORT', 2, currency, period)
    t2.price = newPrice * 0.15
  }

  render() {
    const { classes } = this.props

    if (!this.products || !this.scriptPaymentPlans || !this.supportPaymentPlans) {
      return (
        <DashboardLayout title={$('__prices.title__')}>
          <div className={classes.root}>
            <div className={classes.container}>
              <Portlet className={classes.portlet}>
                <PortletContent></PortletContent>
              </Portlet>
            </div>
          </div>
        </DashboardLayout>
      )
    }

    return (
      <DashboardLayout title={$('__prices.title__')}>
        <div className={classes.root}>
          <div className={classes.container}>
            <Portlet className={classes.portlet}>
              <PortletContent>
                <div className={classes.prices}>
                  <div className={classes.column}>
                    <Typography variant="h5">{this.products[0].name}</Typography>
                    {this.scriptPaymentPlans
                      .filter(p => p.productReferenceCode === this.products[0].referenceCode)
                      .map(p => (
                        <React.Fragment>
                          <div className={classes.item}>
                            <div className={classes.name}>
                              <Typography variant="h6">{p.name}</Typography>
                            </div>
                            <div className={classes.price}>
                              <TextField
                                label={$('__prices.price__')}
                                disabled={!p.name.startsWith('T1')}
                                variant="outlined"
                                fullWidth
                                value={p.price}
                                onChange={e => this.updateScriptPrices(p, Number(e.target.value))}
                              />
                            </div>
                          </div>
                        </React.Fragment>
                      ))}
                  </div>
                  <div className={classes.column}>
                    <Typography variant="h5">{this.products[1].name}</Typography>
                    {this.supportPaymentPlans
                      .filter(p => p.productReferenceCode === this.products[1].referenceCode)
                      .map(p => (
                        <React.Fragment>
                          <div className={classes.item}>
                            <div className={classes.name}>
                              <Typography variant="h6">{p.name}</Typography>
                            </div>
                            <div className={classes.price}>
                              <TextField
                                label={$('__prices.price__')}
                                disabled={!p.name.startsWith('ST1')}
                                variant="outlined"
                                fullWidth
                                value={p.price}
                                onChange={e => this.updateSupportPrices(p, Number(e.target.value))}
                              />
                            </div>
                          </div>
                        </React.Fragment>
                      ))}
                  </div>
                </div>
              </PortletContent>
              <PortletFooter>
                <Button color="primary" variant="contained" onClick={() => this.update()}>
                  {$('__prices_update_prices__')}
                </Button>
              </PortletFooter>
            </Portlet>
          </div>
        </div>
      </DashboardLayout>
    )
  }
}

export default withStyles(Styles)(Prices)
