import { createStyles, Theme } from '@material-ui/core/styles'

export default (theme: Theme) =>
  createStyles({
    root: {
      maxWidth: '100%',
      borderRadius:'20%',
      border: '1px solid #001e50',
      paddingTop: theme.spacing() * 3,
      paddingBottom: theme.spacing() * 2,
      paddingRight: theme.spacing() * 3,
      paddingLeft: theme.spacing() * 3,
      "&:hover": {
        transform: 'scale(1.1)',
      }
    },
    imageWrapper: {
      height: '64px',
      width: '64px',
      margin: '0 auto',
      border: '1px solid #EDF0F2',
      borderRadius: '40px',
      overflow: 'hidden',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      "&:hover": {
        transform: 'scale(1.5)',
      },
    },
    image: {
      width: '100%',
    },
    details: {},
    title: {
      fontSize: '18px',
      lineHeight: '21px',
      textAlign: 'center',
      marginTop: theme.spacing() * 2,
      textDecoration:'none'
    },
    description: {
      lineHeight: '16px',
      height: theme.spacing() * 4,
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      color: theme.palette.text.secondary,
      textAlign: 'center',
      marginTop: theme.spacing(),
      marginBottom: theme.spacing() * 2,
    },
    stats: {
      display: 'flex',
      alignItems: 'center',
      paddingTop: theme.spacing(),
    },
    updateIcon: {
      color: theme.palette.text.secondary,
    },
    updateText: {
      marginLeft: theme.spacing(),
      color: theme.palette.text.secondary,
      textDecoration:'none'
    },
    downloadsIcon: {
      marginLeft: 'auto',
      color: theme.palette.text.secondary,
    },
    downloadsText: {
      marginLeft: theme.spacing(),
      color: theme.palette.text.secondary,
    },

  })

  
