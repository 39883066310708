import DashboardLayout from 'layouts/dashboard'
import React, { Component } from 'react'
import Store from 'stores/store'
import Styles from './styles'
import { GetText as $ } from 'stores/UITexts'
import { Grid, Typography, withStyles, WithStyles } from '@material-ui/core'
import { Link } from 'react-router-dom'
import { observer } from 'mobx-react'
import { Toolbar } from './components'
import SiteCardComponent from 'views/dashboard/components/SiteCard'
import NoFoundSiteCard from 'views/dashboard/components/NoFoundSiteCard'
import { Button } from '@material-ui/core'
import { Table } from 'reactstrap'

interface Props extends WithStyles<typeof Styles> { }

interface IState {
  selectedSites?: string
}

@observer
class Sites extends Component<Props, IState> {
  constructor(props: Props) {
    super(props)

    this.state = {
      selectedSites: 'All',
    }
  }

  async handleCrawl  (url: string) {
    await Store.crawlSite(url)
  }

  renderSiteList() {
    if (Store.progress) {
      return <></>
    }
    let a = Store.tenant?.sites.filter(site => site.status === this.state.selectedSites).length
    if (a > 0) {
      return Store.tenant?.sites
        .filter(site => site.status === this.state.selectedSites)
        .map((site: any) => (
          <Grid item key={site.id} lg={4} md={6} xs={12}>
            {(site.status === 'COMPLETED' || site.status === 'ACTIVE') && (
              <Link to={`/site/${site.id}`}>
                <SiteCardComponent site={site} />
              </Link>
            )}
            {site.status !== 'COMPLETED' && site.status !== 'ACTIVE' && <SiteCardComponent site={site} />}
          </Grid>
        ))
    } else if (this.state.selectedSites === 'All') {
      return Store.tenant?.sites.map((site: any) => (
        <Grid item key={site.id} lg={4} md={6} xs={12}>
          {(site.status === 'COMPLETED' || site.status === 'ACTIVE') && (
            <Link to={`/site/${site.id}`}>
              <SiteCardComponent site={site} />
            </Link>
          )}
          {site.status !== 'COMPLETED' && site.status !== 'ACTIVE' && <SiteCardComponent site={site} />}
        </Grid>
      ))
    } else {
      return <NoFoundSiteCard site={this.state.selectedSites} />
    }
  }

  render() {
    const { classes } = this.props

    return (
      <DashboardLayout title={$('websites')}>
        <div className={classes.root}>
          <Toolbar />
          <div className={classes.content}>
            <div style={{ backgroundColor: '#394172', width: '100%', borderRadius: 10, padding: 15, textAlign: 'start', color: '#FFFFFF' }}>
              <Typography variant="h5" className={classes.titleHead} style={{ justifySelf: 'flex-start', color: '#FFFFFF', fontSize: 18 }}>
                {$('integration.description')}
              </Typography>
            </div>
            {/* <div className={classes.centerDiv}>
              <div onClick={() => this.setState({ selectedSites: 'All' })} className={classes.circleSix}>
                <span style={{ fontWeight: 700 }}>All</span>
              </div>
              <div onClick={() => this.setState({ selectedSites: 'WAITING' })} className={classes.circleOne}>
                <span style={{ fontWeight: 700 }}>Waiting</span>
              </div>
              <div onClick={() => this.setState({ selectedSites: 'CRAWLING' })} className={classes.circleTwo}>
                <span style={{ fontWeight: 700 }}>Crawling</span>
              </div>
              <div onClick={() => this.setState({ selectedSites: 'COMPLETED' })} className={classes.circleThree}>
                <span style={{ fontWeight: 700 }}>Completed</span>
              </div>
              <div onClick={() => this.setState({ selectedSites: 'Error' })} className={classes.circleFour}>
                <span style={{ fontWeight: 700 }}>Error</span>
              </div>
              <div onClick={() => this.setState({ selectedSites: 'ACTIVE' })} className={classes.circleFive}>
                <span style={{ fontWeight: 700 }}>Active</span>
              </div>
            </div> */}
            <div style={{ marginTop: 20 }}>
              <Table >
                <thead>
                  <tr>
                    <th>No.</th>
                    <th>{$('site_name')}</th>
                    <th>{$('language')}</th>
                    <th>{$('status')}</th>
                    <th></th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {Store.tenant?.sites.map((item, index) => {
                    return (
                      <tr>
                        <td>{index + 1}</td>
                        <td>{item.name}</td>
                        <td>{item.preferredLanguage}</td>
                        <td style={{ display: 'flex', textAlign: 'center' }}><span style={{ padding: 10, backgroundColor: `${item.status === 'COMPLETED' ? '#394172' : '#23c710'}`, color: '#FFFFFF', textAlign: 'center', borderRadius: 20 }}>{item.status === 'COMPLETED' ? 'READY FOR USE' : item.status}</span></td>
                        <td>
                            <Button className={classes.button} variant="contained" onClick={() => this.handleCrawl(item.urls[0])}  >
                               {$('crawl')}
                            </Button>
                        </td>
                        <td>
                          <Link to={`/site/${item.id}`}>
                            <Button className={classes.button} variant="contained" >
                              <span role="img" aria-label="rocket">🚀</span> {$('edit')}
                            </Button>
                          </Link>
                        </td>
                      </tr>
                    )
                  })}
                </tbody>
              </Table>
            </div>
            {/* {this.renderSiteList()} */}
            {/* <Description /> */}
          </div>
        </div>
      </DashboardLayout>
    )
  }
}

export default withStyles(Styles)(Sites)
