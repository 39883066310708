import classNames from 'classnames'
import DateFnsUtils from '@date-io/date-fns'
import React, { Component } from 'react'
import ReportStore from 'stores/report-store'
import styles from './styles'
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import { observer } from 'mobx-react'
import { Typography, withStyles, WithStyles, Button , } from '@material-ui/core'
import { GetText as $ } from 'stores/UITexts'
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date'
import moment from 'moment';


interface Props extends WithStyles<typeof styles> {
  className?: string
}

@observer
class TimeRangeSelector extends Component<Props,any> { 
   state = {isDateChanged:false}
  
   render() {
    const { classes, className, ...rest } = this.props
    const rootClassName = classNames(classes.root, className)
    return (
      <>
        <Typography variant="h5" className={classes.titleHead} style={{ justifySelf: 'flex-start' }}>
          {$('analysis.description')}{' '}
        </Typography>
        <div {...rest} className={rootClassName}>
          <Typography variant="h5" className={classes.title} style={{ justifySelf: 'flex-start' }}>
            {$('start_date')}:
          </Typography>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
              disableToolbar
              variant="dialog"
              format={navigator.language.startsWith('tr') ? 'dd/MM/yyyy' : 'MM/dd/yyyy'}
              margin="normal"
              id="start-date"
              InputProps={{
                style: {
                  fontSize: 14,
                  height: 44,
                  color: 'white',
                },
              }}
              value={ReportStore.startDate}
              onChange={(date: any) => {
                ReportStore.setStartDate(date);
                
              }}
              onAccept={(e)=>{this.setState({isDateChanged:true})}}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
              style={{ marginRight: 15 }}
            />
          </MuiPickersUtilsProvider>

          <Typography variant="h5" className={classes.title} style={{ justifySelf: 'flex-start' }}>
            {$('end_date')}:
          </Typography>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
              disableToolbar
              variant="dialog"
              format={navigator.language.startsWith('tr') ? 'dd/MM/yyyy' : 'MM/dd/yyyy'}
              margin="normal"
              id="end-date"
              InputProps={{
                style: {
                  fontSize: 14,
                  height: 44,
                  color: 'white',
                },
              }}
              value={ReportStore.endDate}
              onChange={(date: MaterialUiPickersDate) => {
                if (date != null) {
                  date.setHours(23, 59, 59, 999)
                  ReportStore.setEndDate(date)
                }
              }}
              onAccept={(e)=>{this.setState({isDateChanged:true})}}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
            />
          </MuiPickersUtilsProvider>

          <Button 
          style={ 
            (
              (this.state.isDateChanged === true) 
              && 
              (moment(ReportStore.startDate).diff(ReportStore.endDate) <= 0)
            ) 
            ? 
            {
              "backgroundColor": "rgba(57,65,114,1)",
              "color":"#ffffff",
              "textTransform":"capitalize",
              "marginLeft":"5px"
            }
             :  
            {
              "backgroundColor": "rgba(57,65,114,0.5)",
              "color":"rgba(255,255,255,0.5)",
              "textTransform":"capitalize",
              "marginLeft":"5px"
            }} 
            disabled={
              (
                (this.state.isDateChanged === true) 
                && 
                (moment(ReportStore.startDate).diff(ReportStore.endDate) <= 0)
              ) ? false : true }
            variant="contained" 
            onClick={()=>{
              if(ReportStore.context){
                ReportStore.refreshStats(); this.setState({isDateChanged:false})
              }else{ ReportStore.setContext(''); this.setState({isDateChanged:false})}
            }}>
              {$('timepicker_analyze')}
          </Button>
        </div>
      </>
    )
  }
}

export default withStyles(styles)(TimeRangeSelector)
