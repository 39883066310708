import classNames from 'classnames'
import React, { Component } from 'react'
import Store from 'stores/store'
import Styles from './styles'
import {
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  Typography
  } from '@material-ui/core'
import { GetText as $ } from 'stores/UITexts'
import { Link, NavLink, NavLinkProps } from 'react-router-dom'
import { observer } from 'mobx-react'
import { withStyles, WithStyles } from '@material-ui/core'
import {
  AccountCircle as AccountIcon,
  DashboardOutlined as DashboardIcon,
  DeveloperBoardOutlined as DeveloperBoardIcon,
  ExitToApp
} from '@material-ui/icons'

const SideBarLink = React.forwardRef<HTMLAnchorElement, NavLinkProps>((props, ref) => <NavLink innerRef={ref as any} {...props} />)

interface Props extends WithStyles<typeof Styles> {
  className: string
}

@observer
class Sidebar extends Component<Props> {

  handleSignOut = async () => {
    await Store.signOut()
    window.location.href = '/signin'
  }

  render() {
    const { classes, className } = this.props
    const rootClassName = classNames(classes.root, className)

    if (!Store.user) {
      return (
        <nav className={rootClassName}>
          <div className={classes.logoWrapper}>
            <Link className={classes.logoLink} to="/dashboard">
              <img alt="logo" className={classes.logoImage} src="/images/logo.png" />
            </Link>
          </div>
          <Divider className={classes.logoDivider} />
        </nav>
      )
    }

    return (
      <nav className={rootClassName}>
        <div className={classes.logoWrapper}>
          <Link className={classes.logoLink} to="/dashboard">
            <img alt="logo" className={classes.logoImage} src="/images/logo.png" />
          </Link>
        </div>
        <Divider className={classes.logoDivider} />
        <div className={classes.profile}>
          <Typography className={classes.nameText} variant="h6">
            {`${Store.tenant?.contactPerson.firstName} ${Store.tenant?.contactPerson.lastName}`}
          </Typography>
          <Typography className={classes.bioText} variant="caption">
           {Store.tenant?.name}
          </Typography>
        </div>
        <Divider className={classes.profileDivider} />
        {Store.user.systemAdmin && (
          <React.Fragment>
            <List
              component="div"
              disablePadding
              subheader={<ListSubheader className={classes.listSubheader}>{$('__sidebar.system_admin__')}</ListSubheader>}
            >
              <ListItem activeClassName={classes.activeListItem} className={classes.listItem} component={SideBarLink} to="/tenants">
                <ListItemIcon className={classes.listItemIcon}>
                  <DashboardIcon />
                </ListItemIcon>
                <ListItemText classes={{ primary: classes.listItemText }} primary={$('__sidebar.tenants__')} />
              </ListItem>
              <ListItem activeClassName={classes.activeListItem} className={classes.listItem} component={SideBarLink} to="/prices">
                <ListItemIcon className={classes.listItemIcon}>
                  <DeveloperBoardIcon />
                </ListItemIcon>
                <ListItemText classes={{ primary: classes.listItemText }} primary={$('__sidebar.prices__')} />
              </ListItem>
            </List>
            <Divider className={classes.listDivider} />
          </React.Fragment>
        )}
        <List component="div" disablePadding>
        <ListItem activeClassName={classes.activeListItemThree} className={classes.listItemThree} component={SideBarLink} to="/entegration">
            <ListItemIcon className={classes.listItemIcon}>
              <AccountIcon />
            </ListItemIcon>
            <ListItemText classes={{ primary: classes.listItemText }} primary={$('sidebar.entegrated')} />
          </ListItem>
          <ListItem activeClassName={classes.activeListItem} className={classes.listItem} component={SideBarLink} to="/dashboard">
            <ListItemIcon className={classes.listItemIcon}>
              <DashboardIcon />
            </ListItemIcon>
            <ListItemText classes={{ primary: classes.listItemText }} primary={$('sidebar.dashboard')} />
          </ListItem>
          <ListItem activeClassName={classes.activeListItemTwo} className={classes.listItemTwo} component={SideBarLink} to="/sites">
            <ListItemIcon className={classes.listItemIcon}>
              <DeveloperBoardIcon />
            </ListItemIcon>
            <ListItemText classes={{ primary: classes.listItemText }} primary={$('sidebar.websites')} />
          </ListItem>
          <ListItem activeClassName={classes.activeListItemThree} className={classes.listItemThree} component={SideBarLink} to="/account">
            <ListItemIcon className={classes.listItemIcon}>
              <AccountIcon />
            </ListItemIcon>
            <ListItemText classes={{ primary: classes.listItemText }} primary={$('sidebar.account')} />
          </ListItem>
        </List>
        <Divider className={classes.listDivider} />
        {/* <List component="div" disablePadding subheader={<ListSubheader className={classes.listSubheader}>{$('sidebar.support')}</ListSubheader>}> */}
          {/* <ListItem
            className={classes.listItem}
            // component="a"
            component={SideBarLink} 
            to="/contact-us"
            // href={`https://www.cookieseal.com${locale === 'tr' ? '/#iletisim' : '/en/#contact'}`}
          >
            <ListItemIcon className={classes.listItemIcon}>
              <InfoIcon />
            </ListItemIcon>
            <ListItemText classes={{ primary: classes.listItemText }} primary={$('sidebar.customer_support')} />
          </ListItem> */}
        {/* </List> */}
        <div className={classes.footer}>
        <ListItem
            className={classes.listItem}
            component={SideBarLink}
            to=""
            onClick={() => this.handleSignOut()}
          >
            <ListItemIcon className={classes.listItemIcon}>
              <ExitToApp />
            </ListItemIcon>
            <ListItemText classes={{ primary: classes.listItemText }} primary={$('sidebar.log_out')} />
          </ListItem>
        </div>
      </nav>
      
    )
  }
}

export default withStyles(Styles)(Sidebar)
