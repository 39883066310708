import React from 'react'
import classNames from 'classnames'
import { createStyles, withStyles, WithStyles } from '@material-ui/core'
import { Typography } from '@material-ui/core'

const styles = (theme: any) =>
  createStyles({
    root: {
      display: 'flex',
      alignItems: 'center',
    },
    icon: {
      fontSize: '1.3rem',
      marginRight: theme.spacing(),
      color: theme.palette.text.secondary,
      alignItems: 'center',
      display: 'flex',
    },
    title: {
      fontWeight: 500,
    },
    subtitle: {
      fontWeight: 400,
      marginLeft: theme.spacing(),
      color: theme.palette.text.secondary,
    },
  })

interface Props extends WithStyles<typeof styles> {
  children?: React.ReactNode
  className?: string
  icon?: React.ReactNode
  subtitle?: string
  title?: string
}

const PortletLabel = (props: Props) => {
  const { classes, className, icon, title, subtitle, ...rest } = props

  const rootClassName = classNames(classes.root, className)

  return (
    <div {...rest} className={rootClassName}>
      {icon && <span className={classes.icon}>{icon}</span>}
      {title && (
        <Typography className={classes.title} variant="h5">
          {title}
        </Typography>
      )}
      {subtitle && (
        <Typography className={classes.subtitle} variant="subtitle2">
          {subtitle}
        </Typography>
      )}
    </div>
  )
}

export default withStyles(styles)(PortletLabel)
