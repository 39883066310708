import React, { Component } from 'react'

// Externals
import classNames from 'classnames'

// Material helpers
import { withStyles, createStyles, WithStyles, Theme } from '@material-ui/core'

// Material components
import { Divider, Typography } from '@material-ui/core'

// Component styles
const Styles = (theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(4),
    },
    company: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(0.5),
    },
  })

interface Props extends WithStyles<typeof Styles> {
  className?: string
}

class Footer extends Component<Props> {
  render() {
    const { classes, className } = this.props

    const rootClassName = classNames(classes.root, className)

    return (
      <div className={rootClassName}>
        <Divider />
        <Typography className={classes.company} variant="body1">
          &copy; Doğuş Teknoloji 2019
        </Typography>
        <Typography variant="caption">
          A part of Doğuş Teknoloji GDPR Suite.
        </Typography>
      </div>
    )
  }
}

export default withStyles(Styles)(Footer)
