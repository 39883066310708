import { createStyles, Theme } from '@material-ui/core/styles'

export default (theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing() * 3,
    },
    content: {
      alignItems: 'center',
      display: 'flex',
    },
    details: {},
    title: {
      color: theme.palette.text.secondary,
      fontWeight: 700,
    },
    value: {
      marginTop: theme.spacing(),
    },
    iconWrapper: {
      alignItems: 'center',
      borderRadius: '50%',
      display: 'inline-flex',
      height: '4rem',
      justifyContent: 'center',
      marginLeft: 'auto',
      width: '4rem',
    },
    icon: {
      color: theme.palette.common.white,
      fontSize: '2rem',
      height: '4rem',
      width: '4rem',
    },
    footer: {
      marginTop: theme.spacing() * 2,
      display: 'flex',
      alignItems: 'center',
    },
    upDifference: {
      alignItems: 'center',
      color: (theme.palette as any).success.dark,
      display: 'inline-flex',
      fontWeight: 700,
    },
    downDifference: {
      alignItems: 'center',
      color: (theme.palette as any).danger.dark,
      display: 'inline-flex',
      fontWeight: 700,
    },
    caption: {
      marginLeft: theme.spacing(),
    },
  })
