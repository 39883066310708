import React, { useState } from 'react'
import { BannerToolContext } from '../BannerCustomization'
import { BlockPicker } from 'react-color'
import FrameComponent from '../FrameComponent'
const ButtonColor = () => {
  const value = React.useContext(BannerToolContext)
  const [buttonColor, SetButtonColor] = useState(value.form3.value ? value.form3.value : '#ff8724')
  const handleChange = color => {
    SetButtonColor(color.hex)
    value.form3.value = color.hex
  }
  return (
    <div style={{display:'flex', flexDirection:'row'}}>
      <div  style={{display:'flex', flexDirection:'column' ,width:'25%', alignItems: 'center'}}>
        <div
        style={{fontWeight:'400', fontFamily:'Quicksand' ,fontSize:'20px', marginBottom: '16px', color:'#383F73',textAlign:'center' }}
          >
          Lütfen button rengini seçiniz.
        </div>
        <div>
          <BlockPicker
            width="250px"
            style={{ boxShadow: '0px 0px 20px 1px rgba(0, 0, 0, 0.05)', borderRadius: '20px' }}
            color={buttonColor}
            onChange={handleChange}
            triangle="hide"
          />
        </div>
      </div>
      <div style={{display:'flex', justifyContent:'center', width:'75%', boxShadow:'0px 0px 20px 1px #0000000D'}}>
        <FrameComponent />
      </div>
    </div>
  )
}

export default ButtonColor
