// Palette
import palette from '../palette'

export default {
  root: {
    padding: '14px 40px 14px 24px',
  },
  body: {
    // borderBottom: `1px solid ${palette.divider}`,
  },
  head: {
    fontSize: '16px',
    color: palette.text.primary,
  },
}
