import _ from 'lodash'
import countries from 'assets/countries'
import PackageSelector from 'components/package-selector'
import PaymentPlanStore, { PaymentPeriod } from 'stores/payment-plan-store'
import React, { Component } from 'react'
import Schema, { Rules } from 'async-validator'
import Store from 'stores/store'
import Styles from './styles'
import { AgreementViewer } from 'components/agreement-viewer'
import { GetText as $ } from 'stores/UITexts'
import { observable } from 'mobx'
import { observer } from 'mobx-react'
import { PaymentPlan } from 'stores/models'
import { Select } from 'components'
import 'utils/validators'
import {
  AppBar,
  Button,
  Container,
  Divider,
  TextField,
  Typography,
  useScrollTrigger,
  WithStyles,
  withStyles,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  FormControlLabel,
  Checkbox,
  Link,
} from '@material-ui/core'

const months = _.range(1, 13).map(i => ({ key: i + '', value: _.padStart(i + '', 2, '0') }))
const years = _.range(new Date().getFullYear(), new Date().getFullYear() + 10).map(i => ({
  key: i + '',
  value: i + '',
}))
const locale = navigator.language.startsWith('tr') ? 'tr' : 'en'

interface Props extends WithStyles<typeof Styles> {
  children: React.ReactElement
  history: any
}

function ElevationScroll(props: Props) {
  const { children } = props
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
  })

  return React.cloneElement(children, {
    elevation: trigger ? 4 : 0,
  })
}

@observer
class SignUp extends Component<Props> {
  @observable paymentPeriod = PaymentPeriod.Monthly
  @observable packageLocale: keyof typeof PaymentPlanStore.defaultPrices = locale === 'tr' ? 'tr' : 'eur'
  @observable price = 0

  @observable values: { [key: string]: string | number | boolean } = {
    siteName: '',
    siteUrl: '',
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    passwordCheck: '',
    companyName: '',
    taxOffice: '',
    taxNumber: '',
    country: 'TR',
    city: '',
    address: '',
    zipCode: '',
    cardHolderName: '',
    cardNumber: '',
    expireMonth: 1,
    expireYear: new Date().getFullYear(),
    cvc: '',
    agreements: false,
  }

  @observable touched: { [key: string]: boolean } = {
    siteName: false,
    siteUrl: false,
    firstName: false,
    lastName: false,
    email: false,
    password: false,
    passwordCheck: false,
    companyName: false,
    taxOffice: false,
    taxNumber: false,
    country: false,
    city: false,
    address: false,
    zipCode: false,
    cardHolderName: false,
    cardNumber: false,
    expireMonth: false,
    expireYear: false,
    cvc: false,
    agreements: false,
  }

  @observable errors: { [key: string]: string[] } = {
    siteName: [],
    siteUrl: [],
    firstName: [],
    lastName: [],
    email: [],
    password: [],
    passwordCheck: [],
    companyName: [],
    taxOffice: [],
    taxNumber: [],
    country: [],
    city: [],
    address: [],
    zipCode: [],
    cardHolderName: [],
    cardNumber: [],
    expireMonth: [],
    expireYear: [],
    cvc: [],
    agreements: [],
  }

  @observable isValid = false
  @observable error = ''
  @observable transactionInProgress = false
  @observable purchaseSuccessful = false
  @observable dialog = {
    open: false,
    title: '',
    content: '',
  }

  @observable agreementViewer = {
    title: 'Kullanım Sözleşmesi',
    agreementName: '01_en.html',
    open: false,
    data: {},
  }

  validationDescriptor: Rules = {
    siteName: { type: 'string', required: true },
    siteUrl: [
      { type: 'url', required: true },
      {
        asyncValidator: (rule, value) => {
          return new Promise(async (resolve, reject) => {
            const exists = await Store.checkSiteUrl(value)
            if (exists) {
              return reject($('site_address_exists'))
            }
            resolve()
          })
        },
      },
    ],
    firstName: { type: 'string', required: true },
    lastName: { type: 'string', required: true },
    email: [
      { type: 'email', required: true },
      {
        asyncValidator: (rule, value) => {
          return new Promise(async (resolve, reject) => {
            const exists = await Store.checkEmail(value)
            if (exists) {
              return reject($('email_address_in_use'))
            }
            resolve()
          })
        },
      },
    ],
    password: [
      { type: 'string', required: true },
      {
        validator: (rule, value) => {
          return /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/.test(value)
        },
      },
    ],
    passwordCheck: [
      { type: 'string', required: true },
      {
        validator: (rule, value) =>
          !_.isEmpty(this.values.password) && !_.isEmpty(this.values.passwordCheck) && this.values.password === this.values.passwordCheck,
        message: $('passwords_do_not_match'),
      },
    ],
    companyName: { type: 'string', required: true },
    taxOffice: { type: 'string', required: true },
    taxNumber: { type: 'string', required: true },
    country: { type: 'string', required: true },
    city: { type: 'string', required: true },
    address: { type: 'string', required: true },
    zipCode: { type: 'string', required: true, len: 5 },
    cardHolderName: { type: 'string', required: true },
    cardNumber: { type: 'string', required: true },
    expireMonth: { type: 'number', required: true },
    expireYear: { type: 'number', required: true },
    cvc: { type: 'number', required: true },
    agreements: { type: 'boolean', required: true },
  }

  validator = new Schema(this.validationDescriptor)

  validateForm = _.debounce(() => {
    this.validator.validate(this.values, {}, (errors, fields) => {
      this.errors = {}
      if (fields) {
        Object.keys(fields).forEach(f => {
          this.errors[f] = fields[f].map(e => e.message)
        })
      }
      this.isValid = errors === null
    })
  }, 300)

  handleFieldChange = (field: string, value: any) => {
    this.error = ''
    this.touched[field] = true
    this.values[field] = value
    this.validateForm()
  }

  checkError(field: string) {
    return this.touched[field] && this.errors[field] && this.errors[field].length > 0
  }

  getError(field: string, label: string) {
    return this.checkError(field) ? this.errors[field][0] : label
  }

  componentDidMount() {
    PaymentPlanStore.load().then(() => {
      const selectedPeriod = _.padStart((this.paymentPeriod === PaymentPeriod.Monthly ? 1 : 12) + '', 2, '0')
      // const currencyCode = locale === 'tr' ? 'TRY' : 'EUR'
      const currencyCode = 'TRY'
      const paymentPlan: PaymentPlan = PaymentPlanStore.items.find(i => i.name === `${currencyCode}${selectedPeriod}_2024`)!
      this.price = paymentPlan.price
    })
  }

  paymentOptionChanged = (period: PaymentPeriod) => {
    this.paymentPeriod = period
  }

  async register() {
    this.transactionInProgress = true

    const period = _.padStart((this.paymentPeriod === PaymentPeriod.Monthly ? 1 : 12) + '', 2, '0')
    // const currencyCode = locale === 'tr' ? 'TRY' : 'EUR'
    const currencyCode = 'TRY'
    const paymentPlan: PaymentPlan = PaymentPlanStore.items.find(i => i.name === `${currencyCode}${period}_2024`)!

    const params = Object.assign(
      {
        scriptPaymentPlanRefCode: paymentPlan.referenceCode,
        locale: locale,
      },
      this.values
    )
    delete params.passwordCheck
    delete params.agreements

    const result = await Store.signUp(params)
    if (!result.success) {
      this.dialog = {
        open: true,
        title: $('error_processing_transaction'),
        content: result.message,
      }
      this.purchaseSuccessful = false
    } else {
      this.dialog = {
        open: true,
        title: $('success'),
        content: $('purchase_successful._click_ok_to_continue_to_sign_in_page.'),
      }
      this.purchaseSuccessful = true
    }
    this.transactionInProgress = false
  }

  closeDialog() {
    const { history } = this.props
    if (this.purchaseSuccessful) {
      history.push('/signin')
    }
    this.dialog = {
      open: false,
      title: '',
      content: '',
    }
  }

  renderRightCol() {
    const { classes } = this.props

    return locale === 'en' ? (
      <React.Fragment>
        <Typography variant="h4" className={classes.title}>
          Get Started Free!
        </Typography>
        <Typography variant="h5" className={classes.adText}>
          Use all the features of Cookie Seal for 14 days free of charge.
        </Typography>
        <Typography variant="h5" className={classes.adText}>
          Make your website compatible with Data Protection Laws with easy setup.
        </Typography>
        <Typography variant="h5" className={classes.adText}>
          With Cookie Seal:
        </Typography>
        <Typography variant="h5" className={classes.adBulletText}>
          º Classify the cookies on your website by scanning them
        </Typography>
        <Typography variant="h5" className={classes.adBulletText}>
          º Customize the content of the Cookie Preference Screen as you like
        </Typography>
        <Typography variant="h5" className={classes.adBulletText}>
          º Access detailed consent reports via the dashboard
        </Typography>
      </React.Fragment>
    ) : (
      <React.Fragment>
        <Typography variant="h4" className={classes.title}>
          Ücretsiz Kullanmaya Başlayın!
        </Typography>
        <Typography variant="h5" className={classes.adText}>
          Cookie Seal’ın tüm özelliklerini 14 gün boyunca ücretsiz kullanın.
        </Typography>
        <Typography variant="h5" className={classes.adText}>
          Kolay Kurulumu ile Web sitenizi Veri Koruma Kanunlarına uyumlu hale getirin.
        </Typography>
        <Typography variant="h5" className={classes.adText}>
          Cookie Seal ile:
        </Typography>
        <Typography variant="h5" className={classes.adBulletText}>
          º Web sitenizdeki Cookie’leri tarayarak sınıflandırın
        </Typography>
        <Typography variant="h5" className={classes.adBulletText}>
          º Cookie Tercih Ekranı içeriğini kendinize göre özelleştirin
        </Typography>
        <Typography variant="h5" className={classes.adBulletText}>
          º Panel üzerinden detaylı raporlara erişin
        </Typography>
      </React.Fragment>
    )
  }

  showAgreement(title: string, name: string) {
    let period
    if (this.paymentPeriod === PaymentPeriod.Monthly) {
      if (locale === 'tr') {
        period = 'AYLIK'
      } else {
        period = 'MONTHLY'
      }
    } else {
      if (locale === 'tr') {
        period = 'YILLIK'
      } else {
        period = 'YEARLY'
      }
    }
    const data = {
      customerName: this.values.firstName + ' ' + this.values.lastName,
      address: `${this.values.address} ${this.values.city} / ${this.values.country}`,
      email: this.values.email,
      dateSigned: new Date().toLocaleDateString(),
      productName: `CookiSeal ${this.paymentPeriod === PaymentPeriod.Yearly ? 'Yearly Subsciption' : 'Monthly Subsciption'}`,
      period: period,
      price: this.price,
    }
    this.agreementViewer = { title: title, agreementName: name, open: true, data: data }
  }

  render() {
    const { classes } = this.props

    const label =
      locale === 'tr' ? (
        <span>
          {$('signup.accept')}: &nbsp;
          <Link component="button" style={{ fontSize: 14, paddingBottom: 3 }} onClick={() => this.showAgreement('ÖN BİLGİLENDİRME FORMU', '01_tr')}>
            Ön Bilgilendirme Formu
          </Link>
          &nbsp;&&nbsp;
          <Link
            component="button"
            style={{ fontSize: 14, paddingBottom: 3 }}
            onClick={() => this.showAgreement('Mesafeli Kullanım ve Üyelik Sözleşmesi', '02_en')}
          >
            Mesafeli Kullanım ve Üyelik Sözleşmesi
          </Link>
        </span>
      ) : (
        <span>
          I have read and agree to the&nbsp;
          <Link component="button" style={{ fontSize: 14, paddingBottom: 3 }} onClick={() => this.showAgreement('ÖN BİLGİLENDİRME FORMU', '01_en')}>
            Terms and Conditions
          </Link>
          &nbsp;and&nbsp;
          <Link
            component="button"
            style={{ fontSize: 14, paddingBottom: 3 }}
            onClick={() => this.showAgreement('Mesafeli Kullanım ve Üyelik Sözleşmesi', '02_en')}
          >
            Sales Agreement
          </Link>
        </span>
      )

    return (
      <React.Fragment>
        <ElevationScroll {...this.props}>
          <AppBar className={classes.appbar}>
            <Container maxWidth="lg" className={classes.headerContainer}>
              <img alt="logo" src="/images/logo.png" />
              <div>
                <Button variant="outlined" color="primary" href="https://www.cookieseal.com">
                  {$('return_to_site')}
                </Button>
              </div>
            </Container>
          </AppBar>
        </ElevationScroll>
        {/* <Toolbar />
        <Section />
        <Brands />
        <StepperComponent />
        <Team /> */}
        {/* <Footer /> */}
        <Container className={classes.content}>
          <div className={classes.leftColumn}>
            <Typography variant="h3" className={classes.title}>
              {$('choose_your_package')}
            </Typography>
            {!PaymentPlanStore.loading && (
              <PackageSelector
                prices={PaymentPlanStore.defaultPrices[this.packageLocale]}
                activeIndex={this.paymentPeriod === 'MONTHLY' ? 0 : 1}
                onChanged={period => this.paymentOptionChanged(period)}
              />
            )}

            <Divider className={classes.divider} />
            <Typography variant="h3" className={classes.title}>
              {$('site_information')}
            </Typography>

            <div className={classes.column}>
              <TextField
                name="siteFrom"
                error={this.checkError('siteOwner')}
                // label={this.getError('siteOwner', $('site_owner'))}
                variant="outlined"
                disabled
                value={'TSoft'}
                fullWidth
                className={classes.field}
                onChange={e => this.handleFieldChange('siteOwner', e.target.value)}
              />
            </div>
            <div className={classes.formContainer}>
              <div className={classes.column}>
                <TextField
                  name="siteName"
                  error={this.checkError('siteName')}
                  label={this.getError('siteName', $('site_name'))}
                  variant="outlined"
                  fullWidth
                  className={classes.field}
                  onChange={e => this.handleFieldChange('siteName', e.target.value)}
                />
              </div>
              <div className={classes.column}>
                <TextField
                  name="siteUrl"
                  error={this.checkError('siteUrl')}
                  label={this.getError('siteUrl', $('site_url'))}
                  variant="outlined"
                  fullWidth
                  className={classes.field}
                  onChange={e => this.handleFieldChange('siteUrl', e.target.value)}
                />
              </div>
            </div>

            <Divider className={classes.divider} />
            <Typography variant="h3" className={classes.title}>
              {$('user_information')}
            </Typography>
            <div className={classes.formContainer}>
              <div className={classes.column}>
                <TextField
                  name="firstName"
                  error={this.checkError('firstName')}
                  label={this.getError('firstName', $('first_name'))}
                  variant="outlined"
                  fullWidth
                  className={classes.field}
                  onChange={e => this.handleFieldChange('firstName', e.target.value)}
                />
              </div>
              <div className={classes.column}>
                <TextField
                  name="lastName"
                  error={this.checkError('lastName')}
                  label={this.getError('lastName', $('last_name'))}
                  variant="outlined"
                  fullWidth
                  className={classes.field}
                  onChange={e => this.handleFieldChange('lastName', e.target.value)}
                />
              </div>
            </div>

            <Divider className={classes.divider} />
            <Typography variant="h3" className={classes.title}>
              {$('account_information')}
            </Typography>
            <div className={classes.formContainer}>
              <div className={classes.column}>
                <TextField
                  name="email"
                  error={this.checkError('email')}
                  label={this.getError('email', $('email'))}
                  variant="outlined"
                  fullWidth
                  className={classes.field}
                  onChange={e => this.handleFieldChange('email', e.target.value)}
                />
              </div>
              <div className={classes.column}>
                <TextField
                  name="password"
                  error={this.checkError('password')}
                  label={this.getError('password', $('password'))}
                  type="password"
                  variant="outlined"
                  fullWidth
                  className={classes.field}
                  onChange={e => this.handleFieldChange('password', e.target.value)}
                />
                <TextField
                  name="passwordCheck"
                  error={this.checkError('passwordCheck')}
                  label={this.getError('passwordCheck', $('re-type_password'))}
                  type="password"
                  variant="outlined"
                  fullWidth
                  className={classes.field}
                  onChange={e => this.handleFieldChange('passwordCheck', e.target.value)}
                />
              </div>
            </div>

            <Divider className={classes.divider} />
            <Typography variant="h3" className={classes.title}>
              {$('company_information')}
            </Typography>
            <div className={classes.formContainer}>
              <div className={classes.column}>
                <TextField
                  name="companyName"
                  error={this.checkError('companyName')}
                  label={this.getError('companyName', $('company_name'))}
                  variant="outlined"
                  fullWidth
                  className={classes.field}
                  onChange={e => this.handleFieldChange('companyName', e.target.value)}
                />
                <TextField
                  name="taxOffice"
                  error={this.checkError('taxOffice')}
                  label={this.getError('taxOffice', $('tax_office'))}
                  variant="outlined"
                  fullWidth
                  className={classes.field}
                  onChange={e => this.handleFieldChange('taxOffice', e.target.value)}
                />
                <TextField
                  name="taxNumber"
                  error={this.checkError('taxNumber')}
                  label={this.getError('taxNumber', $('tax_number'))}
                  variant="outlined"
                  fullWidth
                  className={classes.field}
                  onChange={e => this.handleFieldChange('taxNumber', e.target.value)}
                />
              </div>
              <div className={classes.column}>
                <Select
                  label={this.getError('country', $('country'))}
                  value={this.values.country as string}
                  items={Object.keys(countries)
                    .sort()
                    .map(c => ({ key: c, value: countries[c] }))}
                  onChange={({ value }) => (this.values.country = value)}
                />
                <TextField
                  name="city"
                  error={this.checkError('city')}
                  label={this.getError('city', $('city'))}
                  variant="outlined"
                  fullWidth
                  className={classes.field}
                  onChange={e => this.handleFieldChange('city', e.target.value)}
                />
                <TextField
                  name="address"
                  error={this.checkError('address')}
                  label={this.getError('address', $('street_address'))}
                  variant="outlined"
                  fullWidth
                  className={classes.field}
                  onChange={e => this.handleFieldChange('address', e.target.value)}
                />
                <TextField
                  name="zipCode"
                  error={this.checkError('zipCode')}
                  label={this.getError('zipCode', $('zip_code'))}
                  variant="outlined"
                  fullWidth
                  className={classes.field}
                  onChange={e => this.handleFieldChange('zipCode', e.target.value)}
                />
              </div>
            </div>
            <Typography variant="h3" className={classes.title}>
              {$('credit_card_information')}
            </Typography>
            <div className={classes.formContainer}>
              <div className={classes.column}>
                <TextField
                  name="cardHolderName"
                  error={this.checkError('cardHolderName')}
                  label={this.getError('cardHolderName', $('signup.name_on_card'))}
                  variant="outlined"
                  fullWidth
                  className={classes.field}
                  onChange={e => this.handleFieldChange('cardHolderName', e.target.value)}
                />
                <TextField
                  name="cardNumber"
                  error={this.checkError('cardNumber')}
                  label={this.getError('cardNumber', $('card_number'))}
                  variant="outlined"
                  fullWidth
                  className={classes.field}
                  onChange={e => this.handleFieldChange('cardNumber', e.target.value)}
                />
              </div>
              <div className={classes.column}>
                <div className={classes.spaceBetweenColumn}>
                  <Select
                    label={this.getError('expireMonth', $('expire_month'))}
                    error={this.checkError('expireMonth')}
                    items={months}
                    className={classes.expireDateSelector}
                    onChange={({ value }) => (this.values.expireMonth = Number(value))}
                  />
                  <Select
                    label={this.getError('expireYear', $('expire_year'))}
                    error={this.checkError('expireYear')}
                    items={years}
                    className={classes.expireDateSelector}
                    onChange={({ value }) => (this.values.expireYear = Number(value))}
                  />
                </div>
                <div className={classes.expireDateSelector}>
                  <TextField
                    name="cvc"
                    error={this.checkError('cvc')}
                    label={this.getError('cvc', 'CVC')}
                    variant="outlined"
                    className={classes.field}
                    onChange={e => this.handleFieldChange('cvc', Number(e.target.value))}
                  />
                </div>
              </div>
            </div>
            <div className={classes.formContainer}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={this.values.agreements as boolean}
                    onChange={() => {
                      this.values.agreements = !this.values.agreements
                    }}
                  />
                }
                label={label}
              />
            </div>
            <Button
              disabled={!this.isValid || this.transactionInProgress}
              variant="contained"
              color="primary"
              size="large"
              fullWidth
              onClick={() => this.register()}
            >
              {$('register')}
            </Button>
          </div>
          <div className={classes.rightColumn}>{this.renderRightCol()}</div>
        </Container>
        <AgreementViewer
          title={this.agreementViewer.title}
          agreementName={this.agreementViewer.agreementName}
          isOpen={this.agreementViewer.open}
          data={this.agreementViewer.data}
          onClose={() => (this.agreementViewer.open = false)}
        />
        <Dialog open={this.dialog.open} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
          <DialogTitle id="alert-dialog-title">{this.dialog.title}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">{this.dialog.content}</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => this.closeDialog()} color="primary">
              {$('ok')}
            </Button>
          </DialogActions>
        </Dialog>
      </React.Fragment>
    )
  }
}

export default withStyles(Styles)(SignUp)
