import { createStyles, Theme } from '@material-ui/core/styles'

export default (theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing() * 4,
    },
    content: {
      marginTop: theme.spacing() * 4,
    },
    progressWrapper: {
      paddingTop: '48px',
      paddingBottom: '24px',
      display: 'flex',
      justifyContent: 'center',
    },
    centerDiv: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      marginTop: '3%'
    },
    circleOne: {
      width: 150,
      borderRadius: 18,
      textAlign: 'center',
      background: "radial-gradient(#60efbc, #58d5c9)",
      color: '#ffffff',
      alignItems: 'center',
      cursor: 'pointer',
      padding: 20,
      margin: 10,
      opacity: 0.7
    },
    circleTwo: {
      width: 150,
      borderRadius: 18,
      textAlign: 'center',
      background: "gray",
      color: '#ffffff',
      alignItems: 'center',
      padding: 20,
      cursor: 'pointer',
      margin: 10,
      opacity: 0.7

    },
    circleThree: {
      width: 150,
      textAlign: 'center',
      borderRadius: 18,
      background: "#23c710",
      color: '#ffffff',
      cursor: 'pointer',
      alignItems: 'center',
      padding: 20,
      margin: 10,
      opacity: 0.7

    },
    circleFour: {
      width: 150,
      textAlign: 'center',
      borderRadius: 18,
      background: "#ce0000",
      color: '#ffffff',
      alignItems: 'center',
      cursor: 'pointer',
      padding: 20,
      margin: 10,
      opacity: 0.7

    },
    tableHead:{
      marginTop:20,
    },
    button: {
      backgroundColor: '#FFFFFF',
      borderColor: '#394172',
      color: '#394172'
    },
    circleFive: {
      width: 150,
      textAlign: 'center',
      borderRadius: 18,
      background: "radial-gradient(#1fe4f5, #3fbafe)",
      color: '#ffffff',
      cursor: 'pointer',
      alignItems: 'center',
      padding: 20,
      margin: 10,
      opacity: 0.7

    },
    titleHead: {
      marginRight: 20,
      marginLeft: 5,
      marginBottom: 5,
    },
    circleSix: {
      width: 150,
      textAlign: 'center',
      borderRadius: 18,
      color: '#ffffff',
      cursor: 'pointer',
      alignItems: 'center',
      padding: 20,
      margin: 10,
      background: '#394172',
      opacity: 0.7

    }

  })
