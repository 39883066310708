import { createStyles, Theme } from '@material-ui/core/styles'

export default (theme: Theme) =>
  createStyles({
    root: {
      padding: 30,
      minWidth: 200,
      margin: 5,
      minHeight: 120,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      cursor: 'pointer'
    },
    imageWrapper: {
      height: '64px',
      width: '64px',
      margin: '0 auto',
      overflow: 'hidden',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    image: {
      width: '100%',
    },
    details: {},
    title: {
      textAlign: 'center',
      marginTop: theme.spacing() * 2,
    },
  })
