import _ from 'lodash'
import DefaultUITexts, { GetDefaultUIText, UITextItem } from 'stores/UITexts'
import languages from 'assets/languages'
import React, { Component } from 'react'
import {
  createStyles,
  MenuItem,
  Select,
  Theme,
  withStyles,
  WithStyles
} from '@material-ui/core'
import { GetText as $ } from 'stores/UITexts'
import { observable } from 'mobx'
import { observer } from 'mobx-react'
import { Portlet, PortletContent, PortletHeader, PortletLabel } from 'components'
import { PublicOutlined } from '@material-ui/icons'
import { Input } from "reactstrap";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      marginTop: theme.spacing() * 5,
      paddingLeft: 10,
      paddingRight: 10,
      borderWidth: 1,
      borderStyle: 'solid',
      borderColor: '#001e50',
      maxWidth: '100%',
      borderRadius: 18,
      backgroundColor: '#fff',
    },
    formContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems:'center'
    },
    column: {
      width: '50%',
      padding:10,
      display: 'flex'
    },
    field: {
      marginBottom: theme.spacing() * 3,
      borderRadius:50
    },

  })

interface Props extends WithStyles<typeof styles> {
  site: any
}

@observer
class Texts extends Component<Props> {
  @observable selectedLanguage!: string

  constructor(props: Props) {
    super(props)

    const { site } = this.props

    this.selectedLanguage = site.preferredLanguage || 'en'

    if (!site.uiTexts) {
      site.uiTexts = {}
    }
  }

  getItemValueFromSite(item: UITextItem) {
    const { site } = this.props

    if (item.group === 'BannerText') {
      const defaultValue = GetDefaultUIText(item.key, this.selectedLanguage)

      if (!_.has(site.uiTexts, item.key)) {
        site.uiTexts[item.key] = {}
      }

      if (!_.has(site.uiTexts[item.key], this.selectedLanguage)) {
        _.set(site.uiTexts[item.key], `${this.selectedLanguage}`, defaultValue)
      }

      const value = _.get(site.uiTexts[item.key], `${this.selectedLanguage}`)
      return value
    }

    if (item.group === 'CookieGroups') {
      const groupProps = item.key.split('.')
      const defaultValue = GetDefaultUIText(item.key, this.selectedLanguage)
      const cookieGroup = site.cookieGroups!.find((g: any) => g.id === groupProps[0])
      if (cookieGroup) {
        const value = _.get(cookieGroup, `${groupProps[1]}.${this.selectedLanguage}`) || defaultValue
        return value
      }
      return defaultValue
    }
  }

  setSiteValueFromItem(item: UITextItem, value: string) {
    const { site } = this.props
    if (item.group === 'BannerText') {
      const textItem = site.uiTexts[item.key]
      _.set(textItem, `${this.selectedLanguage}`, value)
      console.log(textItem);
    }

    if (item.group === 'CookieGroups') {
      const groupProps = item.key.split('.')
      const cookieGroup = site.cookieGroups!.find((g: any) => g.id === groupProps[0])
      if (cookieGroup) {
        _.set(cookieGroup, `${groupProps[1]}.${this.selectedLanguage}`, value)
      }
    }
  }

  render() {
    const { classes, site } = this.props

    const items = DefaultUITexts.filter(t => t.group === 'BannerText' || t.group === 'CookieGroups')
    const rows = _.chunk(items, 2)

    return (
      <>
      {/* <WebSiteCard /> */}
      <Portlet className={classes.root}>
        <PortletHeader>
        <div style={{display:'flex', alignItems:'center',}}>
          <PublicOutlined/>
          <PortletLabel title={$('language_settings')} />
          </div>
          <Select
            style={{ width: 100 }}
            value={this.selectedLanguage}
            onChange={e => (this.selectedLanguage = e.target.value as string)}
            inputProps={{
              name: 'preferredLanguage',
            }}
          >
            {site.supportedLanguages!.map((l: string) => (
              <MenuItem key={l} value={l}>
                {languages[l]}
              </MenuItem>
            ))}
          </Select>
        </PortletHeader>
        <PortletContent>
          {rows.map((r, i) => (
            <div className={classes.formContainer} key={'row' + i}>
              {r.map((c, i) => (
                <div className={classes.column} key={'col' + i}>
                                      <Input type="text" className="form-control" id="email"
                                         placeholder={c.name}                    
                                         value={this.getItemValueFromSite(c)}
                                         onChange={e => this.setSiteValueFromItem(c, e.target.value as string)}
                                        />
                  {/* <Typography variant="caption">{c.description}</Typography> */}
                  {/* <TextField
                    value={this.getItemValueFromSite(c)}
                    label={''+c.name}
                    variant='standard'
                    fullWidth
                    className={classes.field}
                    onChange={e => this.setSiteValueFromItem(c, e.target.value as string)}
                  /> */}
                </div>
              ))}
            </div>
          ))}
        </PortletContent>
      </Portlet>
      </>
    )
  }
}

export default withStyles(styles)(Texts)
