import React from 'react'
import './style.css'
const SiteCardComponent = ({site}) => {
    console.log(site)

  if(site.status === 'ACTIVE'){
      return(
        <div className="main-container">
        <div className="cards">
    
          <div className="card card-1">
            <div className="card__icon"><i class="fab fa-creative-commons-sampling"></i></div>
            <h3 className="card__title">{site.name}</h3>
            <span style={{textDecoration:'none',color:'#ffffff'}}>Tercih edilen dil:{"  " +site.preferredLanguage}</span>
            <span style={{textDecoration:'none',color:'#ffffff'}}>Durumu:{"  " +site.status}</span>
            <p className="card__apply">
              <a className="card__link" href="#">Düzenle<i className="fas fa-arrow-right" /></a>
            </p>
          </div>
          </div> 
  </div>
      )
  }else if(site.status === 'ERROR'){
      return (
        <div className="main-container">
        <div className="cards">
    
          <div className="card card-2">
            <div className="card__icon"><i class="fas fa-bug"></i></div>
            <h3 className="card__title">{site.name}</h3>
            <span style={{textDecoration:'none',color:'#ffffff'}}>Tercih edilen dil:{"  " +site.preferredLanguage}</span>
            <span style={{textDecoration:'none',color:'#ffffff'}}>Durumu:{"  " +site.status}</span>
            <p className="card__apply">
              <a className="card__link" href="#">Düzenle<i className="fas fa-arrow-right" /></a>
            </p>
          </div>
          </div> 
  </div>

      )
  }else if(site.status === 'COMPLETED'){
    return (
      <div className="main-container">
      <div className="cards">
  
        <div className="card card-3">
          <div className="card__icon"><i class="fas fa-thumbs-up"></i></div>
          <h3 className="card__title">{site.name}</h3>
          <span style={{textDecoration:'none',color:'#ffffff'}}>Tercih edilen dil:{"  " +site.preferredLanguage}</span>
            <span style={{textDecoration:'none',color:'#ffffff'}}>Durumu:{"  " +site.status}</span>
            <p className="card__apply">
            <a className="card__link" href="#">Düzenle<i className="fas fa-arrow-right" /></a>
          </p>
        </div>
        </div> 
</div>

)
}else if(site.status === 'WAITING'){
  return (
    <div className="main-container">
    <div className="cards">

      <div className="card card-4">
        <div className="card__icon"><i class="far fa-clock"></i></div>
        <h3 className="card__title">{site.name}</h3>
        <span style={{textDecoration:'none',color:'#ffffff'}}>Tercih edilen dil:{"  " +site.preferredLanguage}</span>
          <span style={{textDecoration:'none',color:'#ffffff'}}>Durumu:{"  " +site.status}</span>
          <p className="card__apply">
          <a className="card__link" href="#">Düzenle<i className="fas fa-arrow-right" /></a>
        </p>
      </div>
      </div> 
</div>

)
}else if(site.status === 'CRAWLING'){
  return (
    <div className="main-container">
    <div className="cards">

      <div className="card card-5">
        <div className="card__icon"><i class="fas fa-drafting-compass"></i></div>
        <h3 className="card__title">{site.name}</h3>
        <span style={{textDecoration:'none',color:'#ffffff'}}>Tercih edilen dil:{"  " +site.preferredLanguage}</span>
          <span style={{textDecoration:'none',color:'#ffffff'}}>Durumu:{"  " +site.status}</span>
          <p className="card__apply">
          <a className="card__link" href="#">Düzenle<i className="fas fa-arrow-right" /></a>
        </p>
      </div>
      </div> 
</div>

)
}else {
    return (
        <div className="main-container">
        <div className="cards">
    
          <div className="card card-1">
            <div className="card__icon"><i className="fas fa-bolt" /></div>
            <h3 className="card__title">{site.name}</h3>
            <span style={{textDecoration:'none',color:'#ffffff'}}>Tercih edilen dil:{"  " +site.preferredLanguage}</span>
            <span style={{textDecoration:'none',color:'#ffffff'}}>Durumu:{"  " +site.status}</span>
            <p className="card__apply">
              <a className="card__link" href="#">Düzenle<i className="fas fa-arrow-right" /></a>
            </p>
          </div>
          </div> 
  </div>
  
      )
}
}

export default SiteCardComponent
