import React from 'react'
import './style.css'
const NoFoundSiteCard = ({site}) => {
    // https://images.unsplash.com/photo-1584791097929-aa825fe8e1e1?ixlib=rb-1.2.1&q=85&fm=jpg&crop=entropy&cs=srgb&ixid=eyJhcHBfaWQiOjE0NTg5fQ
  return (
    <div className="a-box">
      <div className="img-container">
        <div className="img-inner">
          <div className="inner-skew">
            <img src={require('./nodata.jpg')} alt="nodata"/>
          </div>
        </div>
      </div>
      <div className="text-container">
        <h3>{site} statüsünde bir web sitesi bulunamadı.</h3>
      </div>
    </div>
  )
}

export default NoFoundSiteCard
