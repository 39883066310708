import validate from 'validate.js'

const luhnChk = (ccNum: any) => {
  const arr = [0, 2, 4, 6, 8, 1, 3, 5, 7, 9]
  let len = ccNum.length
  let bit = 1
  let sum = 0
  let val

  while (len) {
    val = parseInt(ccNum.charAt(--len), 10)
    // eslint-disable-next-line
    sum += (bit ^= 1) ? arr[val] : val
  }
  return sum && sum % 10 === 0
}

validate.validators.creditCard = function(value: any, options: any, key: any, attributes: any) {
  if (!luhnChk(value)) return 'is not valid.'
  return null
}
