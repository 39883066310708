import React from 'react'
import classNames from 'classnames'
import { createStyles, withStyles, WithStyles } from '@material-ui/core'

const styles = (theme: any) =>
  createStyles({
    root: {
      alignItems: 'center',
      borderBottom: `1px solid ${theme.palette.border}`,
      borderTopLeftRadius: '2px',
      borderTopRightRadius: '2px',
      display: 'flex',
      height: '64px',
      minHeight: '64px',
      justifyContent: 'space-between',
      paddingLeft: theme.spacing() * 3,
      paddingRight: theme.spacing() * 3,
      position: 'relative',
    },
    noDivider: {
      borderBottom: 'none',
    },
    noPadding: {
      padding: 0,
    },
  })

interface Props extends WithStyles<typeof styles> {
  children: React.ReactNode
  className?: string
  noDivider?: boolean
  noPadding?: boolean
}

const PortletHeader = (props: Props) => {
  const { classes, className, noDivider, noPadding, children, ...rest } = props

  const rootClassName = classNames(
    {
      [classes.root]: true,
      [classes.noDivider]: noDivider,
      [classes.noPadding]: noPadding,
    },
    className
  )

  return (
    <div {...rest} className={rootClassName}>
      {children}
    </div>
  )
}

export default withStyles(styles)(PortletHeader)
