import { createStyles, Theme } from '@material-ui/core/styles'

export default (theme: Theme) =>
  createStyles({
    appbar: {
      backgroundColor: '#fff',
    },
    headerContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    content: {
      paddingTop: theme.spacing() * 4,
      paddingBottom: theme.spacing() * 5,
      display: 'flex',
      justifyContent: 'space-between',
    },
    divider: {
      marginTop: theme.spacing() * 3,
      marginBottom: theme.spacing() * 3,
    },
    leftColumn: {
      width: '60%',
    },
    rightColumn: {
      paddingTop: 50,
      width: '38%',
    },
    adText: {
      marginTop: 20,
    },
    adBulletText: {
      marginTop: 10,
      marginLeft: 20,
    },
    title: {
      marginBottom: theme.spacing() * 2,
    },
    formContainer: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    column: {
      width: '49%',
    },
    spaceBetweenColumn: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    expireDateSelector: {
      width: '49%',
    },
    field: {
      marginBottom: theme.spacing() * 3,
    },
  })
