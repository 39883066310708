import { createStyles, Theme } from '@material-ui/core/styles'

export default (theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing() * 4,
    },
    content: {
      marginTop: theme.spacing() * 4,
    },
    progressWrapper: {
      paddingTop: '48px',
      paddingBottom: '24px',
      display: 'flex',
      justifyContent: 'center',
    },
    centerDiv: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      marginTop: '3%',
    },
    titleHead: {
      marginRight: 20,
      marginLeft: 5,
      marginBottom: 5,
    },
    button: {
      backgroundColor: '#FFFFFF',
      borderColor:'#394172',
      color:'#394172'
    },
  })
