import React, { StatelessComponent } from 'react'
import classNames from 'classnames'
import { withStyles, WithStyles } from '@material-ui/core'
import { Input } from '@material-ui/core'
import { Search as SearchIcon } from '@material-ui/icons'
import styles from './styles'

interface Props extends WithStyles<typeof styles> {
  className: string
  onChange: (...args: any[]) => any
  style: object
}

const SearchInput: StatelessComponent<Props> = (props: Props) => {
  const { classes, className, onChange, style, ...rest } = props

  const rootClassName = classNames(classes.root, className)

  return (
    <div className={rootClassName} style={style}>
      <SearchIcon className={classes.icon} />
      <Input
        {...rest}
        className={classes.input}
        disableUnderline
        onChange={onChange}
      />
    </div>
  )
}

SearchInput.defaultProps = {
  onChange: () => {},
}

export default withStyles(styles)(SearchInput)
