import classNames from 'classnames'
import React, { Component, Fragment } from 'react'
import Store from 'stores/store'
import Styles from './styles'
import uiStore from 'stores/ui-store'
import { CircularProgress, IconButton, Toolbar, Typography } from '@material-ui/core'
import { observer } from 'mobx-react'
import { withStyles, WithStyles } from '@material-ui/core'
import { GetText as $ } from 'stores/UITexts'

import {
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Media,
} from 'reactstrap'

interface Props extends WithStyles<typeof Styles> {
  title: string
  className: string
  history?: any
}

@observer
class Topbar extends Component<Props> {
  handleSignOut = async () => {
    await Store.signOut()
    window.location.href = '/signin'
  }

  render() {
    const { classes, className, title } = this.props
    const rootClassName = classNames(classes.root, className)

    return (
      <Fragment>
        <div className={rootClassName}>
          <Toolbar className={classes.toolbar}>
            <Typography className={classes.title} variant="h4">
              {title}
            </Typography>
            {uiStore.isInProgress ? (
              <CircularProgress className={classes.rightElement} />
            ) : (
              <IconButton className={classes.rightElement}>
                <UncontrolledDropdown>
                  <DropdownToggle className="pr-0" nav>
                    <Media className="align-items-center">
                      <span style={{ color: '#ffffff', fontSize: 16, marginLeft: 5 }}>{Store.tenant?.name} &darr;</span>
                    </Media>
                  </DropdownToggle>
                  <DropdownMenu className="dropdown-menu-arrow" right>
                    <DropdownItem className="noti-title" header tag="div">
                      <h6 className="text-overflow m-0">Hoşgeldiniz!</h6>
                    </DropdownItem>
                    <DropdownItem to="/admin/user-profile">
                      <i className="ni ni-support-16" />
                      <span>Destek</span>
                    </DropdownItem>
                    <DropdownItem divider />
                    <DropdownItem onClick={() => this.handleSignOut()}>
                      <i className="ni ni-user-run" />
                      <span>{$('sidebar.log_out')}</span>
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
              </IconButton>
            )}
          </Toolbar>
        </div>
      </Fragment>
    )
  }
}

export default withStyles(Styles)(Topbar)
