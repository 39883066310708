// Palette
import palette from 'theme/palette'

// Chart data
export const data = {
  labels: [
    '1',
    '2',
    '3',
    '4',
    '5',
    '6',
    '7',
    '1',
    '2',
    '3',
    '4',
    '5',
    '6',
    '7',
    '1',
    '2',
    '3',
    '4',
    '5',
    '6',
    '7',
    '1',
    '2',
    '3',
    '4',
    '5',
    '6',
    '7',
    '1',
    '2',
    '3',
    '4',
    '5',
    '6',
    '7',
    '1',
    '2',
    '3',
    '4',
    '5',
    '6',
    '7',
  ],
  datasets: [
    {
      label: 'Performance',
      backgroundColor: palette.primary.main,
      data: [
        18,
        5,
        19,
        27,
        29,
        19,
        20,
        18,
        5,
        19,
        27,
        29,
        19,
        20,
        18,
        5,
        19,
        27,
        29,
        19,
        20,
        18,
        5,
        19,
        27,
        29,
        19,
        20,
        18,
        5,
        19,
        27,
        29,
        19,
        20,
        18,
        5,
        19,
        27,
        29,
        19,
        20,
      ],
    },
    {
      label: 'Functional',
      backgroundColor: palette.danger.main,
      data: [
        11,
        20,
        12,
        29,
        30,
        25,
        13,
        11,
        20,
        12,
        29,
        30,
        25,
        13,
        11,
        20,
        12,
        29,
        30,
        25,
        13,
        11,
        20,
        12,
        29,
        30,
        25,
        13,
        18,
        5,
        19,
        27,
        29,
        19,
        20,
        18,
        5,
        19,
        27,
        29,
        19,
        20,
      ],
    },
    {
      label: 'Marketing',
      backgroundColor: palette.warning.main,
      data: [
        18,
        5,
        19,
        27,
        29,
        19,
        20,
        18,
        5,
        19,
        27,
        29,
        19,
        20,
        18,
        5,
        19,
        27,
        29,
        19,
        20,
        18,
        5,
        19,
        27,
        29,
        19,
        20,
        18,
        5,
        19,
        27,
        29,
        19,
        20,
        18,
        5,
        19,
        27,
        29,
        19,
        20,
      ],
    },
  ],
}

// Chart options
export const options = {
  responsive: true,
  maintainAspectRatio: false,
  animation: false,
  legend: { display: false },
  cornerRadius: 20,
  tooltips: {
    enabled: true,
    mode: 'index',
    intersect: false,
    borderWidth: 1,
    borderColor: palette.border,
    backgroundColor: palette.common.white,
    titleFontColor: palette.text.primary,
    bodyFontColor: palette.text.secondary,
    footerFontColor: palette.text.secondary,
  },
  layout: { padding: 0 },
  scales: {
    xAxes: [
      {
        stacked: true,
        barThickness: 10,
        maxBarThickness: 10,
        barPercentage: 0.5,
        categoryPercentage: 0.5,
        ticks: {
          fontColor: palette.text.secondary,
        },
        gridLines: {
          display: false,
          drawBorder: false,
        },
      },
    ],
    yAxes: [
      {
        stacked: true,
        ticks: {
          fontColor: palette.text.secondary,
          beginAtZero: true,
          min: 0,
        },
        gridLines: {
          borderDash: [2],
          borderDashOffset: [2],
          color: palette.divider,
          drawBorder: false,
          zeroLineBorderDash: [2],
          zeroLineBorderDashOffset: [2],
          zeroLineColor: palette.divider,
        },
      },
    ],
  },
}
