import React from 'react'
import './style.css'

interface WebsiteCardProps {
  parentCallback: any
  image:any
  title:String
  description:String,
  value:Number
  onTrigger:any
}

const DestinationCard: React.FC<WebsiteCardProps> = (props: WebsiteCardProps) =>  {
  return (
    <div className="news-card" onClick={(props.onTrigger(props.value))}>
    <a href="#" className="news-card__card-link"> </a>
    <img src={props.image} alt="" className="news-card__image" />
    <div className="news-card__text-wrapper">
      <h2 className="news-card__title">{props.title}</h2>
      <div className="news-card__details-wrapper">
        <p className="news-card__excerpt">{props.description}</p>
      </div>
    </div>
  </div>
  )
}

export default DestinationCard