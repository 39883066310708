import _ from 'lodash'
import PackageSelector from 'components/package-selector'
import PaymentPlanStore, { PaymentPeriod } from 'stores/payment-plan-store'
import React, { Component } from 'react'
import Schema, { Rules } from 'async-validator'
import Store from 'stores/store'
import { AgreementViewer } from 'components/agreement-viewer'
import { GetText as $ } from 'stores/UITexts'
import { observable } from 'mobx'
import { observer } from 'mobx-react'
import { PaymentPlan } from 'stores/models'
import { Portlet, PortletContent, PortletFooter, Select } from 'components'
import 'utils/validators'
import {
  Button,
  createStyles,
  Divider,
  TextField,
  Theme,
  Typography,
  withStyles,
  WithStyles,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  FormControlLabel,
  Checkbox,
  Link,
} from '@material-ui/core'

const Styles = (theme: Theme) =>
  createStyles({
    root: {
      height: '100%',
      display: 'flex',
      justifyContent: 'space-between',
      flexDirection: 'column',
    },
    container: {
      padding: theme.spacing() * 3,
    },
    portlet: {
      marginTop: theme.spacing() * 4,
      paddingLeft: 10,
      paddingRight: 10,
      borderWidth: 1,
      borderStyle: 'solid',
      borderColor: '#dcdcdc',
      maxWidth: '100%',
      borderRadius: 5,
      backgroundColor: '#fff',
    },
    label: {
      marginBottom: theme.spacing() * 2,
    },
    divider: {
      marginBottom: theme.spacing() * 2,
    },
    formContainer: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    expireDateSelector: {
      width: '49%',
    },
    column: {
      width: '49%',
    },
    spaceBetweenColumn: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    field: {
      marginBottom: theme.spacing() * 3,
    },
    numberField: {
      marginBottom: theme.spacing() * 3,
      '& input::-webkit-clear-button, & input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
        '-webkit-appearance': 'none',
      },
    },
  })

interface Props extends WithStyles<typeof Styles> {
  history: any
}

const months = _.range(1, 13).map(i => ({ key: i + '', value: _.padStart(i + '', 2, '0') }))
const years = _.range(new Date().getFullYear(), new Date().getFullYear() + 10).map(i => ({
  key: i + '',
  value: i + '',
}))
const locale = navigator.language.startsWith('tr') ? 'tr' : 'en'

@observer
class BuyCookieseal extends Component<Props, History> {
  @observable paymentPeriod = PaymentPeriod.Monthly

  @observable values: { [key: string]: any } = {
    siteName: Store.tenant.sites[0].name,
    siteUrl: Store.tenant.sites[0].urls[0],
    cardHolderName: '',
    cardNumber: '',
    expireMonth: '',
    expireYear: '',
    ccv: '',
    isToSAccepted: false,
  }

  @observable touched: { [key: string]: boolean } = {
    siteName: false,
    siteUrl: false,
    cardHolderName: false,
    cardNumber: false,
    expireMonth: false,
    expireYear: false,
    ccv: false,
    isToSAccepted: false,
  }

  @observable errors: { [key: string]: string[] } = {
    siteName: [],
    siteUrl: [],
    cardHolderName: [],
    cardNumber: [],
    expireMonth: [],
    expireYear: [],
    ccv: [],
    isToSAccepted: [],
  }

  @observable isValid = false
  @observable error = ''
  @observable transactionInProgress = false
  @observable purchaseSuccessful = false
  @observable dialog = {
    open: false,
    title: '',
    content: '',
  }

  @observable packageLocale: keyof typeof PaymentPlanStore.defaultPrices = locale === 'tr' ? 'tr' : 'eur'
  @observable price = 0
  @observable agreementViewer = {
    title: 'Kullanım Sözleşmesi',
    agreementName: '01_en.html',
    open: false,
    data: {},
  }

  validationDescriptor: Rules = {
    siteName: { type: 'string', required: true },
    siteUrl: [
      { type: 'url', required: true },
      // {
      //   asyncValidator: (rule, value) => {
      //     return new Promise(async (resolve, reject) => {
      //       const exists = await Store.checkSiteUrl(value)
      //       if (exists) {
      //         return reject('Site address exists')
      //       }
      //       resolve()
      //     })
      //   },
      // },
    ],
    cardHolderName: { type: 'string', required: true },
    cardNumber: { type: 'string', required: true },
    expireMonth: { type: 'number', required: true },
    expireYear: { type: 'number', required: true },
    ccv: { type: 'number', required: true },
    isToSAccepted: { type: 'boolean', required: true },
  }

  validator = new Schema(this.validationDescriptor)

  validateForm = _.debounce(() => {
    this.validator.validate(this.values, {}, (errors, fields) => {
      this.errors = {}
      if (fields) {
        Object.keys(fields).forEach(f => {
          this.errors[f] = fields[f].map(e => e.message)
        })
      }
      this.isValid = errors === null
    })
  }, 300)

  handleFieldChange = (field: string, value: any) => {
    this.error = ''
    this.touched[field] = true
    this.values[field] = value
    this.validateForm()
  }

  checkError(field: string) {
    return this.touched[field] && this.errors[field] && this.errors[field].length > 0
  }

  getError(field: string, label: string) {
    return this.checkError(field) ? this.errors[field][0] : label
  }

  componentDidMount() {
    PaymentPlanStore.load().then(() => {
      const selectedPeriod = _.padStart((this.paymentPeriod === PaymentPeriod.Monthly ? 1 : 12) + '', 2, '0')
      // const currencyCode = locale === 'tr' ? 'TRY' : 'EUR'
      const currencyCode = 'TRY'
      const paymentPlan: PaymentPlan = PaymentPlanStore.items.find(i => i.name === `${currencyCode}${selectedPeriod}_2024`)!
      this.price = paymentPlan.price
    })
  }

  paymentOptionChanged = (period: PaymentPeriod) => {
    this.paymentPeriod = period
    this.componentDidMount()
  }

  async register() {
    this.transactionInProgress = true
    const period = _.padStart((this.paymentPeriod === PaymentPeriod.Monthly ? 1 : 12) + '', 2, '0')
    // const currencyCode = locale === 'tr' ? 'TRY' : 'EUR'
    const currencyCode = 'TRY'

    const paymentPlan: PaymentPlan = PaymentPlanStore.items.find(i => i.name === `${currencyCode}${period}_2024`)!

    const params = Object.assign(
      {
        scriptPaymentPlanRefCode: paymentPlan.referenceCode,
        locale: locale,
      },
      this.values
    )

    const result = await Store.registerSite(params as any)
    if (!result.success) {
      this.dialog = {
        open: true,
        title: $('error_processing_transaction'),
        content: result.message,
      }
      this.purchaseSuccessful = false
    } else {
      this.dialog = {
        open: true,
        title: $('success'),
        content: $('purchase_successful.'),
      }
      this.purchaseSuccessful = true
    }
    this.transactionInProgress = false
  }

  async closeDialog() {
    if (this.purchaseSuccessful) {
      await Store.loadTenant()
      // history.push('/sites')
    }
    this.dialog = {
      open: false,
      title: '',
      content: '',
    }
  }

  showAgreement(title: string, name: string) {
    let period
    if (this.paymentPeriod === PaymentPeriod.Monthly) {
      if (locale === 'tr') {
        period = 'AYLIK'
      } else {
        period = 'MONTHLY'
      }
    } else {
      if (locale === 'tr') {
        period = 'YILLIK'
      } else {
        period = 'YEARLY'
      }
    }
    const data = {
      customerName: Store.tenant.contactPerson.firstName + ' ' + Store.tenant.contactPerson.lastName,
      address: Store.tenant.address,
      phone: Store.tenant.contactPerson.phone,
      email: Store.tenant.contactPerson.email,
      dateSigned: new Date().toLocaleDateString(),
      productName: `CookiSeal ${this.paymentPeriod === PaymentPeriod.Yearly ? 'Yearly Subsciption' : 'Monthly Subsciption'}`,
      period: period,
      price: this.price,
      discount: 0,
      totalPrice: this.price,
      taxRate: 20,
      priceWithoutTax: this.price / 1.2,
    }
    this.agreementViewer = { title: title, agreementName: name, open: true, data: data }
  }

  render() {
    const { classes } = this.props
    const ToSLabel = (
      /* locale === 'tr' ? */ <span>
        {$('signup.accept')}: &nbsp;
        <Link
          component="button"
          style={{ fontSize: 14, paddingBottom: 3 }}
          onClick={() => this.showAgreement('ÖN BİLGİLENDİRME FORMU', 'on_bilgilendirme')}
        >
          Ön Bilgilendirme Formu
        </Link>
        &nbsp;&&nbsp;
        <Link
          component="button"
          style={{ fontSize: 14, paddingBottom: 3 }}
          onClick={() => this.showAgreement('MESAFELİ SATIŞ SÖZLEŞMESİ', 'mesafeli_satis')}
        >
          Mesafeli Kullanım ve Üyelik Sözleşmesi
        </Link>
      </span>
    ) /*: (
        <span>
          I have read and agree to the&nbsp;
          <Link component="button" style={{ fontSize: 14, paddingBottom: 3 }} onClick={() => this.showAgreement('ÖN BİLGİLENDİRME FORMU', '01_en')}>
            Terms and Conditions
          </Link>
          &nbsp;and&nbsp;
          <Link
            component="button"
            style={{ fontSize: 14, paddingBottom: 3 }}
            onClick={() => this.showAgreement('Mesafeli Kullanım ve Üyelik Sözleşmesi', '02_en')}
          >
            Sales Agreement
          </Link>
        </span>
      )
      */

    return (
      // <DashboardLayout title={$('register_new_site')}>
      <>
        <div className={classes.root}>
          <div className={classes.container}>
            <Portlet className={classes.portlet}>
              <PortletContent>
                <Typography variant="h5" className={classes.label}>
                  {$('site_information')}
                </Typography>
                <Divider className={classes.divider} />

                <Typography variant="h5" className={classes.label}>
                  {$('package_selection')}
                </Typography>
                {!PaymentPlanStore.loading && (
                  <PackageSelector
                    prices={PaymentPlanStore.getPrices()}
                    activeIndex={this.paymentPeriod === 'MONTHLY' ? 0 : 1}
                    onChanged={period => this.paymentOptionChanged(period)}
                  />
                )}
                <Divider className={classes.divider} style={{ marginTop: 20 }} />

                <div className={classes.formContainer}>
                  <div className={classes.column}>
                    <TextField
                      label={this.getError('siteName', $('site_name'))}
                      variant="outlined"
                      fullWidth
                      className={classes.field}
                      name="siteName"
                      value={this.values.siteName}
                      error={this.checkError('siteName')}
                      onChange={e => this.handleFieldChange('siteName', e.target.value)}
                    />
                  </div>
                  <div className={classes.column}>
                    <TextField
                      label={this.getError('siteUrl', $('site_url'))}
                      variant="outlined"
                      fullWidth
                      className={classes.field}
                      name="siteUrl"
                      value={this.values.siteUrl}
                      error={this.checkError('siteUrl')}
                      onChange={e => this.handleFieldChange('siteUrl', e.target.value)}
                    />
                  </div>
                </div>

                <Divider className={classes.divider} />
                <Typography variant="h5" className={classes.label}>
                  {$('credit_card_information')}
                </Typography>
                <div className={classes.formContainer}>
                  <div className={classes.column}>
                    <TextField
                      label={this.getError('cardHolderName', $('card_holder_name'))}
                      variant="outlined"
                      fullWidth
                      className={classes.field}
                      name="cardHolderName"
                      value={this.values.cardHolderName}
                      error={this.checkError('cardHolderName')}
                      onChange={e => this.handleFieldChange('cardHolderName', e.target.value)}
                    />
                    <TextField
                      label={this.getError('cardNumber', $('card_number'))}
                      variant="outlined"
                      fullWidth
                      className={classes.numberField}
                      name="cardNumber"
                      type="number"
                      value={this.values.cardNumber}
                      error={this.checkError('cardNumber')}
                      onChange={e => this.handleFieldChange('cardNumber', e.target.value)}
                    />
                  </div>
                  <div className={classes.column}>
                    <div className={classes.spaceBetweenColumn}>
                      <Select
                        label={this.getError('expireMonth', $('expire_month'))}
                        items={months}
                        className={classes.expireDateSelector}
                        onChange={({ value }) => this.handleFieldChange('expireMonth', Number(value))}
                      />
                      <Select
                        label={this.getError('expireYear', $('expire_year'))}
                        items={years}
                        className={classes.expireDateSelector}
                        onChange={({ value }) => this.handleFieldChange('expireYear', Number(value))}
                      />
                    </div>
                    <TextField
                      label={this.getError('cvc', 'CVC')}
                      variant="outlined"
                      className={classes.numberField}
                      name="ccv"
                      type="password"
                      value={this.values.ccv}
                      error={this.checkError('ccv')}
                      onChange={e => this.handleFieldChange('ccv', Number(e.target.value))}
                    />
                  </div>
                </div>
                <Divider className={classes.divider} />
                <div className={classes.column}>
                  <div className={classes.formContainer}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={this.values.isToSAccepted as boolean}
                          onChange={() => {
                            this.values.isToSAccepted = !this.values.isToSAccepted
                          }}
                        />
                      }
                      label={ToSLabel}
                    />
                  </div>
                </div>
              </PortletContent>
            </Portlet>
          </div>
          <div>
            <Portlet>
              <PortletFooter>
                <Button
                  disabled={!this.isValid || this.transactionInProgress || !this.values.isToSAccepted}
                  color="primary"
                  variant="contained"
                  onClick={() => this.register()}
                >
                  {$('make_payment_and_register')}
                </Button>
              </PortletFooter>
            </Portlet>
          </div>
        </div>
        <AgreementViewer
          title={this.agreementViewer.title}
          agreementName={this.agreementViewer.agreementName}
          isOpen={this.agreementViewer.open}
          data={this.agreementViewer.data}
          onClose={() => (this.agreementViewer.open = false)}
        />
        <Dialog open={this.dialog.open} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
          <DialogTitle id="alert-dialog-title">{this.dialog.title}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">{this.dialog.content}</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => this.closeDialog()} color="primary">
              {$('ok')}
            </Button>
          </DialogActions>
        </Dialog>
      </>
      // </DashboardLayout>
    )
  }
}

export default withStyles(Styles)(BuyCookieseal)
