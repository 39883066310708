import classNames from 'classnames'
import React, { Component } from 'react'
import Styles from './styles'
import { Divider, Typography, withStyles, WithStyles } from '@material-ui/core'
import { EventAvailableOutlined as EventAvailableIcon, SettingsOutlined as SettingsIcon, SyncOutlined as SyncIcon } from '@material-ui/icons'
import { Paper } from 'components'
import { Site } from 'stores/models'

interface Props extends WithStyles<typeof Styles> {
  className?: string
  site: Site
}

class SiteCard extends Component<Props> {
  render() {
    const { classes, className, site } = this.props
    let data = site.status.toUpperCase()
    let rootClassName:any = null;
    if(data === 'WAITING'){
      rootClassName = classNames(classes.root, className)
    }else if(data === 'CRAWLING'){
      rootClassName = classNames(classes.root, className)
    }else if(data === 'COMPLETED'){
      rootClassName = classNames(classes.root, className)
    }else if(data === 'ERROR'){
      rootClassName = classNames(classes.root, className)
    }else if(data === 'ACTIVE'){
      rootClassName = classNames(classes.root, className)
    }
    rootClassName = classNames(classes.root, className)
    return (
      <Paper className={rootClassName}>
        <div className={classes.imageWrapper}>
          <img alt="Site" className={classes.image} src="/images/domain.png" />
        </div>
        <div className={classes.details}>
          <Typography className={classes.title} variant="h4">
            {site.name}
          </Typography>
          <Typography className={classes.description} variant="body1">
            {site.urls[0]}
          </Typography>
        </div>
        <Divider />
        <div className={classes.stats}>
          {data === 'WAITING' && <EventAvailableIcon className={classes.updateIcon} />}
          {data === 'CRAWLING' && <SyncIcon className={classes.updateIcon} />}
          {data === 'COMPLETED' && <SettingsIcon className={classes.updateIcon} />}
          {data === 'ERROR' && <SettingsIcon className={classes.updateIcon} />}
          {data === 'ACTIVE' && <SettingsIcon className={classes.updateIcon} />}
          <Typography className={classes.updateText} variant="body2">
            {data === 'WAITING' && 'Beklemede'}
            {data === 'CRAWLING' && 'Çerezler Taranıyor'}
            {data === 'COMPLETED' && 'Çerez Taraması Bitti'} 
            {data === 'Error' && 'Bir Sorun Oluştu'}
            {data === 'ACTIVE' && 'Site Kullanımda'}
          </Typography>
        </div>
      </Paper>
    )
  }
}

export default withStyles(Styles)(SiteCard)
