import { createStyles, Theme } from '@material-ui/core/styles'

export default (theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: theme.palette.primary.main,
      borderColor: theme.palette.primary.main,
      padding: theme.spacing() * 3,
      height: '100%',
    },
    content: {
      display: 'flex',
      alignItems: 'center',
      height: '100%',
    },
    details: {},
    title: {
      fontWeight: 700,
      color: theme.palette.common.white,
    },
    value: {
      color: theme.palette.common.white,
    },
    iconWrapper: {
      alignItems: 'center',
      borderRadius: '50%',
      display: 'inline-flex',
      height: '4rem',
      justifyContent: 'center',
      marginLeft: 'auto',
      width: '4rem',
    },
    icon: {
      color: theme.palette.primary.main,
      width: '4rem',
      height: '4rem',
      fontSize: '2rem',
    },
  })
