import React, { useState } from 'react'
import { BannerToolContext } from '../BannerCustomization'
import Slider from '@material-ui/core/Slider'
import { withStyles } from '@material-ui/core'
import FrameComponent from '../FrameComponent'

const PrettoSlider = withStyles({
  root: {
    color: '#383F73',
    height: 8,
  },
  thumb: {
    height: 24,
    width: 24,
    backgroundColor: '#fff',
    border: '2px solid currentColor',
    marginTop: -10,
    marginLeft: -12,
    '&:focus, &:hover, &$active': {
      boxShadow: 'inherit',
    },
  },
  active: {},
  valueLabel: {
    left: 'calc(-50% + 4px)',
  },
  track: {
    height: 4,
    borderRadius: 4,
  },
  rail: {
    height: 4,
    borderRadius: 4,
  },
})(Slider)

const FontSize = () => {
  const value = React.useContext(BannerToolContext)
  const [fontSize, setFontSize] = useState(value.form4.value ? value.form4.value : 14)

  const handleChange = (event, newValue) => {
    value.form4.value = newValue
    setFontSize(newValue)
  }

  React.useEffect(() => {
  }, [fontSize])


  return (
    <div style={{display:'flex', flexDirection:'row'}}>
      <div  style={{display:'flex', flexDirection:'column', width:'25%', alignItems: 'center',gap:'10px'}}>
        <div
        style={{fontWeight:'400', fontFamily:'Quicksand' ,fontSize:'20px', marginBottom: '5rem', color:'#383F73',textAlign:'center' }}
          >
          Lütfen font büyüklüğünü seçiniz.
        </div>
        <PrettoSlider
          min={10}
          max={24}
          step={2}
          defaultValue={14}
          onChange={handleChange}
          aria-labelledby="continuous-slider"
          valueLabelDisplay="on"
        />
      </div>
      <div style={{display:'flex', justifyContent:'center', width:'75%', boxShadow:'0px 0px 20px 1px #0000000D'}}>
        <FrameComponent />
      </div>
    </div>
  )
}

export default FontSize
