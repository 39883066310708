import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import classNames from 'classnames'
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked'
import React from 'react'
import { createStyles, Typography } from '@material-ui/core'
import { PaymentPeriod, PricingInfo } from 'stores/payment-plan-store'
import { Theme, withStyles, WithStyles } from '@material-ui/core'
import { GetText as $ } from 'stores/UITexts'

const styles = (theme: Theme) =>
  createStyles({
    root: {
      justifyContent: 'space-between',
      alignItems: 'center',
      display: 'flex',
    },
    option: {
      display: 'flex',
      width: '49%',
      height: 120,
      justifyContent: 'flex-start',
      alignItems: 'center',
      border: '3px solid #ededed',
      borderRadius: 5,
      cursor: 'pointer',
      margin: 2,
    },
    activeOption: {
      borderWidth: 3,
      borderColor: '#0767DB',
      color: '#fff',
      fontWeight: 'bold',
    },
    circle: {
      marginRight: 30,
      marginLeft: 10,
    },
    activeCircle: {
      backgroundColor: '#fff',
    },
    pricingInfo: {
      justifyContent: 'flex-start',
      alignItems: 'center',
    },
    text: {
      marginBottom: 10,
    },
    activeText: {},
  })

interface Props extends WithStyles<typeof styles> {
  children?: React.ReactNode
  className?: string
  activeIndex: number
  prices: PricingInfo
  onChanged: (period: PaymentPeriod) => void
}

const PackageSelector = (props: Props) => {
  const { activeIndex, classes, prices, onChanged } = props

  const optionClicked = (index: number) => {
    if (index !== activeIndex) {
      onChanged(index === 0 ? PaymentPeriod.Monthly : PaymentPeriod.Yearly)
    }
  }

  // const currency = navigator.language.startsWith('tr') ? 'TRY' : 'EUR' // I hate disabling codes using comments but PO wants it (:
  const currency = 'TRY'

  return (
    <div className={classes.root}>
      <div className={classNames({ [classes.option]: true, [classes.activeOption]: activeIndex === 0 })} onClick={() => optionClicked(0)}>
        <div className={classNames({ [classes.circle]: true, [classes.activeCircle]: activeIndex === 0 })}>
          {activeIndex === 0 ? <CheckCircleIcon color="primary" /> : <RadioButtonUncheckedIcon color="primary" />}
        </div>
        <div className={classes.pricingInfo}>
          <Typography className={classNames({ [classes.text]: true, [classes.activeText]: activeIndex === 0 })} variant="h6">
          {$('monthly_payment')}
          </Typography>
          <Typography className={classNames({ [classes.text]: true, [classes.activeText]: activeIndex === 0 })} variant="h4">
            {currency} {prices.monthly.price}
          </Typography>
        </div>
      </div>
      <div className={classNames({ [classes.option]: true, [classes.activeOption]: activeIndex === 1 })} onClick={() => optionClicked(1)}>
        <div className={classNames({ [classes.circle]: true, [classes.activeCircle]: activeIndex === 1 })}>
          {activeIndex === 1 ? <CheckCircleIcon color="primary" /> : <RadioButtonUncheckedIcon color="primary" />}
        </div>
        <div className={classes.pricingInfo}>
          <Typography className={classNames({ [classes.text]: true, [classes.activeText]: activeIndex === 1 })} variant="h6">
          {$('yearly_payment')}
          </Typography>
          <Typography className={classNames({ [classes.text]: true, [classes.activeText]: activeIndex === 1 })} variant="h4">
            {currency} {prices.yearly.price}
          </Typography>
        </div>
      </div>
    </div>
  )
}

export default withStyles(styles)(PackageSelector)
