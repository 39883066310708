import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { BannerToolContext } from '../BannerCustomization'
import Button from '@material-ui/core/Button'

import FrameComponent from '../FrameComponent'

const useStyles = makeStyles(theme => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
      width: '25ch',
    },
  },
}))

const BannerPlace = () => {
  const classes = useStyles()
  const value = React.useContext(BannerToolContext)

  const [selectedButton, setSelectedButton] = useState(value.form1.value ? value.form1.value : 1)

  const handleButtonClick = buttonValue => {
    setSelectedButton(buttonValue)
    value.form1.value = buttonValue
  }

  const ActiveButtonStyles = {
    textTransform: 'capitalize',
    fontFamily: 'Quicksand',
    fontSize: '20px',
    fontWeight: '400',
    color: '#FFFFFF',
    backgroundColor: '#383F73',
    boxShadow: '0px 0px 20px 1px #0000000D',
    maxWidth: '100%',
    width: '350px',
    height: '64px',
    borderRadius: '5px',
    display: 'flex',
    justifyContent: 'flex-start',
    paddingLeft: '24px',
  }
  const PassiveButtonStyles = {
    textTransform: 'capitalize',
    fontFamily: 'Quicksand',
    fontSize: '20px',
    fontWeight: '400',
    color: '#383F73',
    backgroundColor: '#FFFFFF',
    boxShadow: '0px 0px 20px 1px #0000000D',
    maxWidth: '100%',
    width: '350px',
    height: '64px',
    borderRadius: '5px',
    display: 'flex',
    justifyContent: 'flex-start',
    paddingLeft: '24px',
  }

  return (
    <div style={{display:'flex', flexDirection:'row'}}>
      <div  style={{display:'flex', flexDirection:'column' ,width:'25%', alignItems: 'center',gap:'10px'}}>
        <div
        style={{fontWeight:'400', fontFamily:'Quicksand' ,fontSize:'20px', marginBottom: '8px', color:'#383F73',textAlign:'center' }}
          >
          Lütfen banner konumunu seçiniz.
        </div>
        <Button onClick={() => handleButtonClick(1)} style={selectedButton === 1 ? ActiveButtonStyles : PassiveButtonStyles}>
          Aşağıda
        </Button>
        <Button onClick={() => handleButtonClick(2)} style={selectedButton === 2 ? ActiveButtonStyles : PassiveButtonStyles}>
          Yukarıda
        </Button>
        <Button onClick={() => handleButtonClick(3)} style={selectedButton === 3 ? ActiveButtonStyles : PassiveButtonStyles}>
          Sağda
        </Button>
        <Button onClick={() => handleButtonClick(4)} style={selectedButton === 4 ? ActiveButtonStyles : PassiveButtonStyles}>
          Solda
        </Button>
      </div>
      <div style={{display:'flex', justifyContent:'center', width:'75%', boxShadow:'0px 0px 20px 1px #0000000D'}}>
        <FrameComponent />
      </div>
    </div>
  )
}

export default BannerPlace
