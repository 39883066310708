import React from 'react'
import classNames from 'classnames'
import { createStyles, makeStyles, Theme } from '@material-ui/core'
import Paper from 'components/paper'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
    },
  })
)

const Portlet = (props: any) => {
  const classes = useStyles()
  const { className, children, ...rest } = props

  const rootClassName = classNames(classes.root, className)

  return (
    <Paper {...rest} className={rootClassName} elevation={0} squared={false}>
      {children}
    </Paper>
  )
}

export default Portlet
