import axios from 'stores/axios'
import DashboardLayout from 'layouts/dashboard'
import paymentPlanStore from 'stores/payment-plan-store'
import React, { Component } from 'react'
import Store from 'stores/store'
import uiStore from 'stores/ui-store'
import { GetText as $ } from 'stores/UITexts'
import { observable } from 'mobx'
import { observer } from 'mobx-react'
import { Portlet, PortletContent } from 'components'
import { DataGrid, GridColDef, GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid'
import {
  Button,
  Chip,
  createStyles,
  Theme,
  withStyles,
  WithStyles,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from '@material-ui/core'

import './DataGrid.css'
const Styles = (theme: Theme) =>
  createStyles({
    root: {
      height: '100%',
      display: 'flex',
      justifyContent: 'space-between',
      flexDirection: 'column',
    },
    container: {
      padding: theme.spacing() * 3,
    },
    portlet: {
      marginTop: theme.spacing() * 4,
      paddingLeft: 10,
      paddingRight: 10,
      borderWidth: 1,
      borderStyle: 'solid',
      borderColor: '#dcdcdc',
      maxWidth: '100%',

      borderRadius: 5,
      backgroundColor: '#fff',
    },
    label: {
      marginBottom: theme.spacing() * 2,
    },
    siteItem: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      marginTop: theme.spacing() * 2,
      marginBottom: theme.spacing() * 2,
    },
    siteInfo: {
      display: 'flex',
      flexDirection: 'column',
      minWidth: 250,
    },
    siteUrl: {
      fontSize: '13px',
      marginTop: 5,
    },
    siteProductName: {
      fontSize: '13px',
    },
    siteDates: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      width: 250,
    },
    siteDateContainer: {
      backgroundColor: theme.palette.primary.light,
      padding: 5,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      borderRadius: 5,
      width: 100,
    },
    siteControls: {},
  })

interface Props extends WithStyles<typeof Styles> {
  history: any
}

interface Site {
  id: string
  name: string
  paymentPlanRefCode: string
  urls: string[]
  serviceStartDate: Date
  serviceEndDate: Date
  status: string
}

interface Tenant {
  _id: string
  name: string
  contactPerson: {
    firstName: string
    lastName: string
  }
  sites: Site[]
}

const StyledDataGrid = withStyles({
  root: {
    '& .MuiDataGrid-renderingZone': {
      maxHeight: 'none !important',
    },
    '& .MuiDataGrid-cell': {
      lineHeight: 'unset !important',
      maxHeight: 'none !important',
      whiteSpace: 'normal',
    },
    '& .MuiDataGrid-row': {
      maxHeight: 'none !important',
    },
  },
})(DataGrid)
@observer
class Tenants extends Component<Props> {
  @observable tenants: Tenant[] = []
  @observable dialogOpen = false
  @observable site: Site

  async componentDidMount() {
    const response = await axios.get('system/tenants')
    this.tenants = response.data

    if (!paymentPlanStore.items) {
      await paymentPlanStore.load()
    }
  }

  getPaymentPlan(paymentPlanRefCode: string) {
    return paymentPlanStore.items.find(p => p.referenceCode === paymentPlanRefCode)
  }

  openDialog(site: any) {
    this.dialogOpen = true
    this.site = site
  }

  async handleClose(suspend: boolean) {
    this.dialogOpen = !this.dialogOpen

    if (suspend) {
      try {
        await Store.suspendSite(this.site.id)
        this.site.status = 'SUSPENDED'
        uiStore.showSnackbar($('site_suspended'))
      } catch {
        uiStore.showSnackbar($('site_cannot_be_suspended'))
      }
    }
  }

  render() {
    const { classes } = this.props

    if (!paymentPlanStore.items) {
      return (
        <DashboardLayout title={$('__tenants.title__')}>
          <div className={classes.root}>
            <div className={classes.container}>
              <Portlet className={classes.portlet}>
                <PortletContent></PortletContent>
              </Portlet>
            </div>
          </div>
        </DashboardLayout>
      )
    }
    const tenantsCopy = this.tenants.slice()

    const formatDate = (date: string): string => {
      const parsedDate = new Date(date)

      const year = parsedDate.getFullYear()
      const month = String(parsedDate.getMonth() + 1).padStart(2, '0')
      const day = String(parsedDate.getDate()).padStart(2, '0')

      return `${year}-${month}-${day}`
    }

    const updatedTenants: any[] = []

    tenantsCopy.forEach((tenant: any) => {
      if (tenant.sites.length >= 1) {
        tenant.sites.forEach((site: any) => {
          const updatedTenant: any = { ...tenant }
          updatedTenant.sites = [{ ...site }]
          if (site.serviceStartDate && site.serviceEndDate) {
            updatedTenant.sites.serviceStartDate = formatDate(updatedTenant.sites.serviceStartDate)
            updatedTenant.sites.serviceEndDate = formatDate(updatedTenant.sites.serviceEndDate)

            updatedTenant.sites.serviceStartDate = String(updatedTenant.sites.serviceStartDate)
            updatedTenant.sites.serviceEndDate = String(updatedTenant.sites.serviceEndDate)
          }
          updatedTenants.push(updatedTenant)
        })
      } else {
        tenant.sites.forEach((site: any) => {
          if (site.serviceStartDate && site.serviceEndDate) {
            site.serviceStartDate = "nothing"
            site.serviceEndDate = "nothing"
          }
        })
        updatedTenants.push(tenant)
      }
    })


    const columns: GridColDef[] = [
      {
        field: 'name',
        headerName: $('tenant_list.tenant_name'),
        width: 300,
        valueGetter: params => {
          return `${params.row?.name}`
        },
      },
      {
        field: 'sites[0].name',
        headerName: $('tenant_list.site_name'),
        width: 300,
        valueGetter: params => {
          return `${params.row.sites[0]?.name}`
        },
      },
      {
        field: 'sites[0].urls[0]',
        headerName: $('tenant_list.site_url'),
        width: 300,
        valueGetter: params => {
          return `${params.row.sites[0]?.urls[0]}`
        },
      },

      {
        field: 'sites[0].serviceStartDate',
        headerName: $('tenant_list.start_date'),
        width: 250,
        valueGetter: params => {
          return `${new Date(params.row.sites[0]?.serviceStartDate).toLocaleDateString(navigator.language)}`
        },
      },
      {
        field: 'sites[0].serviceEndDate',
        headerName: $('tenant_list.end_date'),
        width: 250,
        valueGetter: params => {
          return `${new Date(params.row.sites[0]?.serviceEndDate).toLocaleDateString(navigator.language)}`
        },
      },
      {
        field: 'sites[0].status',
        headerName: $('tenant_list.status'),
        width: 250,
        valueGetter: params => {
          return `${params.row.sites[0]?.status}`
        },
      },
    ]

    function CustomToolbar() {
      return (
        <GridToolbarContainer>
          <GridToolbarExport placeholder={'Export'} csvOptions={{ utf8WithBom: true, fileName: 'tenant_list_for_admins' }} />
        </GridToolbarContainer>
      )
    }

    function guidGenerator() {
      var S4 = function () {
        return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1)
      }
      return S4() + S4() + '-' + S4() + '-' + S4() + '-' + S4() + '-' + S4() + S4() + S4()
    }

    return (
      <DashboardLayout title={$('__tenants.title__')}>
        <div className={classes.root}>
          <div className={classes.container}>
            <Portlet className={classes.portlet}>
              <PortletContent>
                <div style={{ display: 'flex', height: 1000, width: '100%', maxWidth: '100%' }}>
                  <div style={{ flexGrow: 1 }}>
                    <StyledDataGrid
                      rows={updatedTenants}
                      getRowId={guidGenerator}
                      columns={columns}
                      disableSelectionOnClick
                      components={{
                        Toolbar: CustomToolbar,
                      }}
                      style={{ overflow: 'auto' }}
                    />
                  </div>
                </div>
                <Dialog
                  open={this.dialogOpen}
                  onClose={() => this.handleClose(false)}
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description"
                >
                  <DialogTitle id="alert-dialog-title">{$('stop')}</DialogTitle>
                  <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                      {$('suspend_cookieseal_services_for')} {this.site?.name}?
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={() => this.handleClose(false)} color="primary">
                      {$('cancel')}
                    </Button>
                    <Button onClick={() => this.handleClose(true)} color="secondary" autoFocus>
                      {$('suspend')}
                    </Button>
                  </DialogActions>
                </Dialog>
              </PortletContent>
            </Portlet>
          </div>
        </div>
      </DashboardLayout>
    )
  }
}

export default withStyles(Styles)(Tenants)
