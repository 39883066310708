import { createStyles, Theme } from '@material-ui/core/styles'

export default (theme: Theme) =>
  createStyles({
    root: {
      borderBottom: `1px solid #ffffff`,
      backgroundColor: '#394172',
      display: 'flex',
      alignItems: 'center',
      height: '64px',
      zIndex: theme.zIndex.appBar,
    },
    toolbar: {
      minHeight: 'auto',
      width: `calc(100% - 270px)`,
      marginLeft: '270px',
      
    },
    title: {color:'#ffffff'},
    menuButton: {
      marginLeft: '-4px',
      color:'#ffffff'
    },
    rightElement: {
      marginLeft: 'auto',
      alignItems:'center',
      justifyContent:'center',
    color: '#ffffff',
    },
    signOutButton: {
      marginLeft: 'auto',
      color:'#ffffff'
    },
  })
