import React, { Component } from 'react'
import Store from 'stores/store'
import SyntaxHighlighter from 'react-syntax-highlighter'
import { createStyles, Theme, Typography, withStyles, WithStyles, Tooltip, ClickAwayListener } from '@material-ui/core'
import { GetText as $ } from 'stores/UITexts'
import { observable } from 'mobx'
import { observer } from 'mobx-react'
import { Portlet, PortletContent, PortletHeader, PortletLabel } from 'components'
import { CodeOutlined } from '@material-ui/icons'
import { CopyToClipboard } from 'react-copy-to-clipboard'

const Styles = (theme: Theme) =>
  createStyles({
    root: {
      marginTop: theme.spacing() * 4,
      paddingLeft: 10,
      paddingRight: 10,
      borderWidth: 1,
      borderStyle: 'solid',
      borderColor: '#001e50',
      maxWidth: '100%',
      borderRadius: 18,
      backgroundColor: '#fff',
    },
    highlighter: {
      marginTop: 10,
      marginBottom: 10,
    },
  })

interface Props extends WithStyles<typeof Styles> {
  site: any
}

@observer
class Integration extends Component<Props> {
  @observable isCopied: boolean[] = [false, false, false]
  @observable isCountrySelectorOpen = false
  constructor(props: Props) {
    super(props)

    this.state = { isCopied: false }
  }
  // onChange: (state: MainState) => void;
  handleTooltipOpen = (index: number) => {
    this.isCopied[index] = true
  }
  handleTooltipClose = (index: number) => {
    this.isCopied[index] = false
  }

  // }
  render() {
    const { classes, site } = this.props

    if (!site) {
      return <></>
    }

    const codeString = `<head>
    <link rel="stylesheet" type="text/css" href="https://assets.cookieseal.com/cookie-seal.css" />
    <script id="cookieseal-script" src="https://assets.cookieseal.com/cookie-seal.js"></script>
</head>`

    const bodyString = `<body>
    <script>
        window.cookieSeal = new CookieSeal({
            tenantId: '${Store.tenant.id}',
            siteId: '${site.id}',
            lang: 'tr', // or 'en' for English
            showLogo: false,
            onChangePreference: function(category, allowed) {
                if (category === 'MarketingCookies' && allowed) {
                    // Initialize marketing scripts / trackers under this comment line
                }
                if (category === 'PerformanceCookies' && allowed) {
                    /// Initialize performance analytics scripts under this comment line
                }
                if (category === 'FunctionalCookies' && allowed) {
                    // Initialize functional analytics scripts under this comment line
                }
            },
        })
    </script>
</body>`

    const popupString = `<button onclick="window.cookieSeal.showPopup()">Show Popup</button>`

    return (
      <Portlet className={classes.root}>
        <PortletHeader>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <CodeOutlined />
            <PortletLabel title={$('integration_directions')} />
          </div>
        </PortletHeader>
        <PortletContent>
          <Typography>{$('installation_cookieseal_title_description')}</Typography>
          {/* <Typography variant="h3" style={{ marginTop: 30 }} >{$('registering_cookiseal')}</Typography> */}
          {navigator.language === 'tr' ? (
            <>
              <a
                style={{ color: 'red', marginTop: 10, cursor: 'pointer', textDecoration: 'underline' }}
                target="_blank"
                rel="noopener noreferrer"
                href="https://admin.cookieseal.com/pdf/cookieseal_kurulum_yonergesi.pdf"
              >
                Cookie Seal Kurulum Yönergesini indirmek için tıklayınız
              </a>
              <br />
              <a
                style={{ color: 'red', marginTop: 10, cursor: 'pointer', textDecoration: 'underline' }}
                target="_blank"
                rel="noopener noreferrer"
                href="https://admin.cookieseal.com/pdf/cookie_seal_gtm_kurulum_yonergesi.pdf"
              >
                GTM Kurulum Yönergesini indirmek için tıklayınız
              </a>
            </>
          ) : (
            <>
              <a
                style={{ color: 'red', marginTop: 10, cursor: 'pointer', textDecoration: 'underline' }}
                target="_blank"
                rel="noopener noreferrer"
                href="https://admin.cookieseal.com/pdf/cookie_seal_installation_guideline.pdf"
              >
                Click here to download the Cookie Seal Installation Guide
              </a>
              <br />
              <a
                style={{ color: 'red', marginTop: 10, cursor: 'pointer', textDecoration: 'underline' }}
                target="_blank"
                rel="noopener noreferrer"
                href="https://admin.cookieseal.com/pdf/gtm_installation_guideline_of_cookie_seal.pdf"
              >
                Click here to download the GTM Installation Guide
              </a>
            </>
          )}

          <Typography variant="h4" style={{ marginTop: 30 }}>
            {$('integration_steps')}
          </Typography>
          <Typography style={{ marginTop: 30 }}>{$('insallation_steps_cookieseal_one')}</Typography>
          <Typography style={{ marginTop: 15 }}>{$('insallation_steps_cookieseal_two')}</Typography>
          <Typography style={{ marginTop: 15 }}>{$('insallation_steps_cookieseal_three')}</Typography>
          <Typography style={{ marginTop: 15 }}>{$('insallation_steps_cookieseal_four')}</Typography>
          <Typography variant="h4" style={{ marginTop: 30 }}>
            {$('insallation_steps_cookieseal_one')}
          </Typography>
          <Typography style={{ marginTop: 20 }}>{$('register_the_required_assets_in_the_head_tag_of_your_html_page')}:</Typography>
          <ClickAwayListener onClickAway={() => this.handleTooltipClose(0)}>
            <Tooltip title={$('copied_to_clipboard')} placement="top" open={this.isCopied[0]}>
              <CopyToClipboard onCopy={() => this.handleTooltipOpen(0)} text={codeString}>
                <SyntaxHighlighter className={classes.highlighter} language="html">
                  {codeString}
                </SyntaxHighlighter>
              </CopyToClipboard>
            </Tooltip>
          </ClickAwayListener>
          <Typography style={{ marginTop: 30 }} variant="h4">
            {$('initializing_cookieseal')}
          </Typography>
          <Typography style={{ marginTop: 20 }}>{$('copy_and_paste_the_following_template_just_after_the_opening_body_tag')}:</Typography>
          <ClickAwayListener onClickAway={() => this.handleTooltipClose(1)}>
            <Tooltip title={$('copied_to_clipboard')} placement="top" open={this.isCopied[1]}>
              <CopyToClipboard onCopy={() => this.handleTooltipOpen(1)} text={bodyString}>
                <SyntaxHighlighter className={classes.highlighter} language="html">
                  {bodyString}
                </SyntaxHighlighter>
              </CopyToClipboard>
            </Tooltip>
          </ClickAwayListener>
          <Typography>{$('youre_all_set')}</Typography>
          <Typography style={{ marginTop: 30 }} variant="h4">
            {$('showing_settings_popup')}
          </Typography>
          <Typography style={{ marginTop: 20 }}>{$('you_can_show_the_cookie_customization_popup_by_calling_the_following_method')}:</Typography>
          <ClickAwayListener onClickAway={() => this.handleTooltipClose(2)}>
            <Tooltip title={$('copied_to_clipboard')} placement="top" open={this.isCopied[2]}>
              <CopyToClipboard onCopy={() => this.handleTooltipOpen(2)} text={popupString}>
                <SyntaxHighlighter className={classes.highlighter} language="html">
                  {popupString}
                </SyntaxHighlighter>
              </CopyToClipboard>
            </Tooltip>
          </ClickAwayListener>
        </PortletContent>
      </Portlet>
    )
  }
}

export default withStyles(Styles)(Integration)
